import React, { useState } from "react";
import {
  Divider,
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  CircularProgress,
} from "@mui/material";
import BackgroundImage from "../backgroundImage.svg";
import Logo from "../logoImage/BlueLogo.png";
import { Link } from "react-router-dom";
import { AUTH_API, LOOKUP_API } from "../apis";
import { useNavigate, useLocation } from "react-router-dom";
import CustomInputLabel from "../components/CustomLabelInput";
import COLORS from "../utils/colors";
import { useSnackbar } from "../context/SnackbarContext";
import { set } from "../actions/authActions";
import { setLookup } from "../actions/lookupActions";
import { useSelector, useDispatch } from "react-redux";
import { signInWithGooglePopup, auth } from "../utils/firebase.utils";
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import GoogleIcon from "../images/logo googleg 48dp.png";
import AppleIcon from "../images/Apple Logo.png";
import { useTranslation } from "react-i18next";

const Login = ({ theme, mode, handleToggleChange }) => {
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = React.useState(false);
  const [otpText, setOtpText] = useState("");
  const [otpTextErr, setOtpTextErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotEmailErr, setForgotEmailErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\+?1?\d{10,15}$/;
  const location = useLocation();
  const { state } = location;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const withGoogle = async () => {
    try {
      const response = await signInWithGooglePopup();
      const res = await AUTH_API.socialLogin(
        {
          firebaseToken: response?._tokenResponse?.idToken,
        },
        () => {},
        showSnackbar
      );
      if (res) {
        dispatch(set({ token: res?.token, authorities: res?.authorities }));

        const res2 = await LOOKUP_API.getLookup(res?.token);
        if (res2) dispatch(setLookup(res2));

        if (state?.redirectBack) window.history.back();
        else navigate("/");
      }
    } catch (error) {
      console.log("Error while login ", error);
    }
  };

  const withApple = async () => {
    try {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
      const res = await signInWithPopup(auth, provider);

      const socialRes = await AUTH_API.socialLogin(
        {
          firebaseToken: res?._tokenResponse?.idToken,
        },
        () => {},
        showSnackbar
      );

      if (socialRes) {
        dispatch(
          set({ token: socialRes?.token, authorities: socialRes?.authorities })
        );

        const res2 = await LOOKUP_API.getLookup(socialRes?.token);
        if (res2) dispatch(setLookup(res2));

        if (state?.redirectBack) window.history.back();
        else navigate("/");
      }
    } catch (error) {
      console.log("... error ", error);
    }
  };

  const handleClickOpen = () => {
    console.log("test console");
    setOpen(true);
  };

  const Toogle = () => {
    setOtp(!otp);
  };

  const validateIfEmailorPhone = (str = "") => {
    if (emailRegex.test(str)) setIsEmail(true);
    else setIsEmail(false);

    if (phoneRegex.test(str)) setIsPhone(true);
    else setIsPhone(false);
  };

  const login = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!email) {
        setEmailErr("Email or Phone is required");
        // setPhoneErr("Email or Phone is required");
        if (isEmail && !password) setPasswordErr("Password is required");
        setLoading(false);
        return null;
      }

      if (isEmail) {
        if (!password) {
          setPasswordErr("Password is required");
          setLoading(false);
          return null;
        }
      }

      if (isPhone) {
        await AUTH_API.resendOTP({
          phone: email,
        });
        setOtp(true);
      } else if (email && password) {
        const body = {
          password,
          email,
        };

        const res = await AUTH_API.login(body, () => {}, showSnackbar);
        if (res) {
          dispatch(set({ token: res?.token, authorities: res?.authorities }));

          const res2 = await LOOKUP_API.getLookup(res?.token);
          if (res2) dispatch(setLookup(res2));

          if (state?.redirectBack) window.history.back();
          else navigate("/");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("Error while login ", error);
    }
  };

  const loginPhone = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!otpText) {
        setOtpTextErr("OTP is required");
        setLoading(false);
        return null;
      }

      const body = {
        phone: email,
        otp: otpText,
      };

      const res = await AUTH_API.login(body);
      if (res) {
        dispatch(set({ token: res?.token, authorities: res?.authorities }));
        window.history.back();
      }
      setLoading(false);
    } catch (error) {
      console.log("Error while login ", error);
    }
  };

  const forgotPassword = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!forgotEmail) {
        setForgotEmailErr("Recovery email is required");
        setLoading(false);
        return null;
      }

      if (!emailRegex.test(forgotEmail)) {
        setForgotEmailErr("Enter a valid email");
        setLoading(false);
        return null;
      }

      const res = await AUTH_API.forgotPassword({
        email: forgotEmail,
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log("Error while login ", error);
    }
  };

  const resendOtp = async () => {
    try {
      setLoading2(true);
      await AUTH_API.resendOTP({
        phone: email,
      });
      setLoading2(false);
    } catch (error) {
      console.log("Error while resend otp ", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            width: "520px",
            padding: "30px",
            borderRadius: "6px",
            background: theme.palette.background.default,
          }}
        >
          <DialogTitle
            sx={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "0px" }}
          >
            {t("login.account_recovery_header")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 5 }}>
              {t("login.account_recovery_sub_header")}
              {/* , and we'll guide you
              through the steps to reset your password and regain access to your
              account. */}
            </DialogContentText>
            <CustomInputLabel
              sx={{ "& .MuiOutlinedInputLabel-root": { fontSize: "49px" } }}
              title={"Email"}
              isRequired={true}
            />
            <TextField
              id="name"
              // label="Email Address"
              sx={{
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "40px",
                background: theme.palette.background.main,
                border: "1px solid #E2F0FA",
                // Ensure no additional border is added on focus
                "& .MuiOutlinedInput-root": {
                  width: "100%", // Ensure the width is maintained
                  height: "100%", // Ensure the height is maintained
                  padding: 0, // Prevent additional padding from affecting size

                  "& input": {
                    padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                  },
                  "& input::placeholder": {
                    fontSize: "16px", // Customize the placeholder font size
                  },
                  "& fieldset": {
                    border: "none", // Remove the default MUI border
                  },
                  "&:hover fieldset": {
                    border: "none", // Ensure no border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // Ensure no border on focus
                  },
                },
              }}
              type="email"
              fullWidth
              variant="outlined"
              placeholder="Email"
              onChange={(e) => {
                setForgotEmail(e.target.value);
                setForgotEmailErr("");
              }}
              helperText={forgotEmailErr}
              error={forgotEmailErr.length > 0 ? true : false}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                width: "149px",
                height: "38px",
                color: theme.palette.background.primary,
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "149px",
                height: "38px",
                backgroundColor: theme.palette.background.primary,
                "&:hover": {
                  backgroundColor: theme.palette.background.primary,
                },
              }}
              variant="contained"
              onClick={forgotPassword}
            >
              {isLoading ? (
                <CircularProgress
                  size={26}
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                "Send"
              )}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={otp} onClose={Toogle}>
        <Box sx={{ padding: "15px", background: COLORS.default_background }}>
          <DialogTitle>Verify Your Account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We are sending a OTP to validate your mobile number , Hang On!
            </DialogContentText>
            <CustomInputLabel title={"Enter OTP"} />
            <TextField
              autoFocus
              id="number"
              // label="OTP"
              type="number"
              fullWidth
              variant="outlined"
              sx={{ mt: 2, background: COLORS.field }}
              onChange={(e) => {
                setOtpText(e.target.value);
                setOtpTextErr("");
              }}
              error={otpTextErr.length > 0 ? true : false}
              helperText={otpTextErr}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ height: "40px" }}
              variant="outlined"
              onClick={() => {
                resendOtp();
              }}
            >
              {isLoading2 ? (
                <CircularProgress size={26} color="primary" />
              ) : (
                "Resend OTP"
              )}
            </Button>
            <Button
              sx={{ height: "40px" }}
              variant="contained"
              onClick={loginPhone}
            >
              {isLoading ? (
                <CircularProgress
                  size={26}
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                "Verify"
              )}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          background: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundColor: theme.palette.background.default,
          height: "100vh",
          overflow: "hidden",
        }}
      >
        {/* <Grid bgcolor={"black"} item xs={12} md={10}></Grid> */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mb={3}>
            <img src={Logo} alt="" style={{ width: "160px" }} />
          </Box>
          <Box
            sx={{
              width: "100%",
              flexGrow: "1",
              overflowY: "auto",
              maxHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              paddingX: 2,
            }}
          >
            <Card
              variant="outlined"
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "6px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "520px",
                padding: "30px 0px",
                border: "1px solid #E2F0FA",
              }}
            >
              <CardContent>
                <form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      mb={3.5}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: { xs: "center", sm: "start" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.background.mainH1,
                          fontSize: { xs: 16, sm: 18 },
                        }}
                        variant="subtitle1"
                      >
                        {t("login.header")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 14, sm: 16 },
                          color: theme.palette.text.body,
                        }}
                        variant="subtitle1"
                      >
                        {t("login.subheader")}
                      </Typography>
                    </Box>

                    <Box mb={3}>
                      <CustomInputLabel
                        title={"Email / Phone"}
                        isRequired={false}
                      />
                      <TextField
                        sx={{
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          width: "400px",
                          height: "40px",
                          background: theme.palette.background.main,
                          border: "1px solid #E2F0FA",
                          "& .MuiOutlinedInput-root": {
                            width: "100%", // Ensure the width is maintained
                            height: "100%", // Ensure the height is maintained
                            padding: 0, // Prevent additional padding from affecting size
                            background: theme.palette.background.main, // Ensure no background on root
                            "& input": {
                              padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                            },
                            "& input::placeholder": {
                              fontSize: "16px", // Customize the placeholder font size
                            },
                            "& fieldset": {
                              border: "none", // Remove the default MUI border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensure no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Ensure no border on focus
                            },
                          },
                        }}
                        id="name"
                        // label="Enter Email"
                        placeholder="Enter email or phone (+46123456789)"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          validateIfEmailorPhone(e.target.value);
                          setEmailErr("");
                        }}
                        helperText={emailErr}
                        error={emailErr.length > 0 ? true : false}
                      />
                    </Box>

                    {/* <Grid item xs={12} md={6}>
                    <CustomInputLabel title={"Phone"} isRequired={false} />
                    <TextField
                      sx={{ width: "400px" }}
                      id="name"
                      // label="Enter Phone"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setPhoneErr("");
                      }}
                      helperText={phoneErr}
                      error={phoneErr.length > 0 ? true : false}
                    />
                  </Grid> */}

                    {isEmail ? (
                      <Box mb={3}>
                        <CustomInputLabel
                          title={"Password"}
                          isRequired={true}
                        />
                        <TextField
                          sx={{
                            borderRadius: "4px",
                            display: "flex",
                            justifyContent: "center",
                            width: "400px",
                            height: "40px",
                            background: theme.palette.background.main,
                            border: "1px solid #E2F0FA",
                            "& .MuiOutlinedInput-root": {
                              width: "100%", // Ensure the width is maintained
                              height: "100%", // Ensure the height is maintained
                              padding: 0, // Prevent additional padding from affecting size
                              background: theme.palette.background.main, // Ensure no background on root
                              "& input": {
                                padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                              },
                              "& input::placeholder": {
                                fontSize: "16px", // Customize the placeholder font size
                              },
                              "& fieldset": {
                                border: "none", // Remove the default MUI border
                              },
                              "&:hover fieldset": {
                                border: "none", // Ensure no border on hover
                              },
                              "&.Mui-focused fieldset": {
                                border: "none", // Ensure no border on focus
                              },
                            },
                          }}
                          id="password"
                          // label="Password"
                          type="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordErr("");
                          }}
                          helperText={passwordErr}
                          error={passwordErr.length > 0 ? true : false}
                        />
                      </Box>
                    ) : null}

                    <Box
                      mb={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        width: "400px",
                        justifyContent: "end",
                      }}
                    >
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={true}
                          name="checked"
                          color="primary"
                        />
                      }
                      label="I accept the"
                    /> */}
                      <Typography
                        fontWeight="regular"
                        variant="subtitle1"
                        component={Link}
                        onClick={handleClickOpen}
                        sx={{
                          textDecoration: "none",
                          color: theme.palette.background.primary,
                          fontSize: { xs: 14, sm: 16 },
                        }}
                      >
                        {t("login.forgot_password")} ?
                      </Typography>
                    </Box>

                    <Button
                      sx={{
                        width: "240px",
                        height: "38px",
                        mt: 1.5,
                        textTransform: "none",
                        // backgroundColor: theme.palette.background.primary,
                        // "&:hover": {
                        //   backgroundColor: theme.palette.background.primary,
                        // },
                      }}
                      fontWeight="regular"
                      disableElevation
                      fullWidth
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={login}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={30}
                          sx={{
                            color: "white",
                          }}
                        />
                      ) : (
                        <Typography fontSize={"15px"}>Lets Begin</Typography>
                      )}
                    </Button>

                    <Button
                      sx={{
                        width: "240px",
                        height: "38px",
                        mt: 1.5,
                        textTransform: "none",
                        backgroundColor: "white",
                        // backgroundColor: theme.palette.background.primary,
                        // "&:hover": {
                        //   backgroundColor: theme.palette.background.primary,
                        // },
                      }}
                      fontWeight="regular"
                      disableElevation
                      fullWidth
                      variant="outlined"
                      size="large"
                      onClick={withGoogle}
                    >
                      <img
                        src={GoogleIcon}
                        height={"20px"}
                        width={"20px"}
                        style={{
                          marginRight: "8px",
                        }}
                      ></img>
                      <Typography fontSize={"15px"}>
                        {/* {t("login.continue_with_google")} */}
                        Continue with Google
                      </Typography>
                    </Button>

                    <Button
                      sx={{
                        width: "240px",
                        height: "38px",
                        mt: 1.5,
                        textTransform: "none",
                        backgroundColor: "black",
                        // backgroundColor: theme.palette.background.primary,
                        // "&:hover": {
                        //   backgroundColor: theme.palette.background.primary,
                        // },
                      }}
                      fontWeight="regular"
                      disableElevation
                      fullWidth
                      variant="contained"
                      size="large"
                      onClick={withApple}
                    >
                      <img
                        src={AppleIcon}
                        height={"20px"}
                        width={"20px"}
                        style={{
                          marginRight: "10px",
                        }}
                      ></img>
                      <Typography fontSize={"15px"}>
                        {/* {t("login.continue_with_apple")} */}
                        Continue with Apple
                      </Typography>
                    </Button>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        width: "400px",
                        justifyContent: "center",
                      }}
                    >
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={true}
                          name="checked"
                          color="primary"
                        />
                      }
                      label="I accept the"
                    /> */}
                      <Typography
                        fontWeight="light"
                        variant="subtitle1"
                        sx={{
                          fontSize: { xs: 14, sm: 16 },
                          textDecoration: "none",
                          mt: 4,
                          mr: 1,
                          color: theme.palette.background.primary,
                        }}
                      >
                        {t("login.dont_have_account")} ?
                      </Typography>

                      <Typography
                        fontWeight="light"
                        variant="subtitle1"
                        onClick={() => navigate("/signup")}
                        sx={{
                          textDecoration: "underline",
                          mt: 4,
                          color: theme.palette.background.primary,
                          fontSize: { xs: 14, sm: 16 },
                        }}
                      >
                        {t("login.signup_here")}
                      </Typography>
                    </Grid>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default Login;
