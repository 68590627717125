import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
// import Banner from "../components/Banner";
import BannerVideo from "../components/Banners/BannerVideo";
import LearnersViewing from "../components/LearnersViewing";
// import TopCategories from "../components/TopCategories";
import Instructor from "../components/Instructor";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import FeaturedCourseCard from "../components/HomePage/FeaturedCourseCard";
import FeaturedCourseSlider from "../components/HomePage/FeaturedCourseSlider";
import { COURSE_API } from "../apis";
import { useSelector } from "react-redux";

const HomePage = ({ theme, mode, handleToggleChange }) => {
  const [topCourses, setTopCourses] = useState([]);
  const conState = useSelector((state) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    getTopCourses(conState?.token);
  }, [conState]);

  const getTopCourses = async (token = null) => {
    try {
      const res = await COURSE_API.getFeatureCourses(token);
      if (res) {
        setTopCourses(res?.featuredCourses);
      }
    } catch (error) {
      console.log("Error getCourses", topCourses);
    }
  };

  return (
    <Grid width={"100vw"} sx={{ overflowX: "hidden" }}>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <FeaturedCourseSlider courses={topCourses} theme={theme} />
      <LearnersViewing t={t} theme={theme} />
      <Instructor t={t} theme={theme} />
      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </Grid>
  );
};

export default HomePage;
