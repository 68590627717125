const initialState = {};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET":
      return { ...state, ...action?.payload };
    case "DECREMENT":
      return { ...state, value: state.value - 1 };
    default:
      return state;
  }
};

export default authReducer;
