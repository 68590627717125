import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { COURSE_API } from "../apis";
import CourseCard from "../components/CourseCard";
import SearchHeader from "../components/SearchHeader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";

const Course = ({ theme, mode, handleToggleChange }) => {
  const perPage = 8;
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [status, setStatus] = useState("ALL");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const getCourses = async () => {
    try {
      const res = await COURSE_API.getCoursesPaginated({
        myCourseStatus: status,
        perPage,
        page,
      });
      if (res) {
        setCourses(res?.courses);
        setPages(Math.ceil(res?.pagination?.totalItems / perPage));
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    getCourses();
  }, [status, page]);

  const buttons = [
    <Button
      sx={{
        flex: 1, // Allows buttons to grow and fill available space equally
        height: "47px",
        borderRadius: "0",
        borderRight: "1px solid #E2F0FA",
        fontSize: { xs: "14px", sm: "14px", md: "16px" }, // Responsive font size
        paddingLeft: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        paddingRight: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        textTransform: "none",
        fontWeight: "bold",
        color: theme.palette.text.body,
        "&:focus": {
          color: "#006AE6",
          backgroundColor: "transparent",
        },
        "&:last-of-type": {
          borderRight: "none", // Remove border from the last button
        },
      }}
      onClick={() => setStatus("ALL")}
      key="all"
    >
      {t("courses.all")}
    </Button>,
    <Button
      sx={{
        flex: 1, // Allows buttons to grow and fill available space equally
        height: "47px",
        borderRadius: "0",
        borderRight: "1px solid #E2F0FA",
        fontSize: { xs: "14px", sm: "14px", md: "16px" }, // Responsive font size
        paddingLeft: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        paddingRight: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        textTransform: "none",
        fontWeight: "bold",
        color: theme.palette.text.body,
        "&:focus": {
          color: "#006AE6",
          backgroundColor: "transparent",
        },
        "&:last-of-type": {
          borderRight: "none", // Remove border from the last button
        },
      }}
      onClick={() => setStatus("IN_PROGRESS")}
      key="in-progress"
    >
      {t("courses.inprogress")}
    </Button>,
    <Button
      sx={{
        flex: 1, // Allows buttons to grow and fill available space equally
        height: "47px",
        borderRadius: "0",
        borderRight: "1px solid #E2F0FA",
        fontSize: { xs: "14px", sm: "14px", md: "16px" }, // Responsive font size
        paddingLeft: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        paddingRight: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        textTransform: "none",
        fontWeight: "bold",
        color: theme.palette.text.body,
        "&:focus": {
          color: "#006AE6",
          backgroundColor: "transparent",
        },
        "&:last-of-type": {
          borderRight: "none", // Remove border from the last button
        },
      }}
      onClick={() => setStatus("COMPLETED")}
      key="completed"
    >
      {t("courses.completed")}
    </Button>,
    <Button
      sx={{
        flex: 1, // Allows buttons to grow and fill available space equally
        height: "47px",
        borderRadius: "0",
        fontSize: { xs: "14px", sm: "14px", md: "16px" }, // Responsive font size
        paddingLeft: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        paddingRight: { xs: "10px", sm: "20px", md: "40px" }, // Responsive padding
        textTransform: "none",
        fontWeight: "bold",
        color: theme.palette.text.body,
        "&:focus": {
          color: "#006AE6",
          backgroundColor: "transparent",
        },
        "&:last-of-type": {
          borderRight: "none", // Remove border from the last button
        },
      }}
      onClick={() => setStatus("STARRED")}
      key="starred"
    >
      {t("courses.saved")}
    </Button>,
  ];

  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <SearchHeader title={t("courses.heading")} theme={theme} />

          <Box
            sx={{
              backgroundColor: theme.palette.background.secondary,
              width: "100%", // Allows the Box to be as wide as its container
              maxWidth: "665px", // Sets the maximum width
              mt: 4,
              height: "60px",
            }}
          >
            <ButtonGroup
              size="large"
              variant="text"
              sx={{
                height: "100%", // Ensure ButtonGroup fills the height of the Box
                display: "flex",
                alignItems: "center",
                border: "1px solid #E2F0FA",
                width: "100%", // Makes ButtonGroup fill the Box
              }}
            >
              {buttons}
            </ButtonGroup>
          </Box>

          <Grid
            maxWidth={"1200px"}
            container
            rowSpacing={4}
            columnSpacing={2}
            mt={1.5}
          >
            {courses.map((el) => (
              <Grid
                item
                key={el._id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CourseCard
                  theme={theme}
                  course={el}
                  onNav={() =>
                    navigate(`/course/${el?._id}`, {
                      state: { id: el?._id },
                    })
                  }
                />
              </Grid>
            ))}
          </Grid>

          <Pagination
            size="large"
            onChange={(e, value) => setPage(value)}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              padding: 1,
            }}
            count={pages}
          />
        </Grid>
      </Grid>
      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </React.Fragment>
  );
};

export default Course;
