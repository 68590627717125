import ROUTES from "./routes";
import INTERCEPTOR from "../utils/interceptor";

const getProfile = async () => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.USER}/own-profile`,
    auth: true,
  });
  if (res) return res;
};

const updatePassword = async (body) => {
  let res = await INTERCEPTOR.put({
    url: `${ROUTES.USER}/update-password`,
    body,
    auth: true,
  });
  if (res) return res;
};

const updateProfile = async (body) => {
  let res = await INTERCEPTOR.put({
    url: `${ROUTES.USER}/update-profile`,
    body,
    auth: true,
  });
  if (res) return res;
};

const getTransactions = async (body) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.USER}/paginated-transactions`,
    auth: true,
    body
  });
  if (res) return res;
};

const removeAccount = async (id) => {
  let res = await INTERCEPTOR.remove({
    url: `${ROUTES.USER}/own-profile`,
    auth: true,
  });
  if (res) return res;
};

const USER_API = {
  getProfile,
  updatePassword,
  updateProfile,
  getTransactions,
  removeAccount,
};
export default USER_API;
