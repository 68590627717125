import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import homepage from "../../assets/homepage.png";
import "../../App.css";

const Banner = ({ theme, mode, handleToggleChange, t }) => {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        mt: 4,
        paddingTop: 5,
        paddingBottom: 2,
      }}
    >
      <Grid
        maxWidth="1200px"
        width="100%"
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.background.default,
          px: 2,
          maxWidth: "1200px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            className="img_homepage"
            src={homepage}
            alt="globe"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: { xs: "20%", sm: "30%", md: "35%" }, // Responsive top position
            left: { xs: "2%", sm: "2%", md: "2%" }, // Responsive left position
            width: { xs: "50%", sm: "50%", md: "55%", lg: "50%" }, // Responsive width
            padding: { xs: "1.5rem", sm: "2rem", md: "2.3rem" },
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#17224E",
              fontWeight: "bold",
              mb: 1,
              fontSize: { xs: "16px", sm: "20px", md: "28px" },
              textAlign: "left",
            }}
          >
            {t("about_us.join_us")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Banner;
