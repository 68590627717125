import { Typography, Box, Divider, Grid } from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const TermsConditions = ({ theme, mode, handleToggleChange }) => {
  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "24px", sm: "28px", md: "30px" },
                whiteSpace: "nowrap",
              }}
            >
              Terms and Conditions
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 5,
              backgroundColor: theme.palette.background.main,
              width: "100%",
            }}
          >
            <Box sx={{ padding: 5 }}>
              <Typography
                sx={{
                  paddingBottom: 2,
                  fontSize: { xs: "14px", sm: "16px", md: "18px" },
                }}
              >
                Updated Date: April 20, 2024.
              </Typography>
              <Divider />
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  INTELLECTUAL PROPERTY GUIDELINES
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  Welcome to Coursfy, a global platform for creating and sharing
                  educational materials. Our marketplace hosts a wide variety of
                  courses, and while we do not review content for legal
                  compliance, we are committed to respecting intellectual
                  property rights. Instructors on Coursfy are responsible for
                  ensuring they have the necessary permissions or rights to use
                  the content they upload.
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  We strictly prohibit any activities that infringe on
                  intellectual property rights.
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  Our policy outlines the steps we take when we receive
                  copyright and trademark takedown notifications from rights
                  holders concerning content hosted on Coursfy. It also
                  addresses the issue of Coursfy instructors' content being used
                  on external platforms without permission.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  COPYRIGHT TAKEDOWN PROCEDURE
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  Upon receiving a valid copyright takedown notice from the
                  content owner, we promptly remove the infringing content.
                  Instructors with repeated infringements (more than two
                  verified notices) will have their content removed and may face
                  account termination.
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  To report suspected infringement, content owners or their
                  agents should use the provided copyright complaint form.
                  Please ensure your notice is valid by including the following:
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Only the copyright owner or their authorized agent can
                    file a claim, as we must verify rightful ownership or
                    permission.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Filing a baseless or fraudulent notice is unlawful and may
                    lead to legal consequences.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Evaluate if your content usage falls under 'fair use' as
                    defined by copyright law.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Verify that the content in question is copyright-protected
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Your claim should be clear and detailed, including your
                    contact information, a precise identification of the
                    copyrighted material, the location of the infringing content
                    on our platform, and a sworn statement of accuracy.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  COUNTER-NOTIFICATION PROCESS
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  Upon processing a copyright takedown notice, we notify the
                  involved instructor and remove the alleged infringing content.
                  Instructors who believe the takedown was wrongful can submit a
                  counter-notification.
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  Submitting a counter-notification with false information is
                  illegal and may result in legal liabilities.
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  To file a counter-notification, include:
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Your signature (physical or electronic).
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Personal contact details.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Identification of the removed content and its original
                    location.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - A statement under oath asserting a belief in the mistaken
                    removal or misidentification of content.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Consent to disclose your contact information to the
                    claimant and to jurisdictional legal processes.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  TRADEMARK TAKEDOWN PROCEDURE
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  We remove content upon confirmation of trademark infringement.
                  Trademark owners or their agents should use the trademark
                  complaint form.
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  When submitting a trademark takedown notice, ensure:
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Your claim is well-supported with complete contact
                    details, specifics of the trademark, the basis of rights,
                    the location of the trademark on our platform, and a
                    detailed description of the infringement.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - A statement of good faith belief in unauthorized trademark
                    use, a declaration of accuracy, and consent for forwarding
                    the complaint to the uploader.
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                  >
                    - Your electronic or physical signature.
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  False or misleading claims can lead to legal penalties.
                  Consider if the trademark usage constitutes 'fair use.'
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  INFRINGEMENT ON EXTERNAL PLATFORMS
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  We understand concerns regarding unauthorized use of your
                  content on external platforms. We work with an anti-piracy
                  vendor to eliminate such infringements and prevent future
                  occurrences.
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  If your content is pirated externally, please use the provided
                  form to report it.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  CONTACT DETAILS
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  For any intellectual property concerns or to report
                  infringement, please contact us at info@coursfy.com
                </Typography>
              </Box>

              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{ fontSize: { xs: "14px", sm: "16px", md: "18px" } }}
                >
                  By adhering to these guidelines, we can maintain a respectful
                  and lawful environment for educational content creation and
                  sharing.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </React.Fragment>
  );
};

export default TermsConditions;
