import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const SearchHeader = ({ title, theme }) => {
  const [search, setSearch] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate("/search", {
        state: {
          search: search,
        },
      });
    }
  };
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "25px", sm: "30px" },
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
      <TextField
        sx={{
          marginTop: { xs: 2.5, sm: 0, md: 0 },
          backgroundColor: theme.palette.background.secondary,
          width: "400px",
          minWidth: "300px",
          height: "55px",
          border: "none",
          // Remove default border
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          // Remove underline for standard variant
          "& .MuiInputBase-root": {
            "&:before": {
              borderBottom: "none",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "none",
            },
            "&:after": {
              borderBottom: "none",
            },
          },
        }}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder="Search..."
        onKeyPress={handleKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                onClick={() => {
                  navigate("/search", {
                    state: {
                      search: search,
                    },
                  });
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchHeader;
