import React, { useState, useEffect, useCallback } from "react";
import { Box, Grid, IconButton, useMediaQuery } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import FeaturedCourseCard from "./FeaturedCourseCard";
import "../../App.css"; // Import the CSS file

const FeaturedCourseSlider = ({ theme, courses }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile screen
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [touchStart, setTouchStart] = useState(0); // Add touchStart state

  // Infinite scrolling effect
  const handleNext = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === courses.length - 1 ? 0 : prevIndex + 1
    );
  }, [courses.length, isTransitioning]);

  const handlePrev = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? courses.length - 1 : prevIndex - 1
    );
  }, [courses.length, isTransitioning]);

  // Set transitioning back to false after the transition ends
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTransitioning(false);
    }, 500); // Matches CSS transition duration
    return () => clearTimeout(timer);
  }, [currentIndex]);

  // Handle swipe gestures for mobile
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX); // Set the touchStart value
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    if (touchStart - touchEnd > 50) {
      handleNext(); // Swipe left
    } else if (touchEnd - touchStart > 50) {
      handlePrev(); // Swipe right
    }
  };

  return (
    <Grid
      container
      mt={8}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        background: theme.palette.background.main,
        paddingTop: 8,
        paddingBottom: 7,
        position: "relative", // Ensure the grid is the relative container for absolute positioning
      }}
    >
      <Grid
        item
        maxWidth="1100px"
        width="100%"
        sx={{
          paddingX: 2,
          width: "100%",
          position: "relative",
        }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd} // Mobile swipe support
      >
        {/* Previous Button */}
        {!isMobile && (
          <IconButton
            onClick={handlePrev}
            sx={{
              position: "absolute",
              left: -10, // Adjust the distance from the left edge
              top: "50%",
              transform: "translateY(-50%)", // Center vertically
              zIndex: 1,
              bgcolor: "transparent",
              "&:hover": { bgcolor: "transparent" },
            }}
          >
            <WestIcon />
          </IconButton>
        )}
        <Box sx={{ width: "100%", overflow: "hidden" }}>
          <Box
            className="slider"
            sx={{
              display: "flex",
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${currentIndex * 100}%)`, // Slide effect
            }}
          >
            {courses.map((course, index) => (
              <Box
                key={index}
                sx={{
                  minWidth: "100%",
                  boxSizing: "border-box",
                }}
              >
                <FeaturedCourseCard theme={theme} course={course} />
              </Box>
            ))}
          </Box>
        </Box>
        {/* Next Button */}
        {!isMobile && (
          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: -10, // Adjust the distance from the right edge
              top: "50%",
              transform: "translateY(-50%)", // Center vertically
              zIndex: 1,
              bgcolor: "transparent",
              "&:hover": { bgcolor: "transparent" },
            }}
          >
            <EastIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default FeaturedCourseSlider;
