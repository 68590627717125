import { Box, Typography, Button, Grid } from "@mui/material";
import React from "react";
import instructor from "../assets/instructor.png";
import business from "../assets/business.png";
import PricingCards from "./PricingCards";

const Instructor = ({ theme, t }) => {
  return (
    <Grid
      md={12}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      flexWrap={"wrap"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={5}
      sx={{
        paddingTop: 4,
        paddingBottom: 5,
      }}
    >
      {/*---------Instructor Section */}
      <Grid
        mb={2}
        item
        maxWidth="1200px"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          paddingX: 2,
        }}
      >
        <Grid item md={7}>
          <Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "24px", md: "2.125rem" },
              }}
            >
              {t("home.become_instructor")}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
            >
              {t("home.become_instructor_description")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
            >
              {t("home.become_instructor_description2")}
            </Typography>
          </Box>
          <Box mt={3} mb={3}>
            <Button
              href="https://admin.coursfy.com/"
              target="_blank"
              variant="contained"
              sx={{
                height: "42px",
                fontSize: { xs: "14px", md: "16px" },
                backgroundColor: "#006AE6",
                color: "white",
                padding: { xs: "8px 16px", md: "10px 20px" },
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#006AE6",
                  color: "white",
                },
              }}
            >
              {t("home.start_teaching_today")}
            </Button>
          </Box>
        </Grid>

        <Grid
          item
          md={3}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "center", md: "flex-end" }}
          sx={{ width: "100%" }}
        >
          <img className="img_instructor" src={instructor} alt="instructor" />
        </Grid>
      </Grid>

      {/* -----------Company Section */}
      <Grid
        item
        maxWidth="1200px"
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          paddingX: 2,
          width: "100%",
        }}
      >
        <Grid
          item
          md={3}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: "center", md: "flex-start" }}
          sx={{ width: "100%" }}
        >
          <img className="img_business" src={business} alt="business" />
        </Grid>

        <Grid item md={7}>
          <Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "24px", md: "2.125rem" },
              }}
            >
              {t("home.become_company")}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
            >
              {t("home.become_company_description")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}
            >
              {t("home.become_company_description2")}
            </Typography>
          </Box>
          <Box mt={3}>
            <Button
              href="https://admin.coursfy.com/"
              target="_blank"
              variant="contained"
              sx={{
                height: "42px",
                fontSize: { xs: "14px", md: "16px" },
                backgroundColor: "#006AE6",
                color: "white",
                padding: { xs: "8px 16px", md: "10px 20px" },
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#006AE6",
                  color: "white",
                },
              }}
            >
              {t("home.start_teaching_today")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* <PricingCards /> */}
    </Grid>
  );
};

export default Instructor;
