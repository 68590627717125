import ROUTES from "./routes";
import INTERCEPTOR from "../utils/interceptor";
import STORAGE from "../utils/local.storage";

const getCoursesPaginated = async (
  {
    page = 1,
    perPage = 10,
    sortBy = "name",
    sortOrder = 1,
    myCourseStatus = "ANY",
    rating = 0,
    searchText = "",
    priceMin = 0,
    priceMax = 10000,
    category = "",
  },
  snackbar,
  token = null
) => {
  const body = {
    page,
    perPage,
    searchText,
    sortBy,
    sortOrder,
    myCourseStatus,
    rating,
    priceMin,
    priceMax,
  };

  if (category) body["category"] = category;

  let res = await INTERCEPTOR.post({
    url: `${ROUTES.COURSE}/paginated/user-home`,
    body,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getCoursesPaginatedHome = async (
  {
    page = 1,
    perPage = 10,
    sortBy = "name",
    sortOrder = 1,
    myCourseStatus = "ANY",
    rating = 0,
    searchText = "",
    priceMin = 0,
    priceMax = 10000,
    category = "",
  },
  snackbar,
  token = null
) => {
  const body = {
    page,
    perPage,
    searchText,
    sortBy,
    sortOrder,
    myCourseStatus,
    rating,
    priceMin,
    priceMax,
  };

  if (category) body["category"] = category;

  let res = await INTERCEPTOR.post({
    url: `${ROUTES.COURSE}/paginated/user-home`,
    body,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getTopCourses = async (token = null) => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.COURSE}/paginated/top-courses`,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getFeatureCourses = async (token = null) => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.COURSE}/featured`,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getCoursesDetail = async (id, token = null) => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.COURSE}/details/${id}`,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getCoursesDetailLesson = async (id, token = null) => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.COURSE}/details-lesson-page/${id}`,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getCourseSections = async (id, token = null) => {
  let res = await INTERCEPTOR.get({
    url: `section/lessons/${id}`,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getLessonSlides = async (id) => {
  let res = await INTERCEPTOR.get({
    url: `lesson/slide/all/${id}`,
    auth: true,
  });
  if (res) return res;
  return null;
};

const getLessonQuiz = async (id) => {
  let res = await INTERCEPTOR.get({
    url: `lesson/question/all/${id}`,
    auth: true,
  });
  if (res) return res;
  return null;
};

const buyCourse = async (id) => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.COURSE}/checkout/${id}`,
    auth: true,
  });
  if (res) return res;
  return null;
};

const lessonProgressUpdate = async (body) => {
  let res = await INTERCEPTOR.post({
    url: `lesson/progress/update`,
    body,
    auth: true,
  });
  if (res) return res;
  return null;
};

const getCourseLessons = async (id) => {
  let res = await INTERCEPTOR.get({
    url: `lesson/all/${id}`,
    auth: true,
  });
  if (res) return res;
  return null;
};

const rateCourse = async (id, body) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.COURSE}/rating/${id}`,
    body,
    auth: true,
  });
  if (res) return res;
  return null;
};

const reportCourse = async (body) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.COURSE}/report`,
    body,
    auth: true,
  });
  if (res) return res;
  return null;
};

const bookmarkCourse = async (id) => {
  let res = await INTERCEPTOR.put({
    url: `${ROUTES.COURSE}/star/${id}`,
    auth: true,
  });
  if (res) return res;
  return null;
};

const buyFreeCourse = async (id) => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.COURSE}/confirm-purchase/${id}`,
    auth: true,
  });
  if (res) return res;
  return null;
}

const COURSE_API = {
  getCoursesPaginated,
  getCoursesPaginatedHome,
  getCoursesDetail,
  getCoursesDetailLesson,
  getCourseSections,
  getTopCourses,

  getLessonSlides,
  getLessonQuiz,
  buyCourse,
  lessonProgressUpdate,
  getCourseLessons,

  rateCourse,
  reportCourse,
  bookmarkCourse,
  buyFreeCourse,
  getFeatureCourses
};
export default COURSE_API;
