import ROUTES from "./routes";
import INTERCEPTOR from "../utils/interceptor";
import STORAGE from "../utils/local.storage";

const login = async (body, cb, snackbar) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.AUTH}/login/user`,
    body,
    auth: false,
    snackbar,
  });
  if (res) {
    STORAGE.setStoage(res, cb);
    return res;
  }
  return null;
};

const socialLogin = async (body, cb, snackbar) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.AUTH}/login/user-social`,
    body,
    auth: false,
    snackbar,
  });
  if (res) {
    STORAGE.setStoage(res, cb);
    return res;
  }
  return null;
};

const loginOpenUser = async (body, cb) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.AUTH}/login/open-user`,
    body: { deviceId: "ABC123" },
    auth: false,
  });
  if (res) {
    STORAGE.setStoage(res, cb);
    return res;
  }
  return null;
};

const verifyOtp = async (body, cb) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.AUTH}/verify-otp-user`,
    body,
    auth: false,
  });
  if (res) {
    STORAGE.setStoage(res, cb);
    return true;
  }
  return null;
};

const forgotPassword = async (body, cb) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.AUTH}/forgot-password-user`,
    body,
    auth: false,
  });
  if (res) {
    return true;
  }
  return null;
};

const resendOTP = async (body, showSnackbar) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.AUTH}/send-otp-user`,
    body,
    auth: false,
    snackbar: showSnackbar,
  });
  if (res) {
    return true;
  }
  return null;
};

const register = async (body, cb, showSnackbar) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.AUTH}/register/user`,
    body,
    auth: false,
    snackbar: showSnackbar,
  });
  if (res) return true;

  return null;
};

const AUTH_API = {
  login,
  forgotPassword,
  register,
  resendOTP,
  loginOpenUser,
  verifyOtp,
  socialLogin
};
export default AUTH_API;
