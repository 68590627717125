import React from "react";
import {
  Box,
  Card,
  Typography,
  Paper,
  Avatar,
  Rating,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CategoryIcon from "@mui/icons-material/Category";
import { formatDuration } from "../../utils/common";
import { useNavigate } from "react-router-dom";

const imgPlaceHolder = "https://via.placeholder.com/300";

const FeaturedCourseCard = ({ course }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile screen

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.main,
        width: "100%",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: isMobile ? "center" : "center",
        cursor: "pointer",
        overflow: "hidden",
        paddingX: 3,
      }}
      onClick={() => {
        navigate(`/course/${course?._id}`, {
          state: {
            id: course?._id,
          },
        });
      }}
    >
      <Box
        sx={{
          backgroundImage: `url("${course?.courseImage || imgPlaceHolder}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: isMobile ? "280px" : "310px",
          height: isMobile ? "280px" : "310px", // Set height to auto for non-mobile screens
          borderRadius: "5px",
          flexShrink: 0,
          mb: isMobile ? 2 : 0,
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          height: "auto",
          flexGrow: 1,
          ml: isMobile ? 0 : 2,
        }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: "bold",
              fontSize: isMobile ? "20px" : "1.5rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {course?.name || "Course Title"}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: isMobile ? "15px" : "1rem",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {course?.description || "Course Description"}
          </Typography>
        </Box>

        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            gap: { xs: isMobile ? 1 : 2 },
            flexDirection: "row",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: isMobile ? "0.875rem" : "1rem",
              color: theme.palette.background.cardIcons,
            }}
          >
            <StarIcon sx={{ mr: 0.5 }} /> {course?.rating?.toFixed(1) || "N/A"}
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? "0.875rem" : "1rem",
              color: theme.palette.background.cardIcons,
            }}
          >
            <AccessTimeIcon sx={{ mr: 0.5 }} />{" "}
            {formatDuration(course?.duration) || "N/A"}
          </Typography>
          <Typography
            sx={{
              fontSize: isMobile ? "0.875rem" : "1rem",
              color: theme.palette.background.cardIcons,
            }}
          >
            <CategoryIcon sx={{ mr: 0.5 }} /> {course?.category?.name || "N/A"}
          </Typography>
        </Box>

        {/* INSTRUCTOR CARD */}
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: "20px",
            marginTop: 2,
            padding: 2,
            paddingLeft: 3,
            boxSizing: "border-box",
          }}
        >
          <Typography
            fontWeight="bold"
            fontSize={isMobile ? "16px" : "18px"}
            sx={{
              color: theme.palette.background.primary,
            }}
          >
            Instructor
          </Typography>
          <Box mt={1} mb={1} minWidth="200px">
            <Box display="flex" alignItems="center">
              <Box pr={2}>
                <Avatar
                  sx={{
                    width: isMobile ? 55 : 65,
                    height: isMobile ? 55 : 65,
                  }}
                  alt="Instructor"
                  src={course?.organization?.profileImage}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  mb={1}
                  fontSize={isMobile ? "14px" : "16px"}
                  sx={{
                    color: theme.palette.background.primary,
                    fontWeight: "bold",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    lineHeight: "1.2em",
                    maxHeight: "2.4em",
                  }}
                >
                  {course?.organization?.name || "Instructor Name"}
                </Typography>
                <Typography
                  mb={0.5}
                  fontSize={isMobile ? "12px" : "16px"}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    lineHeight: "1.2em",
                    maxHeight: "2.4em",
                  }}
                >
                  {course?.organization?.headline || "Instructor Description"}
                </Typography>
                <Rating
                  value={course?.organization?.rating}
                  size="small"
                  readOnly
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Card>
  );
};

export default FeaturedCourseCard;
