import ROUTES from "./routes";
import INTERCEPTOR from "../utils/interceptor";

const getPaymentMethods = async () => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.STRIPE}/consumer-payment-methods`,
    auth: true,
  });
  if (res) return res;
};

const setupIntent = async () => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.STRIPE}/consumer-setup-intent`,
    auth: true,
  });
  if (res) return res;
};

const STRIPE_API = {
  getPaymentMethods,
  setupIntent,
};
export default STRIPE_API;
