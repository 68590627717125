import React from "react";
import { Grid, Box } from "@mui/material";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Banner from "../components/Banners/Banner";
import Headline from "../components/AboutUs/Headline";
import Impact from "../components/AboutUs/Impact";
import Reviews from "../components/AboutUs/Reviews";
import CoursyOffer from "../components/AboutUs/CoursyOffer";
import CompaniesIcons from "../components/CompaniesIcons";
import { useTranslation } from "react-i18next";

const AboutUs = ({ theme, mode, handleToggleChange }) => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Banner
        t={t}
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Headline
        t={t}
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      {/* <Box>
        <Impact
          t={t}
          theme={theme}
          mode={mode}
          handleToggleChange={handleToggleChange}
        />
      </Box> */}
      {/* <Box>
        <Reviews
          theme={theme}
          mode={mode}
          handleToggleChange={handleToggleChange}
        />
      </Box> */}
      <CoursyOffer
        t={t}
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      {/* <Box>
        <CompaniesIcons
          theme={theme}
          mode={mode}
          handleToggleChange={handleToggleChange}
        />
      </Box> */}
      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </Grid>
  );
};

export default AboutUs;
