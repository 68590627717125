import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import community from "../../assets/community.png";
import innovation from "../../assets/innovation.png";
import technology from "../../assets/technology.png";

const Headline = ({ theme, mode, handleToggleChange, t }) => {
  return (
    <Grid mt={15} container justifyContent="center">
      <Grid
        maxWidth="1200px"
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: 2,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            mb={2}
            fontSize={20}
            variant="h4"
            fontWeight={"bold"}
            textAlign={"center"}
          >
            {t("about_us.main_heading")}
          </Typography>
          <Typography maxWidth="600px" fontSize={"16px"} textAlign={"center"}>
            {t("about_us.mission")}
          </Typography>
        </Box>

        <Grid
          mt={7}
          container
          width="100%"
          display="flex"
          gap={3}
          sx={{
            justifyContent: { xs: "center", md: "space-between" },
          }}
        >
          <Grid
            width="350px"
            height="320px"
            item
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              style={{ width: "100%", maxWidth: "330px", borderRadius: "10px" }}
              src={community}
              alt="community"
            />
            <Box
              mt={3}
              height="auto"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                mb={1}
                fontWeight="bold"
                fontSize={16}
                color="#006AE6"
              >
                {t("about_us.community_title")}
              </Typography>
              <Typography fontSize={14} textAlign="center">
                {t("about_us.community_description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            width="350px"
            height="320px"
            item
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              style={{ width: "100%", maxWidth: "330px", borderRadius: "10px" }}
              src={innovation}
              alt="innovation"
            />
            <Box
              mt={3}
              height="auto"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                mb={1}
                fontWeight="bold"
                fontSize={16}
                color="#006AE6"
              >
                {t("about_us.innovation_title")}
              </Typography>
              <Typography fontSize={14} textAlign="center">
                {t("about_us.innovation_description")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            width="350px"
            height="320px"
            item
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              style={{ width: "100%", maxWidth: "330px", borderRadius: "10px" }}
              src={technology}
              alt="technology"
            />
            <Box
              mt={3}
              height="auto"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography
                mb={1}
                fontWeight="bold"
                fontSize={16}
                color="#006AE6"
              >
                {t("about_us.technology_title")}
              </Typography>
              <Typography fontSize={14} textAlign="center">
                {t("about_us.technology_description")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Headline;
