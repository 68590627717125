import React from "react";
import { useSnackbar } from "../context/SnackbarContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const CustomSnackbar = () => {
  const { snackbarOpen, snackbarMessage, snackbarSeverity, hideSnackbar } =
    useSnackbar();

  const handleClose = () => {
    hideSnackbar();
  };

  return (
    <Snackbar open={snackbarOpen} autoHideDuration={3500} onClose={handleClose}>
      <Alert variant="filled" onClose={handleClose} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
