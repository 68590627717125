import { createTheme } from "@mui/material/styles";

const getCustomTheme = (color = "#FF0000") => {
  const customTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // Example: change default styles for all buttons
            borderRadius: 8,
            textTransform: "none",
          },
          containedPrimary: {
            backgroundColor: color,
            "&:hover": {
              backgroundColor: color,
            },
          },
          outlinedPrimary: {
            borderColor: color,
            color: color,
            "&:hover": {
              borderColor: color,
              backgroundColor: color, // Adding some transparency
            },
          },
        },
      },
    },
  });

  return customTheme;
};

// Common base theme
const baseTheme = createTheme({
  typography: {
    fontFamily: "Gerbera, Arial",
  },
});

// Dark Theme Palette
const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: "dark",
    background: {
      default: "#313132", // Adjusted dark background color
      secondary: "#3F3F3F",
      main: "#1E1E1E !important",
      primary: "#006AE6",
      hover: "#BABBBD",
      overlay: "#000000cc",
      arrow: "white",
      media: "white",
      line: "#E2F0FA",
      cardBg: "#3F3F3F",
      cardBorder: "3F3F3F",
      footer: "#1E1E1E",
      footerDivider: "gray",
      cardIcons: "#BABBBD",
    },
    text: {
      default: "white",
      secondary: "white",
      body: "white",
      h1: "#4F4F4F",
      icon: "#8B98AA",
      mainH1: "white",
      footerText: "white",
    },
  },
});

// Light Theme Palette
const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: "light",
    background: {
      default: "#F5F6FA", // Light background color
      secondary: "white",
      main: "#fff !important",
      primary: "#006AE6",
      hover: "#BABBBD",
      overlay: "#01142bcc",
      arrow: "1E1E1E",
      media: "black",
      line: "#E2F0FA",
      cardBg: "#F4F9FF",
      cardBorder: "#dadada",
      footer: "#17224E",
      footerDivider: "white",
      cardIcons: "#333",
    },
    text: {
      default: "black",
      secondary: "gray",
      body: "#566477",
      h1: "#17224E",
      icon: "#8B98AA",
      mainH1: "#17224E",
      footerText: "white",
    },
  },
});

const getLightTheme = (color = "") => {
  if (!color) return lightTheme;
  else {
    const localCustom = getCustomTheme(color);
    const updateLightTheme = createTheme({
      ...baseTheme,
      ...localCustom,
      palette: {
        mode: "light",
        background: {
          default: "#F5F6FA", // Light background color
          secondary: "white",
          main: "#fff !important",
          primary: "#006AE6",
          hover: "#BABBBD",
          overlay: "#01142bcc",
          arrow: "1E1E1E",
          media: "black",
          line: "#E2F0FA",
          cardBg: "#F4F9FF",
          cardBorder: "#dadada",
        },
        text: {
          default: "black",
          secondary: "gray",
          body: "#566477",
          h1: "#17224E",
          icon: "#8B98AA",
          mainH1: "#17224E",
        },
      },
    });
    return updateLightTheme;
  }
};

const getDarkTheme = (color = "") => {
  if (!color) return darkTheme;
  else {
    const localCustom = getCustomTheme(color);
    const updateDarkTheme = createTheme({
      ...baseTheme,
      ...localCustom,
      palette: {
        mode: "dark",
        background: {
          default: "#313132", // Adjusted dark background color
          secondary: "#3F3F3F",
          main: "#1E1E1E !important",
          primary: "#006AE6",
          hover: "#BABBBD",
          overlay: "#000000cc",
          arrow: "white",
          media: "white",
          line: "#E2F0FA",
          cardBg: "#3F3F3F",
          cardBorder: "3F3F3F",
        },
        text: {
          default: "white",
          secondary: "white",
          body: "white",
          h1: "#4F4F4F",
          icon: "#8B98AA",
          mainH1: "white",
        },
      },
    });
    return updateDarkTheme;
  }
};

export { baseTheme, getLightTheme, getDarkTheme };
