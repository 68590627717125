import React, { useState } from "react";
import {
  Divider,
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Link,
} from "@mui/material";

import BackgroundImage from "../backgroundImage.svg";
import Logo from "../logoImage/BlueLogo.png";
import { AUTH_API } from "../apis";
import { useNavigate } from "react-router-dom";
import CustomInputLabel from "../components/CustomLabelInput";
import { getCountries } from "../utils/common";
import { useSnackbar } from "../context/SnackbarContext";

const Signup = ({ theme, mode, handleToggleChange }) => {
  const [country, setCountry] = React.useState("");
  const [countryErr, setCountryErr] = useState("");

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");

  const [otp, setOtp] = useState("");
  const [otpText, setOtpText] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);

  const [iAgree, setIAgree] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false); // New state for email verification

  // const phonePattern = /^(\+\d{1,3}[- ]?)?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^\+?1?\d{10,15}$/;

  const navigate = useNavigate();
  const COUNTRIES = getCountries();
  const { showSnackbar } = useSnackbar();

  const register = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!name) setNameErr("Name is required");
      if (!email) setEmailErr("Email is required");
      if (!emailRegex.test(email)) setEmailErr("Invalid email pattren");
      if (!password) setPasswordErr("Password is required");
      if (!country) setCountryErr("Country is required");
      // if (!phone) setPhoneErr("Phone is required");

      if (!name || !email || !password || !country || !emailRegex.test(email)) {
        setLoading(false);
        return null;
      }

      if (phone) {
        if (!phoneRegex.test(phone)) {
          setPhoneErr("Invalid phone number");
          setLoading(false);
          return null;
        }

        if (phone.length < 11) {
          setPhoneErr("Minimun 11 digits required");
          setLoading(false);
          return null;
        }

        setOtp(true);
        setLoading(false);
        resendOtp();
        return null;
      }

      const body = {
        name,
        email,
        password,
        country,
        // emailVerified: emailVerified, // Add this line
      };

      const res = await AUTH_API.register(body, () => {}, showSnackbar);
      if (res) {
        navigate("/login");
      }
      setLoading(false);
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const verifyOtp = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setEmailVerified(true); // Set emailVerified to true after successful OTP verification

      const body = {
        name,
        email,
        password,
        country,
        phone,
        // emailVerified: true, // Include emailVerified in the body
      };

      const res = await AUTH_API.register(body, () => {}, showSnackbar);
      setLoading(false);
      if (res) {
        navigate("/login");
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleChange = (event) => {
    setCountry(event.target.value);
    setCountryErr("");
  };

  const Toogle = () => {
    setOtp(!otp);
  };

  const resendOtp = async () => {
    try {
      setLoading2(true);
      await AUTH_API.resendOTP(
        {
          phone: phone,
        },
        showSnackbar
      );
      setLoading2(false);
    } catch (error) {
      console.log("Error while resend otp ", error);
    }
  };

  return (
    <React.Fragment>
      <Dialog open={otp} onClose={Toogle}>
        <Box sx={{ padding: "15px", width: "535px" }}>
          <DialogTitle
            sx={{
              fontWeight: "bold",
              color: theme.palette.background.mainH1,
              fontSize: "18px",
            }}
          >
            Verify Your Account
          </DialogTitle>
          <DialogContent>
            <Typography mb={2} sx={{ fontSize: "15px" }}>
              We are sending a OTP to validate your mobile number, Hang On!
            </Typography>
            <TextField
              id="number"
              label="OTP"
              type="number"
              fullWidth
              variant="outlined"
              sx={{
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                width: "440px",
                height: "40px",
                background: theme.palette.background.main,
                border: "1px solid #E2F0FA",
                "& .MuiOutlinedInput-root": {
                  width: "100%", // Ensure the width is maintained
                  height: "100%", // Ensure the height is maintained
                  padding: 0, // Prevent additional padding from affecting size
                  background: theme.palette.background.main, // Ensure no background on root
                  "& input": {
                    padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                  },
                  "& input::placeholder": {
                    fontSize: "16px", // Customize the placeholder font size
                  },
                  "& fieldset": {
                    border: "none", // Remove the default MUI border
                  },
                  "&:hover fieldset": {
                    border: "none", // Ensure no border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // Ensure no border on focus
                  },
                },
              }}
              onChange={(e) => setOtpText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                height: "38px",
                width: "140px",
                color: theme.palette.background.primary,
                border: "1px solid #006AE6",
                textTransform: "none",
              }}
              variant="outlined"
              onClick={() => {
                resendOtp();
              }}
            >
              Resend OTP
            </Button>
            <Button
              sx={{
                height: "38px",
                width: "140px",
                backgroundColor: theme.palette.background.primary,
                color: "white",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.background.primary,
                  color: "white",
                },
              }}
              variant="contained"
              onClick={verifyOtp}
            >
              {isLoading ? (
                <CircularProgress
                  size={26}
                  sx={{
                    color: "white",
                  }}
                />
              ) : (
                "Verify"
              )}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          background: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundColor: theme.palette.background.default,
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Box
          mt={5}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            height: "100%", // Adjust the height to take the full available space
            overflowY: "auto", // Enable scrolling inside the Box if content overflows
          }}
        >
          <Box mb={3}>
            <img src={Logo} alt="" style={{ width: "160px" }} />
          </Box>

          <Box
            sx={{
              width: "100%",
              flexGrow: "1",
              overflowY: "auto",
              maxHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              paddingX: 2,
            }}
          >
            <Card
              variant="outlined"
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "6px",
                justifyContent: "center",
                width: "100%",
                maxWidth: "520px",
                padding: "30px 0px",
                border: "1px solid #E2F0FA",
                overflowY: "auto", // Enable scrolling inside the card
                height: "100%", // Ensure the card respects the parent Box's height
              }}
            >
              <CardContent>
                <form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      mb={3.5}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: { xs: "center", sm: "start" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.background.mainH1,
                          fontSize: { xs: 16, sm: 18 },
                        }}
                        variant="subtitle1"
                      >
                        Welcome to Coursfy! Lets begin.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 14, sm: 16 },
                          color: theme.palette.text.body,
                        }}
                        variant="subtitle1"
                      >
                        We need your input to prepare your portal
                      </Typography>
                    </Box>

                    <Box mb={3}>
                      <CustomInputLabel title={"Name"} />
                      <TextField
                        sx={{
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          width: "440px",
                          height: "40px",
                          background: theme.palette.background.main,
                          border: "1px solid #E2F0FA",
                          "& .MuiOutlinedInput-root": {
                            width: "100%", // Ensure the width is maintained
                            height: "100%", // Ensure the height is maintained
                            padding: 0, // Prevent additional padding from affecting size
                            background: theme.palette.background.main, // Ensure no background on root
                            "& input": {
                              padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                            },
                            "& input::placeholder": {
                              fontSize: "16px", // Customize the placeholder font size
                            },
                            "& fieldset": {
                              border: "none", // Remove the default MUI border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensure no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Ensure no border on focus
                            },
                          },
                        }}
                        id="name"
                        // label=" Name"
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameErr("");
                        }}
                        helperText={nameErr}
                        error={nameErr.length > 0 ? true : false}
                      />
                    </Box>

                    <Box mb={3}>
                      <CustomInputLabel title={"Email"} />
                      <TextField
                        sx={{
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          width: "440px",
                          height: "40px",
                          background: theme.palette.background.main,
                          border: "1px solid #E2F0FA",
                          "& .MuiOutlinedInput-root": {
                            width: "100%", // Ensure the width is maintained
                            height: "100%", // Ensure the height is maintained
                            padding: 0, // Prevent additional padding from affecting size
                            background: theme.palette.background.main, // Ensure no background on root
                            "& input": {
                              padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                            },
                            "& input::placeholder": {
                              fontSize: "16px", // Customize the placeholder font size
                            },
                            "& fieldset": {
                              border: "none", // Remove the default MUI border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensure no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Ensure no border on focus
                            },
                          },
                        }}
                        id="email"
                        // label=" Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailErr("");
                        }}
                        helperText={emailErr}
                        error={emailErr.length > 0 ? true : false}
                      />
                    </Box>
                    <Box mb={3}>
                      <CustomInputLabel isRequired={false} title={"Phone"} />
                      <TextField
                        sx={{
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          width: "440px",
                          height: "40px",
                          background: theme.palette.background.main,
                          border: "1px solid #E2F0FA",
                          "& .MuiOutlinedInput-root": {
                            width: "100%", // Ensure the width is maintained
                            height: "100%", // Ensure the height is maintained
                            padding: 0, // Prevent additional padding from affecting size
                            background: theme.palette.background.main, // Ensure no background on root
                            "& input": {
                              padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                            },
                            "& input::placeholder": {
                              fontSize: "16px", // Customize the placeholder font size
                            },
                            "& fieldset": {
                              border: "none", // Remove the default MUI border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensure no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Ensure no border on focus
                            },
                          },
                        }}
                        id="phone"
                        // label="Phone Number"
                        placeholder="+46123456789"
                        type="text"
                        onChange={(e) => {
                          setPhone(e.target.value);
                          setPhoneErr("");
                        }}
                        helperText={phoneErr}
                        error={phoneErr.length > 0 ? true : false}
                      />
                    </Box>

                    <Box mb={3}>
                      <CustomInputLabel title={"Password"} />
                      <TextField
                        sx={{
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          width: "440px",
                          height: "40px",
                          background: theme.palette.background.main,
                          border: "1px solid #E2F0FA",
                          "& .MuiOutlinedInput-root": {
                            width: "100%", // Ensure the width is maintained
                            height: "100%", // Ensure the height is maintained
                            padding: 0, // Prevent additional padding from affecting size
                            background: theme.palette.background.main, // Ensure no background on root
                            "& input": {
                              padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                            },
                            "& input::placeholder": {
                              fontSize: "16px", // Customize the placeholder font size
                            },
                            "& fieldset": {
                              border: "none", // Remove the default MUI border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensure no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Ensure no border on focus
                            },
                          },
                        }}
                        id="password"
                        // label="Password"
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setPasswordErr("");
                        }}
                        helperText={passwordErr}
                        error={passwordErr.length > 0 ? true : false}
                      />
                    </Box>

                    <Box mb={3}>
                      <CustomInputLabel title={"Confirm password"} />
                      <TextField
                        sx={{
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          width: "440px",
                          height: "40px",
                          background: theme.palette.background.main,
                          border: "1px solid #E2F0FA",
                          "& .MuiOutlinedInput-root": {
                            width: "100%", // Ensure the width is maintained
                            height: "100%", // Ensure the height is maintained
                            padding: 0, // Prevent additional padding from affecting size
                            background: theme.palette.background.main, // Ensure no background on root
                            "& input": {
                              padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                            },
                            "& input::placeholder": {
                              fontSize: "16px", // Customize the placeholder font size
                            },
                            "& fieldset": {
                              border: "none", // Remove the default MUI border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensure no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Ensure no border on focus
                            },
                          },
                        }}
                        id="Confirmpassword"
                        // label="Confirm Password"
                        type="password"
                        helperText={passwordErr}
                        error={passwordErr.length > 0 ? true : false}
                      />
                    </Box>

                    <Box mb={3}>
                      {/* <FormControl sx={{ width: "400px" }}> */}
                      <CustomInputLabel title={"Country"} />
                      {/* <InputLabel id="demo-simple-select-label">
                        Country
                      </InputLabel> */}
                      <Select
                        sx={{
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          width: "440px",
                          height: "40px",
                          background: theme.palette.background.main,
                          border: "1px solid #E2F0FA",
                          "& .MuiOutlinedInput-root": {
                            width: "100%", // Ensure the width is maintained
                            height: "100%", // Ensure the height is maintained
                            padding: 0, // Prevent additional padding from affecting size
                            background: theme.palette.background.main, // Ensure no background on root
                            "& input": {
                              padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                            },
                            "& input::placeholder": {
                              fontSize: "16px", // Customize the placeholder font size
                            },
                            "& fieldset": {
                              border: "none", // Remove the default MUI border
                            },
                            "&:hover fieldset": {
                              border: "none", // Ensure no border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Ensure no border on focus
                            },
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={country}
                        // label="country"
                        onChange={handleChange}
                      >
                        {Object.entries(COUNTRIES).map((e) => {
                          return <MenuItem value={e[0]}>{e[1]?.name}</MenuItem>;
                        })}
                      </Select>
                      {countryErr.length > 0 ? (
                        <Typography
                          color={"red"}
                          fontSize={"12px"}
                          sx={{
                            ml: 2.2,
                          }}
                        >
                          {countryErr}
                        </Typography>
                      ) : null}
                      {/* </FormControl> */}
                    </Box>

                    <Box
                      mb={1}
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: { xs: "column", sm: "row" },
                        whiteSpace: "nowrap",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: { xs: 14, sm: 16 }, // Set the font size for the label
                          },
                        }}
                        control={
                          <Checkbox
                            checked={iAgree}
                            name="checked"
                            color="primary"
                            onChange={() => {
                              setIAgree(!iAgree);
                            }}
                          />
                        }
                        label="I agree to the"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "-12px",
                        }}
                      >
                        <Link
                          href="https://firebasestorage.googleapis.com/v0/b/iapps-95882.appspot.com/o/local-docs%2Fuser%20agreement%20.pdf?alt=media&token=f32049a0-5dcf-4707-80f4-0c8f3c92284f" // Replace with your actual Terms & Conditions URL
                          target="_blank"
                          rel="noopener"
                          underline="none"
                          sx={{ marginLeft: "4px" }}
                        >
                          <Typography
                            fontWeight="light"
                            color="primary"
                            sx={{
                              textDecoration: "underline",
                              marginLeft: "4px",
                              fontSize: { xs: 14, sm: 16 },
                            }}
                          >
                            Terms & Conditions
                          </Typography>
                        </Link>
                        <Typography
                          fontWeight="light"
                          sx={{
                            textDecoration: "none",
                            marginLeft: "4px",
                            fontSize: { xs: 14, sm: 16 },
                          }}
                        >
                          and
                        </Typography>

                        <Link
                          href="https://firebasestorage.googleapis.com/v0/b/iapps-95882.appspot.com/o/local-docs%2FPRIVACY%20POLICY%20Coursfy%20.pdf?alt=media&token=706edf2d-a4e1-4b3d-a1d9-7eb9e1070784" // Replace with your actual Terms & Conditions URL
                          target="_blank"
                          rel="noopener"
                          underline="none"
                          sx={{ marginLeft: "4px" }}
                        >
                          <Typography
                            fontWeight="light"
                            color="primary"
                            sx={{
                              textDecoration: "underline",
                              marginLeft: "4px",
                              fontSize: { xs: 14, sm: 16 },
                            }}
                          >
                            Privacy Policy
                          </Typography>
                        </Link>
                      </Box>
                    </Box>

                    <Button
                      sx={{
                        width: "140px",
                        height: "38px",
                        backgroundColor: theme.palette.background.primary,
                        color: "white",
                        margin: "30px 0 40px 0",
                      }}
                      fontWeight="regular"
                      disableElevation
                      fullWidth
                      variant="contained"
                      size="large"
                      // type="submit"
                      disabled={!iAgree}
                      onClick={register}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size={26}
                          sx={{
                            color: "white",
                          }}
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default Signup;
