import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Card } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import StarIcon from "@mui/icons-material/Star";
import "../App.css";

const CourseCard = ({ course, onNav, theme }) => {
  return (
    <>
      <Card
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "250px",
          minWidth: "250px",
          height: "365px",
          borderRadius: 3,
          border: "1px solid #dadada",
          padding: "15px 5px 15px 5px",
          background: theme.palette.background.main,
        }}
        onClick={() => {
          onNav();
        }}
      >
        <Box
          sx={{
            width: "220px",
            height: "220px",
          }}
        >
          <img
            className="img_card"
            component="img"
            alt="green iguana"
            src={course?.courseImage}
          />
        </Box>

        <CardContent
          sx={{
            width: "100%",
            height: "105px",
            padding: "0px 10px 0px 10px",
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Box
            display="flex"
            flexDirection={"column"}
            sx={{
              maxWidth: "230px",
              height: "70px",
            }}
          >
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                fontSize: "16px",
                fontWeight: "bold",
                maxWidth: "230px",
                WebkitLineClamp: 2,
                lineClamp: 2,
                textOverflow: "ellipsis",
              }}
            >
              {course?.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                maxWidth: "230px",
                WebkitLineClamp: 1,
                lineClamp: 1,
                textOverflow: "ellipsis",
              }}
            >
              {course?.organization?.name
                ? course.organization.name
                : "Unknown"}
            </Typography>
          </Box>

          <Box
            mt={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              <StarIcon
                sx={{ mr: "3px", fontSize: "22px", color: "text.secondary" }}
              />
              <Typography variant="body2" color="text.secondary">
                {(course?.rating).toFixed(1)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" fontWeight={"bold"} fontSize={"15px"}>
                {course?.isPurchased ? (
                  <EastIcon
                    sx={{ color: theme?.palette.text.main, fontSize: "18px" }}
                  />
                ) : course?.price === 0 ? (
                  "Free"
                ) : (
                  `€ ${course?.price?.toFixed(2)}`
                )}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    </>
  );
};

export default CourseCard;
