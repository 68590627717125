import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  Avatar,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayIcon from "@mui/icons-material/Replay";
const questionTimer = 120;

const LessonQuiz = ({
  theme,
  quizzes,
  updateCourseProgress,
  handleQuizOptionChange,
  handleQuizNext,
  selectedChoices,
  setSelectedChoices,
  lesson,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [retake, setRetake] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(questionTimer);
  const [newAns, setNewAns] = useState({});
  const [localLesson, setLocalLesson] = useState(null);
  const [localCalculation, setLocalCalculation] = useState(0);
  const [isRetake, setIsRetake] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (timeLeft === 0) {
      if (currentQuestionIndex + 1 === quizzes.length) setTimeLeft(0);
      else setTimeLeft(questionTimer);
      handleNextQuestionChange(false);
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  useEffect(() => {
    setLocalLesson(lesson);
  }, [lesson]);

  const submitAnswer = async () => {
    setIsSubmitting(true);
    const formattedAns = [];
    Object.entries(newAns).forEach((el) => {
      formattedAns.push({
        question: el[0],
        option: el[1],
      });
    });

    await updateCourseProgress({
      lesson: lesson?._id,
      answers: formattedAns,
    });
    setRetake(false);
    setIsSubmitting(false);
  };

  const handleNextQuestionChange = (is_bit = true) => {
    if (is_bit) {
      quizzes[currentQuestionIndex]?.options?.map((e) => {
        if (
          e?.isCorrect &&
          e?._id === newAns[quizzes[currentQuestionIndex]?._id]
        )
          setLocalCalculation(localCalculation + 1);
      });
    }

    if (currentQuestionIndex + 1 === quizzes.length) {
      submitAnswer();
      setTimeLeft(0);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const setQuestionAnswer = (id) => {
    setNewAns((ans) => {
      return { ...ans, [`${quizzes[currentQuestionIndex]?._id}`]: id };
    });
  };

  const CompletedQuizSection = ({ lesson }) => {
    return (
      <Box>
        <Card
          sx={{
            width: "100%", // Set width to 100% for responsiveness
            maxWidth: "774px", // Max width of 774px
            height: "100%",
            maxHeight: "774px",
            minHeight: "550px", // Min height of 550px
            margin: "10px auto 40px auto",
            padding: "20px 10px",
            paddingBottom: "0px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
          }}
        >
          <CardContent sx={{ padding: "20px" }}>
            <Box
              mb={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ color: "#566477", fontSize: { xs: "12px", md: "14px" } }}
              >
                Requirement: Pass {localLesson?.passingPercentage} % or higher
              </Typography>

              <Button
                sx={{
                  textTransform: "none",
                  color: "#566477",
                  fontSize: { xs: "12px", md: "14px" },
                }}
                onClick={() => {
                  setIsRetake(true);
                  setRetake(!retake);
                  setCurrentQuestionIndex(0);
                  setNewAns({});
                  setTimeLeft(questionTimer);
                  setLocalCalculation(0);
                }}
              >
                <ReplayIcon sx={{ fontSize: { xs: "17px", md: "22px" } }} />
                Try again
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "535px",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: 22, mb: "25px" }}>
                Quiz Result
              </Typography>
              {/* <Typography>{quizzes?.length} Questions</Typography> */}
              <Typography sx={{ fontSize: "14px", mb: "25px" }}>
                You answered {quizzes?.length}/{quizzes?.length} questions
                correctly.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "25px",
                }}
              >
                <CircularProgressWithLabel
                  value={getRetakeValue()}
                  size={140}
                />
                <Box sx={{ mt: "10px", display: "flex", alignItems: "center" }}>
                  <Avatar
                    sx={{
                      bgcolor: "transparent",
                      color:
                        getRetakeValue() >= localLesson?.passingPercentage
                          ? "#05BA23"
                          : "red",
                    }}
                  >
                    {getRetakeValue() >= localLesson?.passingPercentage ? (
                      <DoneIcon />
                    ) : (
                      <CancelIcon />
                    )}
                  </Avatar>
                  <Box>
                    <Typography
                      style={{
                        color:
                          getRetakeValue() >= localLesson?.passingPercentage
                            ? "#05BA23"
                            : "red",
                      }}
                    >
                      {getRetakeValue() >= localLesson?.passingPercentage
                        ? "Passed"
                        : "Failed"}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {localLesson?.lessonProgress ? (
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                    Best Result:
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14 }}
                    // style={
                    //   {
                    //     color: localLesson?.lessonProgress?.isPassed
                    //       ? "#05BA23"
                    //       : "red",
                    //   }
                    // }
                  >
                    {/* {localLesson?.lessonProgress?.isPassed
                      ? "PASSED "
                      : "FAILED "}{" "} */}
                    {localLesson?.lessonProgress?.percentageAchieved}%
                  </Typography>
                </Box>
              ) : null}

              {/* <Typography
                variant="subtitle2"
                component="div"
                color="textSecondary"
              >
                We keep your first passing score
              </Typography> */}
            </Box>

            {/* <Box mt={2}>
              <Button
                sx={{
                  width: "140px",
                  height: "40px",
                  textTransform: "none",
                  bgcolor: "#006AE6",
                  "&:hover": {
                    bgcolor: "#006AE6",
                  },
                }}
                variant="contained"
              >
                Next Lesson
              </Button>
            </Box> */}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const CircularProgressWithLabel = (props) => {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size={props.size || 40}
          variant="determinate"
          {...props}
          sx={{ color: "#05BA23" }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            sx={{ fontSize: 22, fontWeight: "bold" }}
            variant="caption"
            component="div"
            // color="textSecondary"
          >
            {`${Math.round(props.value)}%`}
          </Typography>
          {/* <Typography
            sx={{ mt: 1 }}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            2 of 2 correct
          </Typography> */}
        </Box>
        {/* <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          borderRadius="50%"
          border={`18px solid rgba(0, 0, 0, 0.1)`}
          boxSizing="border-box"
          width={140}
          height={140}
        /> */}
      </Box>
    );
  };

  const setAnswer = (localQuiz, currentIndex) => {
    const exists = answers.some((obj) => obj?.question === localQuiz?._id);
    if (!exists) {
      const tempArr = answers;
      tempArr.push({
        question: localQuiz?._id,
        option: localQuiz?.options[currentIndex]?._id,
      });
      setAnswers(tempArr);
    }

    if (currentIndex + 1 !== quizzes.length)
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    else {
      updateCourseProgress({
        lesson: lesson?._id,
        answers,
      });
    }
  };

  const getRetakeValue = () => {
    return isRetake
      ? (localCalculation / quizzes.length) * 100
      : localLesson?.lessonProgress?.percentageAchieved;
  };

  return (
    <>
      {!retake && lesson?.lessonProgress ? (
        <CompletedQuizSection />
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            padding: { xs: "10px", sm: "20px" }, // Responsive padding for different screen sizes
            margin: "0 auto", // Center the content
          }}
        >
          {quizzes && quizzes?.length > 0 && (
            <Card
              sx={{
                width: "100%",
                maxWidth: "774px",
                height: "100%",
                maxHeight: "774px",
                minHeight: "550px", // Min height of 550px
                margin: { xs: "10px 0 20px 0", sm: "10px auto 40px auto" }, // Responsive margin
                paddingBottom: "0px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                bgcolor: theme.palette.background.default,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    xs: "20px",
                    sm: "30px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: { xs: 1, sm: 2 }, // Adjust margin bottom for smaller screens
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AccessAlarmIcon sx={{ mr: 1 }} />
                    <Typography fontSize={14}> {timeLeft}s</Typography>
                  </Box>
                  <Typography fontSize={14}>
                    {currentQuestionIndex + 1}/{quizzes.length}
                  </Typography>
                </Box>

                <Grid
                  container
                  sx={{
                    height: "auto",
                    minHeight: "250px", // Minimum height
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: {
                      xs: "column", // Stack items vertically on small screens
                      md: quizzes[currentQuestionIndex]?.media
                        ? "row"
                        : "column",
                    }, // Row if there's an image, column if not, on larger screens
                    justifyContent: { xs: "center", md: "space-between" },
                    alignItems: "center", // Center content vertically
                    columnGap: 1,
                    rowGap: 2, // Add some gap between rows when in column mode
                  }}
                >
                  <Grid
                    item
                    xs={12} // Ensure full width on small screens
                    md={quizzes[currentQuestionIndex]?.media ? 5 : 12}
                    sx={{
                      minHeight: quizzes[currentQuestionIndex]?.media
                        ? "auto"
                        : "250px", // Auto if image, 250px if no image
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center", // Center text horizontally
                      textAlign: "center", // Center text on all screens if no image
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        fontSize: { xs: "14px", sm: "16px" }, // Responsive font size
                      }}
                    >
                      {quizzes[currentQuestionIndex].text}
                    </Typography>
                  </Grid>
                  {quizzes[currentQuestionIndex]?.media && (
                    <Grid
                      item
                      xs={12} // Ensure full width on small screens
                      md={5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: { xs: 2, md: 0 },
                      }}
                    >
                      <img
                        style={{
                          height: "250px",
                          width: "250px",
                          objectFit: "cover",
                        }}
                        src={quizzes[currentQuestionIndex].media}
                        alt=""
                      />
                    </Grid>
                  )}
                </Grid>

                {/* <Typography
                  variant="h6"
                  sx={{ textAlign: "left", mt: 2, mb: 1 }}
                >
                  Options
                </Typography> */}
                <Grid mt={2} mb={4} container direction="column" rowSpacing={2}>
                  {quizzes[currentQuestionIndex].options.map(
                    (option, index) => (
                      <Grid item key={index}>
                        <Button
                          sx={{
                            maxWidth: "532px",
                            width: "100%",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            height: "40px",
                            bgcolor:
                              option?._id ===
                              newAns[quizzes[currentQuestionIndex]?._id]
                                ? "primary.main"
                                : "white",
                            color:
                              option?._id ===
                              newAns[quizzes[currentQuestionIndex]?._id]
                                ? "white"
                                : "#006AE6",
                            borderColor:
                              option?._id ===
                              newAns[quizzes[currentQuestionIndex]?._id]
                                ? "primary.main"
                                : "#006AE6",
                            "&:hover": {
                              bgcolor:
                                option?._id ===
                                newAns[quizzes[currentQuestionIndex]?._id]
                                  ? "primary.dark"
                                  : "white",
                              borderColor:
                                option?._id ===
                                newAns[quizzes[currentQuestionIndex]?._id]
                                  ? "primary.dark"
                                  : "#006AE6",
                            },
                          }}
                          variant={
                            option?._id ===
                            newAns[quizzes[currentQuestionIndex]?._id]
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => setQuestionAnswer(option?._id)}
                        >
                          {option.text}
                        </Button>
                      </Grid>
                    )
                  )}
                </Grid>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!newAns[quizzes[currentQuestionIndex]?._id]}
                  sx={{
                    width: "140px",
                    height: "40px",
                    fontSize: "14px",
                    textTransform: "none",
                    borderRadius: "5px",
                    mt: 2,
                    backgroundColor: "#006AE6",
                    "&:hover": {
                      backgroundColor: "#006AE6",
                    },
                  }}
                  onClick={() => {
                    // setAnswer(
                    //   quizzes[currentQuestionIndex],
                    //   currentQuestionIndex
                    // );
                    // handleQuizNext();

                    handleNextQuestionChange();
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: "white",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </CardContent>
            </Card>
          )}
        </Box>
      )}
    </>
  );
};

export default LessonQuiz;
