import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";

import BackgroundImage from "../backgroundImage.svg";
import Logo from "../logoImage/BlueLogo.png";
import rocket from "../rocket.png";
import { LOOKUP_API } from "../apis";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useSnackbar } from "../context/SnackbarContext";

const VerificationCourse = ({ theme, mode, handleToggleChange }) => {
  const [referenceNum, setReferenceNum] = useState("");
  const [response, setResponse] = useState(null);
  const [stepper, setStepper] = useState(1);
  const [refNoErr, setRefNoErr] = useState("");
  const { showSnackbar } = useSnackbar();

  const Certificate = ({
    courseImage,
    courseName,
    userName,
    orgName,
    completionDate,
    certificateReferenceNo,
  }) => {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: 800,
          margin: "0 auto",
          backgroundColor: "white",
          padding: 4,
          border: "1px solid #ddd",
          borderRadius: 2,
          textAlign: "center",
          position: "relative",
        }}
      >
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          Certificate Verified Successfully
        </Typography>

        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "#5D2F90", mb: 5 }}
        >
          Certificate issued to {userName},
          {/* <Box
            component="img"
            src={rocket}
            alt="Rocket Image"
            sx={{ height: 70 }}
          /> */}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          {" "}
          <Box
            component="img"
            src={courseImage}
            alt="Course Image"
            sx={{ height: 180, width: 170, marginBottom: 4 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {" "}
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#5D2F90",
                marginBottom: 6,
                width: "70%",
                ml: 10,
              }}
            >
              {courseName}
            </Typography>
            <Typography variant="subtitle2" sx={{ marginBottom: 4 }}>
              By {orgName}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ textAlign: "left", marginBottom: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1">
              <strong>Completion Date</strong>
            </Typography>
            <Typography> {new Date(completionDate).toDateString()}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body1">
              <strong>Certificate Reference No:</strong>
            </Typography>
            <Typography> {certificateReferenceNo}</Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ marginBottom: 4 }}>
          Have questions? Reach out to us at{" "}
          <a href="mailto:info@coursfy.com">info@coursfy.com</a>
        </Typography>
      </Box>
    );
  };

  const getCertificate = async (e) => {
    try {
      e.preventDefault();

      if (referenceNum === "") {
        setRefNoErr("Reference no required");
        return null;
      }

      if (referenceNum?.length < 5) {
        setRefNoErr("Minimin 5 digit reference no required");
        return null;
      }

      const res = await LOOKUP_API.getCourseCertificate({
        referenceNumber: referenceNum,
      });

      if (res) {
        const { certificate } = res;
        const response = {
          courseImage: certificate?.course?.courseImage,
          courseName: certificate?.course?.name,
          userName: certificate?.user?.name,
          orgName: certificate?.organization?.name,
          completionDate: certificate?.issuedAt,
          certificateReferenceNo: certificate?.referenceNumber,
        };
        setResponse(response);
        setStepper(2);
      } else {
        showSnackbar({
          message: "Certificate Not Found ",
          severity: "error",
        });
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          background: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundColor: theme.palette.background.default,
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Header
          theme={theme}
          mode={mode}
          handleToggleChange={handleToggleChange}
        />

        {stepper === 1 && (
          <Box
            width="100%"
            sx={{
              paddingX: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box mb={3} sx={{ textAlign: "center" }}>
              <img src={Logo} alt="" style={{ width: "160px" }} />
            </Box>{" "}
            <Card
              variant="outlined"
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "6px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "520px",
                padding: "20px",
                border: "1px solid #E2F0FA",
              }}
            >
              <CardContent>
                <form onSubmit={getCertificate}>
                  {/* <Typography sx={{ fontWeigh: "bold", fontSize: 18, mb: 2 }}>
                    Welcome to Coursfy! Lets get your Certificate.
                  </Typography> */}
                  <Typography
                    textAlign={"center"}
                    sx={{ fontWeigh: "bold", fontSize: 20, mb: 8 }}
                  >
                    <strong>Certificate Verification</strong>
                  </Typography>
                  {/* <Typography sx={{ color: "lightgray", mb: 2 }}>
                    We need your input to prepare your Certificate
                  </Typography> */}
                  <TextField
                    sx={{
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      maxWidth: "400px",
                      height: "40px",
                      background: theme.palette.background.main,
                      border: "1px solid #E2F0FA",
                      "& .MuiOutlinedInput-root": {
                        width: "100%", // Ensure the width is maintained
                        height: "100%", // Ensure the height is maintained
                        padding: 0, // Prevent additional padding from affecting size
                        background: theme.palette.background.main, // Ensure no background on root
                        "& input": {
                          padding: "0 10px", // Adjust padding as needed but ensure it doesn't affect the height
                        },
                        "& input::placeholder": {
                          fontSize: "16px", // Customize the placeholder font size
                        },
                        "& fieldset": {
                          border: "none", // Remove the default MUI border
                        },
                        "&:hover fieldset": {
                          border: "none", // Ensure no border on hover
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", // Ensure no border on focus
                        },
                      },
                    }}
                    id="name"
                    placeholder="Certificate Reference No"
                    onChange={(e) => {
                      setReferenceNum(e.target.value);
                      setRefNoErr("");
                      // setEmail(e.target.value);
                      // validateIfEmailorPhone(e.target.value);
                      // setEmailErr("");
                    }}
                    helperText={refNoErr}
                    error={refNoErr.length > 0 ? true : false}
                  />
                  {/* <TextField
                    required
                    fullWidth
                    placeholder="Certificate Reference No"
                    inputProps={{
                      minLength: 5,
                      pattern: "^[a-zA-Z0-9]+$",
                    }}
                    onChange={(e) => {
                      if (
                        e.target.value.length >= 5 &&
                        /^[a-zA-Z0-9]+$/.test(e.target.value)
                      ) {
                        setReferenceNum(e.target.value);
                      }
                    }}
                  /> */}
                  <Box sx={{ width: "100%", textAlign: "center", mt: 4 }}>
                    <Button
                      onClick={() => getCertificate()}
                      sx={{
                        width: "140px",
                        height: "40px",
                        textTransform: "none",
                        fontSize: 15,
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Box>
        )}

        {stepper === 2 && (
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "1200px" }}
          >
            {" "}
            <Box mb={3} sx={{ textAlign: "center" }}>
              <img src={Logo} alt="" style={{ width: "160px" }} />
            </Box>{" "}
            <Certificate {...response} />{" "}
          </Box>
        )}
      </Grid>

      <Box>
        <Footer
          theme={theme}
          mode={mode}
          handleToggleChange={handleToggleChange}
        />
      </Box>
    </>
  );
};

export default VerificationCourse;
