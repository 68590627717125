import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";

const FAQs = ({ theme, mode, handleToggleChange }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "24px", sm: "28px", md: "30px" },
                whiteSpace: "nowrap",
              }}
            >
              FAQs
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 5,
              backgroundColor: theme.palette.background.main,
              maxWidth: "100%",
            }}
          >
            <Box sx={{ padding: 5 }}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  1. {t("faqs.question1")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer1")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  2. {t("faqs.question2")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer2")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  3. {t("faqs.question3")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer3")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  4. {t("faqs.question4")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer4")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  5. {t("faqs.question5")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer5")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  6. {t("faqs.question6")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer6")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  7. {t("faqs.question7")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer7")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  8. {t("faqs.question8")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer8")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  9. {t("faqs.question9")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer9")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  10. {t("faqs.question10")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer10")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  11. {t("faqs.question11")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer11")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  12. {t("faqs.question12")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer12")}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  13. {t("faqs.question13")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  }}
                >
                  {t("faqs.answer13")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </React.Fragment>
  );
};

export default FAQs;
