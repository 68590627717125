import React from "react";
import { InputLabel, Typography } from "@mui/material";

const CustomInputLabel = ({ title, isRequired = true }) => {
  return (
    <InputLabel
      shrink
      sx={{
        textAlign: "left",
        fontSize: "17px",
        ml: 0.5,
        color: "#566477",
      }}
      htmlFor="bootstrap-input"
    >
      {title}
      {isRequired ? (
        <Typography
          sx={{ ml: 0.5 }}
          fontSize={"20px"}
          color={"red"}
          component="span"
        >
          *
        </Typography>
      ) : null}
    </InputLabel>
  );
};

export default CustomInputLabel;
