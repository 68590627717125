import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { STRIPE_API } from "../apis";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeAPIKey =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_STIPE_KEY
    : "pk_test_51OIr1LFgcx8TKMmqpRRA1dyGYC9Zc9SSWDFnDK7FRFbVOn25xBvIkxENOro2NuQ0sLsm1onASUusphckskUC1qBu00ookJwHI5";

const stripePromise = loadStripe(stripeAPIKey);

const PaymentMethods = (props) => {
  const [clientSecret, setClientSecret] = useState("");

  const getSetupIntent = async () => {
    try {
      const res = await STRIPE_API.setupIntent();

      if (res) {
        setClientSecret(res.client_secret);
      }
    } catch (error) {
      console.log("ERROR ", error);
    }
  };

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    getSetupIntent();
  }, []);

  const options = { clientSecret };

  return (
    <Box
      sx={{
        marginTop: "80px",
        //   backgroundColor: "#F5F6FA",
        // padding: 10,
      }}
    >
      <div id="checkout">
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </Box>
  );
};

export default PaymentMethods;
