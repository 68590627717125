import ROUTES from "./routes";
import INTERCEPTOR from "../utils/interceptor";
import STORAGE from "../utils/local.storage";

const getLookup = async (token = "") => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.LOOKUP}/user`,
    auth: true,
    localtoken: token,
  });
  if (res) return res;
  return null;
};

const getOrgProfile = async (id) => {
  let res = await INTERCEPTOR.get({
    url: `${ROUTES.LOOKUP}/single-for-user/${id}`,
    auth: true,
  });
  if (res) return res;
  return null;
};
// Function to fetch stock profile images
const getStockProfileImages = async () => {
  try {
    const res = await INTERCEPTOR.get({
      url: `${ROUTES.LOOKUP}/user`, // Adjust this URL as per your API endpoint
      auth: true, // Ensure authentication is correctly handled
    });

    // Check if the response is successful and contains the images
    if (res && res.data && Array.isArray(res.data.stockProfileImages)) {
      return res.data.stockProfileImages; // Assuming stockProfileImages is an array of image URLs
    } else {
      throw new Error(
        "Failed to fetch stock profile images or invalid response format"
      );
    }
  } catch (error) {
    console.error("Error fetching stock profile images:", error);
    return []; // Return empty array or handle error as necessary
  }
};

const contactUs = async (body) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.LOOKUP}/contact-us`,
    auth: true,
    body,
  });
  if (res) return res;
  return null;
};

const getCourseCertificate = async (body) => {
  let res = await INTERCEPTOR.post({
    url: `${ROUTES.LOOKUP}/verify-cert`,
    auth: true,
    body,
  });
  if (res) return res;
  return null;
};

const LOOKUP_API = {
  getLookup,
  getOrgProfile,
  getStockProfileImages,
  contactUs,
  getCourseCertificate,
};

export default LOOKUP_API;
