import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  Divider,
  Typography,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CardHeader,
  Avatar,
  DialogContentText,
  Pagination
} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useSearchParams, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Header from "../components/Header";
// import Breadcrumb from "../../components/Breadcrumb";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import STORAGE from "../utils/local.storage";
import { useDispatch } from "react-redux";
import { set } from "../actions/authActions";
import { useTranslation } from "react-i18next";
import PaymentIcon from "@mui/icons-material/Payment";
import {
  LOOKUP_API,
  STRIPE_API,
  USER_API,
  COURSE_API,
  AUTH_API,
} from "../apis";
import { useSnackbar } from "../context/SnackbarContext";
import COLORS from "../utils/colors";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
const perPage = 5;

const euCountries = [
  { name: "Austria", code: "AT" },
  { name: "Belgium", code: "BE" },
  { name: "Bulgaria", code: "BG" },
  { name: "Croatia", code: "HR" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Estonia", code: "EE" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Germany", code: "DE" },
  { name: "Greece", code: "GR" },
  { name: "Hungary", code: "HU" },
  { name: "Ireland", code: "IE" },
  { name: "Italy", code: "IT" },
  { name: "Latvia", code: "LV" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Malta", code: "MT" },
  { name: "Netherlands", code: "NL" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Romania", code: "RO" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Spain", code: "ES" },
  { name: "Sweden", code: "SE" },
];

const stockProfileImages = [
  "https://soar-app-staging.s3.amazonaws.com/Emoji+1.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+2.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+3.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+4.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+5.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+6.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+7.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+8.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+9.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+10.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+11.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+12.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+13.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+14.png",
  "https://soar-app-staging.s3.amazonaws.com/Emoji+15.png",
];

const Setting = ({ theme, mode, handleToggleChange }) => {
  const [edit, setEdit] = useState(false);
  const [showGeneral, setShowGeneral] = useState(true);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [website, setWebsite] = useState("");
  const [headline, setHeadline] = useState("");
  const [bio, setBio] = useState("");
  const [linkedinErr, setLinkedinErr] = useState("");
  const [websiteErr, setWebsiteErr] = useState("");
  const [showEditCard, setShowEditCard] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  // const [profileImages, setProfileImages] = useState([]);
  const [course, setCourse] = useState({});
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifiedNewPassword, setVerifiedNewPassword] = useState("");
  const [oldPassErr, setOldPassErr] = useState("");
  const [newPassErr, setNewPassErr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([1]);
  const [isDelete, setIsDelete] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = STORAGE.getStorage();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lookupState = useSelector((state) => state.lookup);

  useEffect(() => {
    getPaymentPlans();
    getTransactions();
    getInfo();
    if (searchParams) {
      const currentpage = searchParams.get("currentpage");
      if (currentpage) setCurrentPage(parseInt(currentpage));
    }
  }, []);

  const getPaymentPlans = async () => {
    try {
      const res = await STRIPE_API.getPaymentMethods();
      if (res) setPaymentMethods(res?.paymentMethods);
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const getTransactions = async () => {
    try {
      const res = await USER_API.getTransactions({
        "page": page,
        "perPage": perPage
    });
    if(res) {
      setTransactions(res?.transactions);
      setTotalPages(res?.pagination?.totalItems);
    }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    getTransactions()
  }, [page])

  const deleteAccount = async () => {
    try {
      await USER_API.removeAccount();
      STORAGE.logout();
      const auth_user = await AUTH_API.loginOpenUser();
      dispatch(
        set({ authorities: auth_user?.authorities, token: auth_user?.token })
      );
      navigate("/");
    } catch (error) {
      console.log("error ", error);
    }
  };

  const getInfo = async () => {
    try {
      const res = await USER_API.getProfile();
      if (res && res?.user) {
        setName(res?.user?.name);
        setImage(res?.user?.profileImage);
        setEmail(res?.user?.email);
        setPhone(res?.user?.phone);
      }
      // if (res && res?.organization) {
      //   setName(res.organization.name);
      //   setLinkedin(res.organization.linkedIn);
      //   setWebsite(res.organization.website);
      //   setHeadline(res.organization.headline);
      //   setCountry(res.organization.country);
      //   setBio(res.organization.bio);
      //   setImage(res?.organization?.profileImage);
      // }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const courseDetail = async (id, token = null) => {
    try {
      const res = await COURSE_API.getCoursesDetail(id, token);
      if (res) setCourse(res?.course);
    } catch (error) {
      console.log("Error ", error);
    }
  };

  // useEffect(() => {
  //   // Function to fetch stock profile images
  //   const fetchStockProfileImages = async () => {
  //     try {
  //       const res = await LOOKUP_API.getStockProfileImages();
  //       if (res && res.data.stockProfileImages) {
  //         setProfileImages(res.data.stockProfileImages);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching stock profile images:", error);
  //     }
  //   };

  //   fetchStockProfileImages();
  // }, []); // Empty dependency array ensures this effect runs only once on component mount

  const updatePassword = async () => {
    try {
      if (!newPassword) setNewPassErr("Password required");
      if (!oldPassword) setOldPassErr("Password required");

      // if (!newPassErr || !oldPassErr) {
      //   return null;
      // }

      if (newPassword !== verifiedNewPassword) {
        setNewPassErr("");
        setOldPassErr("");
        showSnackbar({
          message: "New Password Mismatched",
          severity: "error",
        });
        return null;
      }

      const res = await USER_API.updatePassword({
        oldPassword,
        newPassword,
      });
      console.log("res", res);
      if (res && res.status === 200) {
        showSnackbar({
          message: "Password Updated Successfully",
          severity: "success",
        });
        setNewPassErr("");
        setOldPassErr("");
      } else
        showSnackbar({
          message: "Password Update Failed",
          severity: "error",
        });
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleEdit = () => {
    setShowEditCard(true);
    setEdit(true);
  };

  const handleClose = () => {
    setShowEditCard(false);
    setEdit(false);
  };

  const handleImageSelect = (img) => {
    setImage(img);
    // setOpen(false);
  };

  const handleConfirm = () => {
    setShowEditCard(false);
    setEdit(false);
    // Save changes logic here
  };

  const PaymentCard = ({ el }) => {
    const { expiry = "test", brand = "test", last4 = "1234" } = el;

    return (
      <Box
        sx={{
          borderRadius: 2,
          boxShadow: "0px 4px 8px rgba(1, 1, 1, 0.102)",
          padding: 3,
          backgroundColor: theme.palette.background.secondary,
          minWidth: "280px",
          maxWidth: "350px",
          minHeight: "280px",
          width: "100%", // Ensures it stretches within the available space between min and max width
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CreditCardIcon sx={{ fontSize: "30px" }} />
          <Typography sx={{ fontWeight: "bold", ml: 2 }}>
            Card Details
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Typography>Card number</Typography>
          <Typography>*** *** {last4}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Typography>Expiry Date</Typography>
          <Typography>{expiry}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Typography
            sx={{
              overflowWrap: "break-word",
              width: "70%",
              textAlign: "left",
            }}
          >
            CARD
          </Typography>
          <Typography>{brand}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Button variant="contained" sx={{ backgroundColor: "red" }}>
            Delete Card
          </Button>
        </Box>
      </Box>
    );
  };

  const { showSnackbar } = useSnackbar();
  //   const storage = STORAGE.getStorage();

  //   const getCountryName = () => {
  //     let countryName = "";
  //     euCountries.forEach((el) => {
  //       if (el?.code === country) countryName = el?.name;
  //     });
  //     return countryName;
  //   };

  //   const getInfo = async () => {
  //     try {
  //       const res = await AUTH_API.getSingleOrg(
  //         storage?.admin?.organization?._id
  //       );
  //       if (res && res?.organization) {
  //         setName(res.organization.name);
  //         setLinkedin(res.organization.linkedIn);
  //         setWebsite(res.organization.website);
  //         setHeadline(res.organization.headline);
  //         setCountry(res.organization.country);
  //         setBio(res.organization.bio);
  //         setImage(res?.organization?.profileImage);
  //       }
  //     } catch (error) {
  //       console.log("Error ", error);
  //     }
  //   };

  //   const updateInfo = async () => {
  //     try {
  //       const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  //       if (!urlRegex.test(linkedin)) {
  //         setLinkedinErr("Invalid url");
  //         return null;
  //       }
  //       if (!urlRegex.test(website)) {
  //         setWebsiteErr("Invalid url");
  //         return null;
  //       }

  //       if (!bio || !headline) return null;

  //       const res = await AUTH_API.updateOrg(storage?.admin?.organization?._id, {
  //         name,
  //         // address,
  //         linkedIn: linkedin,
  //         organizationWebsite: website,
  //         headline,
  //         bio,
  //       });

  //       if (res) {
  //         setEdit(false);
  //         getInfo();
  //       }
  //     } catch (error) {
  //       console.log("Error ", error);
  //     }
  //   };

  //   const updateUserProfileImg = async (file) => {
  //     try {
  //       const formdata = new FormData();
  //       formdata.append("profileImage", file);

  //       const res = await AUTH_API.updateOrgProfilePic(
  //         storage?.admin?.organization?._id,
  //         formdata
  //       );

  //       if (res) {
  //         setEdit(false);
  //         getInfo();
  //       }
  //     } catch (error) {
  //       console.log("Error ", error);
  //     }
  //   };

  //   useEffect(() => {
  //     getInfo();
  //   }, []);

  function handleUpload(event) {
    const file = event.target.files[0];

    const imageUrl = URL.createObjectURL(file);
    setImage(imageUrl);
    // updateUserProfileImg(file);

    console.log("imag is ", imageUrl);
  }

  // const handleEdit = () => {
  //   setEdit(!edit);
  // };

  const handleGeneralClick = () => {
    setShowGeneral(true);
    setEdit(false);
  };

  const handlePasswordClick = () => {
    setShowGeneral(false);
    setEdit(true);
  };

  const MenuItem = ({ name, onClick, isActive }) => {
    // Added isActive prop
    return (
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          padding: 2,
          paddingLeft: 4,
          color: isActive
            ? lookupState?.branding?.colors?.primary || "green"
            : theme.palette.text.default,
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: isActive
              ? theme.palette.background.hover
              : theme.palette.background.hover,
          },
          "&:active": {
            backgroundColor: isActive ? "lightblue" : "lightblue",
          },

          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        {name}
      </Box>
    );
  };

  const TransactionRow = (props) => {
    const { el } = props;

    return (
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            mt: "30px",
            height: "auto",
          }}
        >
          <PaymentIcon
            sx={{
              fontSize: { xs: "30px", sm: "35px", md: "40px" },
              alignSelf: "center", // Center the icon vertically
            }}
          />
          <Box
            sx={{
              ml: "10px",
              display: "flex",
              flexDirection: "column",
              width: "100%", // Take full width available
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap", // Allow content to wrap on smaller screens
              }}
            >
              <Typography
                sx={{
                  flexGrow: 1,
                  flexShrink: 1,
                  maxWidth: { xs: "50%", sm: "70%", md: "65%" }, // Responsive width
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {el?.course?.name}
              </Typography>
              <Typography
                sx={{
                  maxWidth: { xs: "100%", sm: "30%" },
                  flexGrow: 0,
                  flexShrink: 0,
                  fontWeight: "bold",
                  textAlign: { xs: "left", sm: "right" },
                  ml: { xs: 0, sm: "10px" },
                }}
              >
                {el?.currency?.toUpperCase()} {el?.amount?.toFixed(2)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "14px" }, // Responsive font size
                  color: "#95A2B4",
                }}
              >
                VISA ****{el?.paymentMethod?.card?.last4}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "14px" }, // Responsive font size
                  color: "#95A2B4",
                  textAlign: { xs: "left", sm: "right" }, // Align left on small screens
                  mt: { xs: "8px", sm: "0px" }, // Add margin top on small screens
                }}
              >
                {new Date(el?.updatedAt).toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            width: "100%",
            mt: 1,
          }}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />

      <Dialog
        open={isDelete}
        onClose={() => setIsDelete(!isDelete)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Account"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is irreversible. Are you sure you want to delete the
            account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDelete(!isDelete)} color="error">
            Disagree
          </Button>
          <Button onClick={() => deleteAccount()} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        mt={5}
        width="100%"
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid
          maxWidth="1200px"
          width="100%"
          sx={{
            backgroundColor: theme.palette.background.default,
            paddingX: 2,
          }}
        >
          <Grid container columnGap={4}>
            <Grid
              item
              md={3}
              sm={12}
              width="100%"
              sx={{
                backgroundColor: theme.palette.background.secondary,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <MenuItem
                name={t("setting.profile")}
                onClick={() => setCurrentPage(1)}
                isActive={currentPage === 1}
              />{" "}
              {/* Pass isActive prop */}
              <Divider />
              <MenuItem
                name={t("setting.password_security")}
                onClick={() => setCurrentPage(2)}
                isActive={currentPage === 2}
              />
              <Divider />
              <MenuItem
                name={t("setting.payment_methods")}
                onClick={() => setCurrentPage(3)}
                isActive={currentPage === 3}
              />
              <Divider />
              <MenuItem
                name={t("setting.user_transactions")}
                onClick={() => setCurrentPage(4)}
                isActive={currentPage === 4}
              />
              {!user?.user?.organization ? (
                <>
                  <Divider />
                  <MenuItem
                    name={t("setting.delete_account")}
                    onClick={() => setCurrentPage(5)}
                    isActive={currentPage === 5}
                  />
                </>
              ) : null}
            </Grid>
            {currentPage === 1 ? (
              <React.Fragment>
                <Grid
                  item
                  md={8.67}
                  sm={12}
                  width="100%"
                  sx={{
                    backgroundColor: theme.palette.background.main,
                    padding: 5,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {showEditCard && (
                    <Box
                      className="overlay"
                      sx={{ background: theme.palette.background.overlay }}
                    ></Box>
                  )}{" "}
                  {/* Overlay for the blur effect */}
                  <Box className={showEditCard ? "blurred-background" : ""}>
                    <Box>
                      <Grid container justifyContent="center">
                        <Grid item md={12}>
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid
                              item
                              sx={{
                                textAlign: "center",
                                backgroundColor: "transparent",
                              }}
                            >
                              <CardHeader
                                avatar={
                                  <Box sx={{ position: "relative" }}>
                                    <Avatar
                                      sx={{ width: 120, height: 120 }}
                                      aria-label="recipe"
                                      src={image}
                                    >
                                      <AccountCircle
                                        sx={{
                                          fontSize: "10rem",
                                          backgroundColor:
                                            theme.palette.background.secondary,
                                          color: theme.palette.text.secondary,
                                        }}
                                      />
                                    </Avatar>
                                  </Box>
                                }
                              />
                              <Box>
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {name}
                                </Typography>
                              </Box>
                              <Box sx={{ mt: "70px" }} onClick={handleEdit}>
                                <IconButton
                                  sx={{
                                    color: theme.palette.text.default,
                                    // backgroundColor: "#006AE6",
                                    backgroundColor:
                                      lookupState?.branding?.colors?.primary ||
                                      theme.palette.background.primary,
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "100px",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "#0160ce",
                                      color: theme.palette.text.default,
                                    },
                                  }}
                                >
                                  <ModeEditOutlinedIcon
                                    sx={{
                                      color: "white",
                                      fontSize: "30px",
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mb: 2,
                                }}
                              >
                                <Typography sx={{ color: "#95A2B4" }}>
                                  Name:
                                </Typography>
                                <Typography sx={{ color: "#566477" }}>
                                  {name}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mb: 2,
                                }}
                              >
                                <Typography sx={{ color: "#95A2B4" }}>
                                  Email:
                                </Typography>
                                <Typography sx={{ color: "#566477" }}>
                                  {email}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  mb: 2,
                                }}
                              >
                                <Typography sx={{ color: "#95A2B4" }}>
                                  Phone:
                                </Typography>
                                <Typography sx={{ color: "#566477" }}>
                                  {phone}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  {showEditCard && (
                    <Box
                      className="edit-card"
                      sx={{ background: theme.palette.background.main }}
                    >
                      <Card sx={{ p: 4, border: "none", boxShadow: "none" }}>
                        <Typography
                          variant="h5"
                          sx={{ mb: 2, fontWeight: "bold" }}
                        >
                          Edit Profile
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 5,
                            mt: 5,
                            position: "relative",
                          }}
                        >
                          <Avatar
                            sx={{ width: 120, height: 120 }}
                            aria-label="recipe"
                            src={selectedImage || image}
                          >
                            <AccountCircle
                              sx={{
                                fontSize: "10rem",
                                backgroundColor:
                                  theme.palette.background.secondary,
                                color: theme.palette.text.secondary,
                              }}
                            />
                            <IconButton
                              component="span"
                              sx={{
                                position: "absolute",
                                bottom: "30px",
                                right: "30px",
                                zIndex: 1,
                              }}
                              onClick={() => setOpen(true)}
                            >
                              <CameraAltOutlinedIcon
                                sx={{ fontSize: "40px", color: "white" }}
                              />
                            </IconButton>
                          </Avatar>
                        </Box>
                        <Typography sx={{ mb: "5px" }}>Name</Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          sx={{ mb: 2 }}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{ mb: 1 }}
                          onClick={handleConfirm}
                        >
                          Confirm
                        </Button>
                        <Button
                          sx={{
                            position: "absolute",
                            top: "45px",
                            right: "20px",
                            "&:hover": {
                              backgroundColor: "transparent", // Removes background color on hover
                            },
                          }}
                          onClick={handleClose}
                        >
                          <ClearIcon
                            sx={{
                              color: theme.palette.text.default,
                              fontSize: "30px",
                              fontWeight: "100",
                            }}
                          />
                        </Button>
                      </Card>
                    </Box>
                  )}
                  <Dialog open={open} onClose={() => setOpen(false)}>
                    <Box sx={{ background: theme.palette.background.main }}>
                      <DialogTitle
                        variant="h5"
                        sx={{ mb: 2, fontWeight: "bold" }}
                      >
                        Choose Image
                        <DialogContentText>
                          Select image from following:
                        </DialogContentText>
                      </DialogTitle>
                      <DialogContent
                        sx={{
                          width: "100%",
                          maxWidth: "550px",
                          background: theme.palette.background.main,
                        }}
                      >
                        <Grid container spacing={2}>
                          {stockProfileImages.map((img, index) => (
                            <Grid
                              item
                              xs={4}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              key={index}
                              onClick={() => handleImageSelect(img)}
                            >
                              <Avatar
                                src={img}
                                sx={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                  border:
                                    selectedImage === img
                                      ? "2px solid #006AE6"
                                      : "none",
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setOpen(false)}
                          color="primary"
                          sx={{
                            position: "absolute",
                            top: "20px",
                            right: "10px",
                            "&:hover": {
                              backgroundColor: "transparent", // Removes background color on hover
                            },
                          }}
                        >
                          Done
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>
                  <style>
                    {`
                      /* Overlay for the dark blue blur effect */
                      .overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: ; /* Dark blue color with transparency */
                        // backdrop-filter: 3px; /* Blur effect */
                        z-index: 1200; /* Below the edit card */
                      }
                      
                      .edit-card {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1300; /* Above the overlay */
                        // border-radius: 2px;
                        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
                        padding: 16px;
                        max-width: 550px;
                        width: 100%;
                      }
                    `}
                  </style>
                </Grid>
              </React.Fragment>
            ) : null}

            {currentPage === 2 ? (
              <React.Fragment>
                <Grid
                  container
                  md={8.67}
                  sm={12}
                  sx={{
                    backgroundColor: theme.palette.background.main,
                    padding: 5,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Grid item xs={12} sx={{ paddingBottom: 5 }}>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                          {t("setting.password")}
                        </Typography>
                        <Typography sx={{ color: "gray" }}>
                          {t("setting.change_password")}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton>
                          <BorderColorIcon
                            sx={{
                              color: "#006AE6",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={handleEdit}
                          />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid xs={12}>
                    <Box>
                      <Typography sx={{ mb: 2 }}>
                        {t("setting.current_password")}
                      </Typography>
                      {true ? (
                        <TextField
                          sx={{ mb: 2, background: COLORS.field }}
                          fullWidth
                          type="password"
                          size="small"
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                          }}
                          helperText={oldPassErr}
                          error={oldPassErr.length > 0}
                        />
                      ) : (
                        <Typography sx={{ mb: 2 }} fontWeight="regular">
                          {" "}
                          12345
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ mb: 2 }}>
                        {" "}
                        {t("setting.new_password")}{" "}
                      </Typography>
                      {true ? (
                        <TextField
                          sx={{ mb: 2, background: COLORS.field }}
                          fullWidth
                          type="password"
                          size="small"
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          helperText={newPassErr}
                          error={newPassErr.length > 0}
                        />
                      ) : (
                        <Typography sx={{ mb: 2 }} fontWeight="regular">
                          {" "}
                          2345
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ mb: 2 }}>
                        {t("setting.verify_new_password")}
                      </Typography>
                      {true ? (
                        <TextField
                          sx={{ mb: 2, background: COLORS.field }}
                          size="small"
                          fullWidth
                          type="password"
                          onChange={(e) => {
                            setVerifiedNewPassword(e.target.value);
                          }}
                        />
                      ) : (
                        <Typography sx={{ mb: 2 }} fontWeight="regular">
                          2345
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : null}

            {currentPage === 3 ? (
              <>
                <Grid
                  item
                  md={8.67}
                  sm={12}
                  sx={{
                    backgroundColor: theme.palette.background.main,
                    padding: 5,
                    boxShadow: "0px 2px 4px rgba(1, 1, 1, 0.102)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ mb: "15px" }}>
                      <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                        Payment Methods
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Grid
                      container
                      sx={{ mt: 3 }}
                      columnGap={4}
                      rowGap={4}
                      // justifyContent={
                      //   paymentMethods.length === 1
                      //     ? "center"
                      //     : "flex-start"
                      // }
                    >
                      {paymentMethods.map((el) => (
                        <Grid
                          item
                          sx={{
                            minWidth: "280px",
                            maxWidth: "350px",
                            flexGrow: 1, // Ensure it grows within the constraints
                          }}
                        >
                          <PaymentCard el={el} />
                        </Grid>
                      ))}

                      <Grid
                        item
                        sx={{
                          borderRadius: 2,
                          boxShadow: "0px 4px 8px rgba(1, 1, 1, 0.05)",
                          padding: 2,
                          backgroundColor: theme.palette.background.secondary,
                          display: "flex",
                          justifyContent: "center",
                          minWidth: "280px",
                          maxWidth: "350px",
                          minHeight: "280px",
                          flexGrow: 1, // Ensure it grows within the constraints
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "2px dashed lightgrey",
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={() => {
                            navigate("/add-payment-method");
                          }}
                        >
                          <AddIcon sx={{ fontSize: "50px", color: "grey" }} />
                          <Typography>Add Card</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </>
            ) : null}

            {currentPage === 4 ? (
              <>
                <Grid
                  container
                  md={8.67}
                  sm={12}
                  sx={{
                    backgroundColor: theme.palette.background.main,
                    padding: 5,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Grid
                    mb={"10px"}
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                        {t("setting.my_transactions")}
                      </Typography>
                    </Box>
                  </Grid>
                  {transactions.map((el) => {
                    return <TransactionRow el={el} />;
                  })}
                  <Box
                  sx={{
                    mt: 5,
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Pagination
                    size="large"
                    color="primary"
                    // sx={{ mr: 2 }}
                    count={Math.ceil(totalPages / perPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                  />
                </Box>
                </Grid>
              </>
            ) : null}

            {currentPage === 5 ? (
              <React.Fragment>
                <Grid
                  container
                  md={8.67}
                  sm={12}
                  sx={{
                    backgroundColor: theme.palette.background.main,
                    padding: 5,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <Grid
                    xs={12}
                    item
                    mb={2}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                        {t("setting.delete_account")}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton>
                        <BorderColorIcon
                          sx={{
                            color: "blue",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                          // onClick={handleEdit}
                        />
                      </IconButton>
                    </Box>
                  </Grid>

                  <Divider sx={{ mb: 2 }} />

                  <Grid item columnSpacing={4}>
                    <Grid item md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          margin: 3,
                        }}
                      >
                        <Typography sx={{ fontWeight: "light" }}>
                          {t("setting.delete_account_description")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center", // Center horizontally
                          alignItems: "center", // Center vertically
                          margin: 2,
                          marginTop: 4,
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{ background: "red", width: "180px" }}
                          onClick={() => setIsDelete(!isDelete)}
                        >
                          {t("setting.delete_account")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>

          {currentPage === 2 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                sx={{
                  width: "150px",
                  height: "40px",
                  mt: 2,
                  textTransform: "none",
                  background: "#006AE6",
                  "&:hover": {
                    background: "#006AE6",
                  },
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  updatePassword();
                }}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </React.Fragment>
  );
};

export default Setting;
