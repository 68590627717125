const setStoage = (body, cb = null) => {
  try {
    let user = JSON.stringify(body);
    localStorage.setItem("iapps_web_user", user);
    if (cb) cb("/auth/");
    else return true;
  } catch (error) {
    return null;
  }
};

const setTheme = (str) => {
  localStorage.setItem("iapps_web_user_theme", str);
};

const getTheme = () => {
  const res = localStorage.getItem("iapps_web_user_theme");
  return res;
};

const getStorage = (cb = null) => {
  try {
    let user = localStorage.getItem("iapps_web_user");
    if (!user) return null;
    return JSON.parse(user);
  } catch (error) {
    return null;
  }
};

const getToken = (cb) => {
  try {
    let user = getStorage(cb);
    return user.token;
  } catch (error) {
    // cb();
    return null;
  }
};

const logout = (cb) => {
  try {
    localStorage.removeItem("iapps_web_user");
    cb("/");
  } catch (error) {
    return false;
  }
};

const setPersistLesson = (id) => {
  try {
    localStorage.setItem("prgrs_lsn", id);
  } catch (error) {
    console.log("Error ", error);
  }
};

const getPersistLesson = (id) => {
  try {
    const res = localStorage.getItem("prgrs_lsn");
    return res;
  } catch (error) {
    console.log("Error ", error);
    return null;
  }
};

const STORAGE = {
  setStoage,
  getStorage,
  logout,
  getToken,
  getPersistLesson,
  setPersistLesson,
  setTheme,
  getTheme,
};

export default STORAGE;
