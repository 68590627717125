import { countries } from "countries-list";

const getCurrentInProgressLesson = (lessons = []) => {
  let currentLesson = null;
  for (let i = 0; i < lessons.length; i++) {
    if (!lessons[i]?.lessonProgress) {
      currentLesson = lessons[i];
      break;
    }

    if (
      lessons[i]?.lessonProgress &&
      lessons[i]?.lessonProgress?.isCompleted === false
    ) {
      currentLesson = lessons[i];
      break;
    }
  }

  return currentLesson;
};

const enableCourseIconChecks = (isPurchased, e, index) => {
  // if (!isPurchased) return "LOCK";
  // if (index === 0) {
  //   if (e?.lessonProgress && e?.lessonProgress?.isCompleted) return "COMPLETED";
  //   else if (e?.lessonProgress && !e?.lessonProgress?.isCompleted)
  //     return "RESUME";
  //   else return "START";
  // }
  // if (e?.lessonProgress && e?.lessonProgress?.isCompleted) return "COMPLETED";
  // else if (e?.lessonProgress && !e?.lessonProgress?.isCompleted)
  //   return "RESUME";

  // return "LOCK";

  if (!isPurchased) return "LOCK";
  else if (e?.lessonProgress && e?.lessonProgress?.isCompleted)
    return "COMPLETED";
  else if (e?.lessonProgress && !e?.lessonProgress?.isCompleted)
    return "RESUME";
  else return "START";
};

const getCountries = () => {
  return countries;
};

function formatDuration(seconds) {
  const totalSeconds = Math.floor(seconds); // round down to the nearest second

  if (totalSeconds < 60) {
    return `${totalSeconds} secs`;
  } else if (totalSeconds < 3600) {
    const mins = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    return `${mins} mins ${secs} secs`;
  } else {
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    return `${hrs} hrs ${mins} mins`;
  }
}

export {
  getCurrentInProgressLesson,
  enableCourseIconChecks,
  getCountries,
  formatDuration,
};
