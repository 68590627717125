import { Typography, Box, Divider, Grid } from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const PrivacyPolicy = ({ theme, mode, handleToggleChange }) => {
  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />

      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "24px", sm: "26px", md: "30px" },
                whiteSpace: "nowrap",
              }}
            >
              Privacy Policy
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 5,
              backgroundColor: theme.palette.background.main,
              maxWidth: "100%",
            }}
          >
            <Box sx={{ padding: 5 }}>
              <Typography sx={{ paddingBottom: 2 }}>
                Updated Date: April 20, 2024.
              </Typography>
              <Divider />
              <Box sx={{ mt: 5 }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: { xs: "18px", sm: "20px", md: "22px" },
                  }}
                >
                  Introduction
                </Typography>
                <Typography>
                  Welcome to Coursfy. At Coursfy, referred to as "we," "our," or
                  "us," we prioritize your privacy and are dedicated to
                  maintaining the confidentiality and protection of your
                  personal information. This Privacy Policy elaborates on our
                  methods of collecting, using, disclosing, and safeguarding
                  your data and outlines your rights concerning your personal
                  information.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 0.5 }}>
                  Scope and Acceptance
                </Typography>
                <Typography>
                  This Privacy Policy applies to your interactions with our
                  Services, including visiting our websites, using our mobile
                  applications, engaging with our APIs, or availing of our
                  related services. This policy extends to both existing and
                  potential users of our business and enterprise solutions.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 0.5 }}>
                  Consent and Usage Terms
                </Typography>
                <Typography>
                  By accessing and using our Services, you consent to the
                  practices described in this Privacy Policy. If you disagree
                  with any terms herein, please discontinue the use of our
                  Services.
                </Typography>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "21px",
                    marginBottom: 3,
                  }}
                >
                  1. Information Collection Practices
                </Typography>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold", mb: 0.5, fontSize: "19px" }}
                >
                  1.1 Direct Collection from You
                </Typography>
                <Typography>
                  We collect a variety of information based on your activities
                  and choices, including but not limited to:
                </Typography>
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Typography>
                    - Personal identification and contact details (e.g., name,
                    email address, password)
                  </Typography>
                  <Typography>
                    - Detailed profile information (e.g., professional
                    background, educational interests)
                  </Typography>
                  <Typography>
                    - User-generated content within our Services
                  </Typography>
                  <Typography>
                    - Data on educational engagement and progress
                  </Typography>
                  <Typography>
                    - Payment and billing information for transactions
                  </Typography>
                  <Typography>
                    - Specific financial information for instructors for payment
                    processing
                  </Typography>
                  <Typography>
                    - Data from linked third-party social media or online
                    accounts
                  </Typography>
                  <Typography>
                    - Information provided during participation in sweepstakes,
                    contests, and surveys
                  </Typography>
                  <Typography>
                    - Records of communication for customer support and service
                    improvement
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold", mb: 0.5, fontSize: "19px" }}
                >
                  1.2 Automatic Collection
                </Typography>
                <Typography>
                  During your interaction with our Services, we automatically
                  gather information such as:
                </Typography>
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Typography>
                    - Technical system data (e.g., device type, operating
                    system, IP address)
                  </Typography>
                  <Typography>
                    - Behavioral usage data (e.g., page visits, interaction with
                    content)
                  </Typography>
                  <Typography>
                    - Geolocation data based on your IP address
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold", mb: 0.5, fontSize: "19px" }}
                >
                  1.3 Information from Third Parties
                </Typography>
                <Typography>
                  We may obtain additional contact and business-related
                  information from external sources for our business user
                  segment.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  2. Data Collection Technologies
                </Typography>
                We use cookies, web beacons, and other similar technologies to
                facilitate data collection. These tools help us improve service
                functionality, remember user preferences, and provide a
                personalized experience.
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold", mb: 0.5, fontSize: "19px" }}
                >
                  2.1 Cookies and Tracking Technologies
                </Typography>
                <Typography>
                  Our use of cookies and tracking technologies aims to enhance
                  user experience and service efficiency. These technologies
                  collect information about your interactions with our Services,
                  aiding in service personalization and operational
                  improvements.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  3. Utilization of Collected Information
                </Typography>
                <Typography>
                  We employ the collected data to deliver and enhance our
                  Services, including:
                </Typography>
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Typography>
                    - Facilitating educational content delivery and user
                    interaction
                  </Typography>
                  <Typography>
                    - Processing financial transactions for services rendered
                  </Typography>
                  <Typography>
                    - Supporting marketing and promotional efforts
                  </Typography>
                  <Typography>
                    - Conducting data analytics and usage trend analysis
                  </Typography>
                  <Typography>
                    - Ensuring legal and regulatory compliance
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  4. Disclosure of Information
                </Typography>
                <Typography>
                  We share your information with various parties such as
                  educational instructors, peers, third-party service providers,
                  and legal authorities, where necessary, to:
                </Typography>
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Typography>
                    - Enhance the educational offerings and user experience
                  </Typography>
                  <Typography>
                    - Facilitate service provision and technical support
                  </Typography>
                  <Typography>
                    - Comply with legal obligations and safeguard our operations
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  5. Information Security
                </Typography>
                <Typography>
                  We implement robust security measures to protect your data
                  against unauthorized access, alteration, or destruction.
                  However, as no security system is infallible, we urge you to
                  secure your account credentials and notify us promptly of any
                  suspicious activity.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  6. Your Privacy Rights and Choices
                </Typography>
                <Typography>
                  You possess rights regarding the management of your personal
                  data, including the right to access, correct, or delete your
                  information, and to object to or limit its processing.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  7. Regional Privacy Considerations
                </Typography>
                <Typography>
                  Depending on your jurisdiction, you may have additional
                  privacy rights. For example, California residents have
                  specific rights under the CCPA, including the right to know
                  about and delete their personal data, and to opt-out of the
                  sale of their personal information.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  8. Policy Updates and Contact Information
                </Typography>
                <Typography>
                  We may revise this Privacy Policy periodically to reflect
                  changes in our practices or legal requirements. We will notify
                  you of significant updates, and your continued use of the
                  Services after such changes will constitute your acceptance of
                  the new terms.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  9. Data Retention
                </Typography>
                <Typography>
                  We retain your personal information for as long as necessary
                  to fulfill the purposes outlined in this Privacy Policy,
                  unless a longer retention period is required or permitted by
                  law. Our data retention criteria include the nature and
                  sensitivity of the information, the purposes for which we
                  process it, and the applicable legal requirements.
                </Typography>
              </Box>

              <Box sx={{ mt: 5 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  10. International Data Transfers
                </Typography>
                <Typography>
                  Your information may be transferred to, and processed in,
                  countries other than the country in which you are resident.
                  These countries may have data protection laws that are
                  different from those of your country. We ensure appropriate
                  safeguards are in place to protect your data when it is
                  transferred internationally.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  11. Third-Party Links and Services
                </Typography>
                <Typography>
                  Our Services may contain links to third-party websites or
                  services that are not owned or controlled by us. We are not
                  responsible for the privacy practices of these third parties.
                  We encourage you to review the privacy policies of any
                  third-party websites or services that you visit.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  12. User Responsibilities
                </Typography>
                <Typography>
                  You are responsible for maintaining the confidentiality of
                  your account credentials and for any activities conducted
                  through your account. We encourage you to use strong passwords
                  and to notify us immediately if you suspect any unauthorized
                  use of your account.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  13. Opt-Out Choices
                </Typography>
                <Typography>
                  You may opt out of receiving promotional communications from
                  us by following the instructions in those communications or by
                  adjusting your account settings. Even after you opt out, you
                  may still receive non-promotional messages from us, such as
                  service-related notices and legal updates.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  14. Automated Decision-Making
                </Typography>
                <Typography>
                  We may use automated decision-making processes to provide our
                  Services, including profiling to personalize content and
                  offers. You have the right to object to decisions based solely
                  on automated processing, including profiling, if such
                  decisions have a legal effect on you or similarly
                  significantly affect you.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  15. Social Media Features
                </Typography>
                <Typography>
                  Our Services may include social media features, such as the
                  Facebook Like button and widgets, such as the Share button or
                  interactive mini-programs that run on our Services. These
                  features may collect your IP address, which page you are
                  visiting on our Services, and may set a cookie to enable the
                  feature to function properly. Social media features and
                  widgets are either hosted by a third party or hosted directly
                  on our Services. Your interactions with these features are
                  governed by the privacy policy of the company providing them.
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  16. Data Protection Officer
                </Typography>
                <Typography>
                  We have appointed a Data Protection Officer (DPO) to oversee
                  compliance with this Privacy Policy. You can contact our DPO
                  directly regarding any privacy-related concerns.
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                    fontSize: "21px",
                  }}
                >
                  17. Children's Privacy
                </Typography>
                <Typography>
                  Our Services are not intended for children under the age of
                  13, and we do not knowingly collect personal information from
                  children under 13. If we become aware that we have
                  inadvertently collected personal information from a child
                  under 13, we will take steps to delete such information from
                  our records as soon as possible.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
