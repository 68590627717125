// src/components/CourseNotFound.jsx
import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotFound from "../images/404.png";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header";

const CourseNotFound = ({ theme, mode, handleToggleChange }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Grid
        mt={5}
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          sx={{
            maxWidth: "1200px",
            width: "100%",
            paddingX: 2,
            paddingY: 10,
            backgroundColor: theme.palette.background.secondary,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url("${NotFound}")`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "500px",
              height: "auto",
              minWidth: "250px",
              minHeight: "250px",
              mb: 4,
            }}
          ></Box>

          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 30,
              color: theme.palette.text.mainH1,
              mb: 2,
            }}
          >
            {t("Course Not Found")}
          </Typography>

          <Button
            variant="contained"
            onClick={() => navigate("/")}
            sx={{
              textTransform: "none",
              backgroundColor: "#006AE6",
              color: "white",
              "&:hover": {
                backgroundColor: "#006AE6",
                color: "white",
              },
            }}
          >
            {t("Go to Home")}
          </Button>
        </Grid>
      </Grid>
      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </React.Fragment>
  );
};

export default CourseNotFound;
