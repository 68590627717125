import ROUTES from "../apis/routes";
import STORAGE from "./local.storage";
import axios from "axios";

// const token =
//   "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjpudWxsLCJhdXRob3JpdGllcyI6WyJPUEVOX1VTRVIiXSwiaWF0IjoxNzA3NzEzMTIyLCJleHAiOjE3MTEzMTMxMjJ9.qw6gWRV56Y-vmMwR0fCxkMIMOpCm9QLCYdXz7UB3E-ahejhYh_g7c9uFXKrOSHU_AOpJg8OeH2RDpT7llKfSTpDC5-kFpMRiaYMxSyKzCo6W3j8VWqLn1GhLebe1VNW6ijBEbyZegVg9Rm9lQ4Yhxggo72malAUPQvIty8RzBphbTdJMOYtkvGxau0Fsfr-ZSIVkciPTSAidPLKlcGNia-sGkrxD2L3tlQl9dfuxxUPFZmcWr5i7i22xMFPy0QthRwhV9dLnvhgxBEwXgQF4O8zbRK4QDGZRljAeqzesUc6al-fPmw1IpHzo6OgXSyh5DdHqdKw7Jg1ZQSoTfoq_ynFMrLFyDSdcN4zpBG2AEkJFsjaf2QQBnx2mPiq0sCN3eoeQI-OeKzhKLoH-QcrAtZyMGmpZswjWC_Xgq43OMuQFq-YIpX8ARex0PC864z0HczURumNR4G2qTWAZLu0Dk0Wdh7JtDuB26YgEZFxE8y5QuT0GSWxt1hoLTsOGypRipp5NkyIel0pA9CXNuaeZdPFoPFP73XPVtzfDEalIQDUcXusIBUV7_xAxaJ4Nf48s2ngM194eaVm6pcq_rxiXCO2q-pouktgKWyCWFsPdD73w3aZh6vdzOrNXineQYEXvwGVy0ihsRuzgxmMUoNp6F06jSwJJoDiKDE4ooOghw9c";

let token = STORAGE.getToken();

const get = async ({ url, id = null, auth = true, snackbar, localtoken }) => {
  try {
    token = STORAGE.getToken();
    let headers = {};
    if (auth) {
      // const token = STORAGE.getToken();
      headers["Authorization"] = `Bearer ${localtoken ? localtoken : token}`;
    }

    let newurl = url;
    if (id) newurl = newurl + "/" + id;

    let promise = await fetch(`${ROUTES.BASE_URL}/${newurl}`, {
      method: "GET",
      headers,
    });
    if (promise.status === 200) {
      let res = await promise.json();
      // snackbar({
      //   message: res?.message,
      //   severity: "success",
      // });
      return res;
    } else return null;
  } catch (error) {
    console.log("GET ", error);
    return null;
  }
};

const post = async ({
  url,
  body,
  isFormData = false,
  auth = true,
  snackbar,
  localtoken,
}) => {
  try {
    let promise = {};
    // const token = STORAGE.getToken();
    token = STORAGE.getToken();

    let headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    if (auth) {
      headers["Authorization"] = `Bearer ${localtoken ? localtoken : token}`;
    }

    if (!isFormData) {
      promise = await fetch(`${ROUTES.BASE_URL}/${url}`, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

      // promise = await axios.post(
      //   `${ROUTES.BASE_URL}/${url}`,
      //   JSON.stringify(body),
      //   {
      //     headers,
      //   }
      // );
    }

    if (isFormData) {
      headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localtoken ? localtoken : token}`,
      };

      promise = await axios.post(`${ROUTES.BASE_URL}/${url}`, body, {
        headers,
      });
    }
    if (isFormData) return promise;
    const res = await promise.json();

    if (promise.status === 200) {
      if (snackbar)
        snackbar({
          message: res?.message,
          severity: "success",
        });
      return res;
    } else {
      if (snackbar)
        snackbar({
          message: res?.message,
          severity: "error",
        });
      return null;
    }
  } catch (error) {
    console.log("POST ", error);
    return null;
  }
};

const put = async ({
  url,
  isFormData = false,
  body = {},
  auth = true,
  snackbar,
}) => {
  try {
    let promise = {};
    token = STORAGE.getToken();

    let headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    if (auth) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (!isFormData)
      promise = await fetch(`${ROUTES.BASE_URL}/${url}`, {
        method: "PATCH",
        headers,
        body: JSON.stringify(body),
      });

    if (isFormData) {
      headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };

      promise = await axios.patch(`${ROUTES.BASE_URL}/${url}`, body, {
        headers,
      });
    }

    if (isFormData) return promise;
    const res = await promise.json();

    if (promise.status === 200) {
      if (snackbar)
        snackbar({
          message: res?.message,
          severity: "success",
        });
      let res = null;
      return res;
    } else {
      if (snackbar)
        snackbar({
          message: res?.message,
          severity: "error",
        });
      return null;
    }
  } catch (error) {
    console.log("GET ", error);
    return null;
  }
};

const patch = async ({ url, id = null, body = {}, auth = true, snackbar }) => {
  try {
    console.log("BODY ", body);
    let headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    if (auth) {
      const token = STORAGE.getToken();
      headers["Authorization"] = `Bearer ${token}`;
    }

    let promise = await fetch(
      id ? `${ROUTES.BASE_URL}/${url}/${id}` : `${ROUTES.BASE_URL}/${url}`,
      {
        method: "PATCH",
        headers,
        body: JSON.stringify(body),
      }
    );
    if (promise.status === 200) {
      let res = await promise.json();
      return res;
    } else return null;
  } catch (error) {
    console.log("GET ", error);
    return null;
  }
};

const remove = async ({ url, auth = true, snackbar }) => {
  try {
    let headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    };
    if (auth) {
      const token = STORAGE.getToken();
      headers["Authorization"] = `Bearer ${token}`;
    }

    let promise = await fetch(`${ROUTES.BASE_URL}/${url}`, {
      method: "DELETE",
      headers,
    });
    if (promise.status === 200) {
      let res = await promise.json();
      return res;
    } else return null;
  } catch (error) {
    console.log("GET ", error);
    return null;
  }
};

const INTERCEPTOR = { get, post, put, patch, remove };
export default INTERCEPTOR;
