import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import EastIcon from "@mui/icons-material/East";

const CoursyOffer = ({ theme, mode, handleToggleChange, t }) => {
  return (
    <Grid container justifyContent="center" sx={{ paddingBottom: 10 }}>
      <Grid
        item
        maxWidth="1200px"
        width="100%"
        mt={10}
        // mb={10}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ paddingX: 2 }}
      >
        <Box
          mb={7}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: 20, fontWeight: "bold" }}
          >
            {t("about_us.offer")}
          </Typography>
        </Box>
        <Grid
          item
          maxWidth={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          gap={5}
        >
          {/* ------- first row -------- */}
          <Box
            height="auto"
            minHeight="450px"
            sx={{
              bgcolor: theme.palette.background.main,
              flexDirection: { xs: "column", md: "row" },
            }}
            display={"flex"}
            paddingX={4}
          >
            <Box
              flex={1}
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              sx={{
                alignItems: { xs: "center", md: "start" },
              }}
            >
              <Typography
                mt={3}
                mb={2}
                fontWeight="bold"
                color="#006AE6"
                fontSize={16}
              >
                {t("about_us.for_instructor")}
              </Typography>
              <Typography fontWeight="bold" fontSize={18}>
                {t("about_us.for_instructor_heading")}
              </Typography>
            </Box>

            <Box
              flex={2}
              padding={5}
              display={"flex"}
              flexDirection="column"
              sx={{
                alignItems: { xs: "center", md: "start" },
                justifyContent: { xs: "center", md: "space-between" },
              }}
            >
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.creative_freedom_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.creative_freedom_description")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.flexible_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.flexible_description")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.monetization_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.monetization_description")}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.analytics_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.analytics_description")}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* ------- second row -------- */}
          <Box
            height="auto"
            minHeight="450px"
            sx={{
              bgcolor: theme.palette.background.main,
              flexDirection: { xs: "column", md: "row" },
            }}
            display={"flex"}
            paddingX={4}
          >
            <Box
              flex={1}
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              sx={{
                alignItems: { xs: "center", md: "start" },
              }}
            >
              <Typography
                mt={3}
                mb={2}
                fontWeight="bold"
                color="#006AE6"
                fontSize={16}
              >
                {t("about_us.for_learner")}
              </Typography>
              <Typography fontWeight="bold" fontSize={18}>
                {t("about_us.for_learner_heading")}
              </Typography>
            </Box>

            <Box
              flex={2}
              padding={5}
              display={"flex"}
              flexDirection="column"
              sx={{
                alignItems: { xs: "center", md: "start" },
                justifyContent: { xs: "center", md: "space-between" },
              }}
            >
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.paths_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.paths_description")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.interactive_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.interactive_description")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.com_network_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.com_network_description")}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.mobile_ac_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.mobile_ac_description")}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* ------- third row -------- */}
          <Box
            height="auto"
            minHeight="450px"
            sx={{
              bgcolor: theme.palette.background.main,
              flexDirection: { xs: "column", md: "row" },
            }}
            display={"flex"}
            paddingX={4}
          >
            <Box
              flex={1}
              display={"flex"}
              flexDirection="column"
              justifyContent={"center"}
              sx={{
                alignItems: { xs: "center", md: "start" },
              }}
            >
              <Typography
                mt={3}
                mb={2}
                fontWeight="bold"
                color="#006AE6"
                fontSize={16}
              >
                {t("about_us.for_companies")}
              </Typography>
              <Typography fontWeight="bold" fontSize={18}>
                {t("about_us.for_companies_heading")}
              </Typography>
            </Box>

            <Box
              flex={2}
              padding={5}
              display={"flex"}
              flexDirection="column"
              sx={{
                alignItems: { xs: "center", md: "start" },
                justifyContent: { xs: "center", md: "space-between" },
              }}
            >
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.programs_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.programs_description")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.content_creation_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.content_creation_description")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.employ_on_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.employ_on_description")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.pro_dev_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.pro_dev_description")}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold" color="#006AE6" fontSize={16}>
                  {t("about_us.reporting_title")}
                </Typography>
                <Typography fontSize={14}>
                  {t("about_us.reporting_description")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoursyOffer;
