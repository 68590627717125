import React from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import BackgroundImage from "../backgroundImage.svg";
import Logo from "../logoImage/BlueLogo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EmailVerified = ({ theme, mode, handleToggleChange }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          background: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundColor: "theme.palette.background.default",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mb={3}>
            <img src={Logo} alt="" style={{ width: "160px" }} />
          </Box>

          <Card
            variant="outlined"
            elevation={3}
            sx={{
              display: "flex",
              borderRadius: "6px",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "520px",
              padding: "30px 0",
              border: "1px solid #E2F0FA",
            }}
          >
            <CardContent>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.background.mainH1,
                    fontSize: "27px",
                  }}
                  variant="h5"
                >
                  {t("email_verification.title")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: theme.palette.text.body,
                    mt: 2,
                  }}
                  variant="subtitle1"
                >
                  {t("email_verification.subtitle1")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: theme.palette.text.body,
                    mt: 1,
                  }}
                  variant="subtitle1"
                >
                  {t("email_verification.subtitle2")}
                </Typography>
                <Button
                  sx={{
                    width: "240px",
                    height: "38px",
                    mt: 4,
                    textTransform: "none",
                    backgroundColor: theme.palette.background.primary,
                    "&:hover": {
                      backgroundColor: theme.palette.background.primary,
                    },
                  }}
                  fontWeight="regular"
                  disableElevation
                  variant="contained"
                  size="large"
                  onClick={handleLoginRedirect}
                >
                  {t("email_verification.login_button")}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default EmailVerified;
