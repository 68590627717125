const ROUTES = {
  BASE_URL:
    process.env.REACT_APP_NODE_ENV === "production"
      ? process.env.REACT_APP_BACKEND_DEV
      : "http://api.dev.iappstest.com:8080/v1", //DEV API URL

  ORG_ADMIN: "auth",
  TAGS: "tag",
  BRANCHES: "branch",
  GROUP: "group",
  USER: "user",
  CATEGORY: "category",
  COURSE: "course",
  LESSON: "lesson",
  SLIDE: "slide-template",
  AUTH: "auth",
  LOOKUP: "lookup",
  STRIPE: "stripe",
  USER: "user",
  
};

export default ROUTES;
