import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  IconButton,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Card,
  CardMedia,
  CardContent,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Pagination,
  Slider,
  Rating,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SearchHeader from "../components/SearchHeader";
import Header from "../components/Header";
import { COURSE_API, LOOKUP_API } from "../apis";
import CourseCard from "../components/CourseCard";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { throttle } from "lodash";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const marks = [
//   { value: 0, label: "€0" },
//   { value: 50, label: "€50" },
//   { value: 100, label: "€100" },
//   { value: 150, label: "€150" },
//   { value: 200, label: "€200" },
//   { value: 250, label: "€250" },
//   { value: 300, label: "€300" },
//   { value: 350, label: "€350" },
//   { value: 400, label: "€400" },
//   { value: 450, label: "€450" },
//   { value: 500, label: "€500" },
// ];

const priceRanges = [
  { label: "€0 - €50", min: 0, max: 50 },
  { label: "€50 - €100", min: 50, max: 100 },
  { label: "€100 - €150", min: 100, max: 150 },
  { label: "€150 - €200", min: 150, max: 200 },
  { label: "€200 - €250", min: 200, max: 250 },
  { label: "€250 - €300", min: 250, max: 300 },
  { label: "€300 - €350", min: 300, max: 350 },
  { label: "€350 - €400", min: 350, max: 400 },
  { label: "€400 - €450", min: 400, max: 450 },
  { label: "€450 - €500", min: 450, max: 500 },
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function valuetext(value) {
  return `${value}°C`;
}

const Search = ({ themeSearch, mode, handleToggleChange }) => {
  const perPage = 8;
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [rating, setRating] = useState(0);
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(10000);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const { state } = useLocation();
  const lookupState = useSelector((state) => state.lookup);

  const resetFilter = () => {
    setRating(0);
    setPriceMax(10000);
    setSearchText("");
    setCategory("");
  };

  const flattenCategories = (categories) => {
    const result = [];
    const flatten = (items) => {
      items.forEach((item) => {
        result.push({
          id: item._id,
          name: item.name,
          parentId: item.parentCategory,
        });
        if (item.subCategories && item.subCategories.length > 0) {
          flatten(item.subCategories);
        }
      });
    };
    flatten(categories);
    return result;
  };

  const getCategory = async () => {
    try {
      const res = await LOOKUP_API.getLookup();
      if (res) {
        const cat = flattenCategories(res?.categories);
        if (cat) setCategories(cat);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const getCourses = async (search = "") => {
    try {
      const res = await COURSE_API.getCoursesPaginated({
        searchText: search ? search : state?.search,
        rating,
        priceMin,
        priceMax,
        perPage,
        page,
        category,
      });
      if (res) {
        setCourses(res?.courses);
        setPages(Math.ceil(res?.pagination?.totalItems / perPage));
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(value);
  };

  useEffect(() => {
    setSearchText(state?.search);
    setCategory(state?.category_id);
    getCategory();
  }, []);

  useEffect(() => {
    setCategory(state?.category_id);
  }, [state?.category_id]);

  const handleSearchThrottled = useCallback(
    throttle((query) => {
      getCourses(query);
    }, 1000),
    []
  );

  useEffect(() => {
    getCourses();
  }, [rating, priceMax, priceMin, page, category]);

  const handlePriceChange = (event) => {
    const selectedRange = priceRanges.find(
      (range) => range.label === event.target.value
    );
    setPriceMin(selectedRange.min);
    setPriceMax(selectedRange.max);
  };

  const selectedRangeLabel =
    priceRanges.find(
      (range) => range.min === priceMin && range.max === priceMax
    )?.label || "";

  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "25px", sm: "30px" },
                whiteSpace: "nowrap",
              }}
            >
              Search Course
            </Typography>
            <TextField
              sx={{
                marginTop: { xs: 2.5, sm: 0, md: 0 },
                backgroundColor: themeSearch.palette.background.secondary,
                width: "400px",
                minWidth: "300px",
                height: "55px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { border: "none" },
                  "&:hover fieldset": { border: "none" },
                  "&.Mui-focused fieldset": { border: "none" },
                },
                "& .MuiInputBase-root": {
                  "&:before": { borderBottom: "none" },
                  "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
                  "&:after": { borderBottom: "none" },
                },
              }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                handleSearchThrottled(e.target.value);
              }}
              placeholder="Search..."
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              mt: 4,
              height: "auto",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              padding: 1,
              justifyContent: "space-between",
              gap: 1.5,
            }}
          >
            <Box>
              <FormControl
                size="small"
                sx={{
                  minWidth: "240px",
                  height: "40px",
                  borderRadius: "4px",
                  border: "1px solid #E2F0FA",
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiSelect-outlined": { boxShadow: "none" },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                <Select
                  displayEmpty
                  value={category}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <span>Categories</span>;
                    }

                    return (
                      categories.find((cat) => cat.id === selected)?.name || ""
                    );
                  }}
                >
                  <MenuItem disabled value="">
                    <span>Categories</span>
                  </MenuItem>
                  {categories.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.parentId ? (
                        el.name
                      ) : (
                        <Typography variant="body2">{el.name}</Typography>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                width: "620px",
                display: "flex",

                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {/* <Box sx={{ flex: "0 1 auto", mr: 3, mt: { xs: 1, sm: 0 } }}>
                <Typography sx={{ whiteSpace: "nowrap", mb: 1 }}>
                  Price Range:
                </Typography>
              </Box> */}
              {/* <Box
                sx={{
                  paddingX: { xs: 1, md: 0 },
                  flex: "0 1 auto",
                  width: "100%",
                  maxWidth: 500, // Set the max width to 600px
                  minWidth: 350, // Set a minimum width for responsive wrapping
                  boxSizing: "border-box", // Ensure padding and border are included in the width
                }}
              >
                <Slider
                  sx={{
                    width: "100%",
                    color:
                      lookupState?.branding?.colors?.primary ||
                      theme.palette.primary.main,
                  }}
                  aria-label="Always visible"
                  value={priceMax}
                  getAriaValueText={valuetext}
                  onChange={(e) => setPriceMax(e.target.value)}
                  step={10}
                  min={0}
                  max={500}
                  marks={marks}
                />
              </Box> */}
              <Box sx={{ mt: { xs: 1, sm: 0 } }}>
                <FormControl
                  size="small"
                  sx={{
                    minWidth: 240,
                    height: "40px",
                    borderRadius: "4px",
                    border: "1px solid #E2F0FA",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiSelect-outlined": { boxShadow: "none" },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                >
                  <Select
                    displayEmpty
                    value={selectedRangeLabel}
                    onChange={handlePriceChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span>Price Range</span>;
                      }

                      return selected;
                    }}
                  >
                    <MenuItem disabled value="">
                      <span>Price Range</span>
                    </MenuItem>
                    {priceRanges.map((range) => (
                      <MenuItem key={range.label} value={range.label}>
                        {range.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box sx={{ mt: { xs: 1, sm: 0 } }}>
              <Stack spacing={1} direction={"row"} alignItems={"center"}>
                <Typography sx={{ whiteSpace: "nowrap", mr: 2 }}>
                  Rating:
                </Typography>
                <Rating
                  name="size-large"
                  onChange={(e) => setRating(e.target.value)}
                  value={rating}
                  size="large"
                />
              </Stack>
            </Box>

            <Box>
              <Button
                sx={{
                  width: "140px",
                  height: "38px",
                  color: "white",
                  borderRadius: "2px",
                  border: "none",
                  boxShadow: "none",
                  textTransform: "none",
                }}
                variant="contained"
                onClick={() => resetFilter()}
              >
                Reset Filters
              </Button>
            </Box>
          </Box>

          <Grid
            maxWidth={"1200px"}
            container
            rowSpacing={4}
            columnSpacing={2}
            mt={1.5}
          >
            {courses.map((el) => (
              <Grid
                item
                key={el._id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CourseCard
                  theme={theme}
                  course={el}
                  onNav={() =>
                    navigate(`/course/${el?._id}`, {
                      state: { id: el?._id },
                    })
                  }
                />
              </Grid>
            ))}
          </Grid>

          <Pagination
            size="large"
            onChange={(e, value) => setPage(value)}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              mr: 10,
              mt: 2,
              padding: 1,
            }}
            count={pages}
          />
        </Grid>
      </Grid>
      <Footer theme={theme} />
    </React.Fragment>
  );
};

export default Search;
