import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Link,
  Button,
  Typography,
  IconButton,
  Divider,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import STORAGE from "../utils/local.storage";
import { LOOKUP_API } from "../apis";
import LanguageIcon from "@mui/icons-material/Language";
import klarna from "../assets/klarnaIcon.svg";
import masterCard from "../assets/mastercardIcon.svg";
import visa from "../assets/visaIcon.svg";
import tiktok from "../assets/tiktokIcon.svg";
import facebookIcon from "../assets/facebookIcon.svg";
import linkedInIcon from "../assets/linkedinIcon.svg";
import instagramIcon from "../assets/instagramIcon.svg";
import logo from "../assets/coursfyLogo.svg";
import googlePlay from "../assets/googlePlay.svg";
import appStore from "../assets/appStore.svg";
import SearchIcon from "@mui/icons-material/Search";

const Footer = ({ theme }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const activeLocale = i18n.resolvedLanguage;
  const [lang, setLang] = useState("en");
  const [searchText, setSearchText] = useState("");
  const [categories, setCategories] = useState([]);

  const getCategory = async () => {
    try {
      const res = await LOOKUP_API.getLookup();
      if (res) {
        setCategories(res?.categories);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleChange = (event) => {
    setLang(event.target.value);
  };
  return (
    <Grid
      container
      component="footer"
      width={"100vw"}
      height="100%"
      // minHeight="851px"
      sx={{
        paddingTop: { xs: "50px", md: "100px" },
        paddingBottom: { xs: "50px", md: "100px" },
        backgroundColor: theme.palette.background.footer,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        width={"100%"}
        maxWidth={"1200px"}
        sx={{ paddingX: { xs: 2, sm: 2 } }}
      >
        <Box
          mb={5}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
          }}
        >
          <Grid container>
            {/* First Column */}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: 4, sm: 4, md: 0 },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.footerText,
                  fontSize: 20,
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Company
              </Typography>

              <Link
                href="/about-us"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                {t("footer.aboutus")}
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                {t("footer.blog")}
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                {t("footer.explore")}
              </Link>
              <Link
                href="/faqs"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                {t("footer.faqs")}
              </Link>
              <Link
                href="/contact-us"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                {t("footer.contactus")}
              </Link>
            </Grid>

            {/* Second Column */}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: 4, sm: 4, md: 0 },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.footerText,
                  fontSize: 20,
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Support
              </Typography>
              {/* <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Help Center
              </Link> */}
              <Link
                href="/verification-course"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Course Verification
              </Link>
            </Grid>

            {/* Third Column */}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: 4, sm: 4, md: 0 },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.footerText,
                  fontSize: 20,
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Opportunity
              </Typography>
              <Link
                href="https://admin.coursfy.com/"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Become an Instructor
              </Link>
            </Grid>

            {/* Fourth Column */}
            {/* <Grid
              item
              xs={12}
              sm={6}
              md={3.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: 4, sm: 4, md: 0 },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.footerText,
                  fontSize: 20,
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Category
              </Typography>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Business
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Finance & Accounting
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Teaching & Academics
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                IT & Software
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Office & Productivity
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Design
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Development
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Marketing
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Lifestyle
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Photography & Video
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Heath & Fitness
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Personal Development
              </Link>
              <Link
                href="#"
                sx={{
                  fontSize: 16,
                  color: theme.palette.text.footerText,
                  textDecoration: "none",
                  mb: 2,
                }}
              >
                Music
              </Link>
            </Grid> */}

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                paddingLeft: { xs: 0, sm: 1, md: 2 },
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: 4, sm: 4, md: 0 },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.footerText,
                  fontSize: 20,
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Category
              </Typography>

              {categories.map((category) => (
                <Link
                  key={category.id}
                  onClick={() =>
                    navigate("/search", {
                      state: { category_id: category._id },
                    })
                  } // Navigate with state
                  sx={{
                    fontSize: 16,
                    color: theme.palette.text.footerText,
                    textDecoration: "none",
                    cursor: "pointer",
                    mb: 2,
                  }}
                >
                  {category.name}
                </Link>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "start", sm: "start", md: "end" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: { xs: "start", sm: "start", md: "center" },
              }}
            >
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "none",
                  outline: "none",
                  "& .MuiOutlinedInput-root": {
                    width: "115px",

                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                  },
                }}
              >
                <LanguageIcon sx={{ color: theme.palette.text.footerText }} />
                <Select
                  sx={{
                    color: theme.palette.text.footerText,
                    fontSize: "16px",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: "140px",
                        paddingX: 2,
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeLocale}
                  label="Language"
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem
                    sx={{
                      color: theme.palette.text.body,
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    value={"en"}
                    onClick={() => {
                      setLang("en");
                      i18n.changeLanguage("en");
                    }}
                  >
                    English
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: theme.palette.text.body,
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    value={"se"}
                    onClick={() => {
                      setLang("se");
                      i18n.changeLanguage("se");
                    }}
                  >
                    Swedish
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "start", sm: "start", md: "center" },
                marginTop: { xs: 2, sm: 2, md: 5 },
              }}
            >
              <img style={{ width: "240px" }} src={logo} alt="logo" />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", sm: "start", md: "center" },
                  gap: "5px",
                  width: "100%",
                  mt: "25px",
                }}
              >
                <IconButton
                  href="https://www.facebook.com/profile.php?id=61561317475673"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <FacebookIcon sx={{ color: theme.palette.text.icon }} /> */}
                  <img
                    style={{ width: "32px" }}
                    src={facebookIcon}
                    alt="Facebook"
                  />
                </IconButton>

                <IconButton
                  href="https://www.linkedin.com/company/coursfy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <LinkedInIcon sx={{ color: theme.palette.text.icon }} /> */}
                  <img src={linkedInIcon} alt="LinkedIn" />
                </IconButton>
                <IconButton
                  href="https://www.instagram.com/coursfysweden"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <InstagramIcon sx={{ color: theme.palette.text.icon }} /> */}
                  <img src={instagramIcon} alt="Instagram" />
                </IconButton>
                <IconButton
                  href="https://www.tiktok.com/@coursfy?_t=8o5qFlFX7kO&_r=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={tiktok} alt="Tiktok" />
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: "70px",
                  // paddingRight: 0.2,
                }}
              >
                <Link
                  mb={"10px"}
                  href="https://play.google.com/store/apps/details?id=com.coursfy.app"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  <img
                    style={{
                      width: "180px",
                    }}
                    src={googlePlay}
                    alt="google Play"
                  />
                </Link>
                <Link
                  href="https://apps.apple.com/us/app/coursfy/id6499483299"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  <img
                    style={{
                      width: "180px",
                    }}
                    src={appStore}
                    alt="App Store"
                  />
                  {/* <Button
                  sx={{
                    mt: 2,
                    backgroundColor: "#006AE6",
                    color: "white",
                    width: "155px",
                    height: "45px",
                    "&:hover": {
                      backgroundColor: "#006AE6",
                      color: "white",
                    },
                  }}
                  variant="contained"
                >
                  <AppleStoreIcon
                    sx={{ fontSize: "30px", marginLeft: "-15px" }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontSize: "9px", textAlign: "left" }}>
                      Download on the
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      App Store
                    </Typography>
                  </Box>
                </Button> */}
                </Link>
              </Box>
            </Box>
          </Grid>
        </Box>

        <Divider sx={{ bgcolor: theme.palette.background.footerDivider }} />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            padding: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                <Link
                  href="/privacy-policy"
                  sx={{
                    color: theme.palette.text.footerText,
                    textDecoration: "none",
                  }}
                >
                  {t("footer.privacy_policy")}
                </Link>
                <Link
                  href="/terms-conditions"
                  sx={{
                    color: theme.palette.text.footerText,
                    textDecoration: "none",
                  }}
                >
                  {t("footer.term_conditions")}
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", sm: "flex-end" },
                  gap: "32px",
                }}
              >
                <img src={visa} alt="Visa" width="63" />
                <img src={masterCard} alt="MasterCard" width="63" />
                <img src={klarna} alt="Klarna" width="63" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default Footer;
