import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Box,
  Grid,
  Typography,
  CardContent,
  Avatar,
  Divider,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Rating,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LockIcon from "@mui/icons-material/Lock";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import CongratulationImg from "../images/congratulations.png";
import LessonQuiz from "./lessons/Quiz";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { COURSE_API } from "../apis";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Document, Page, pdfjs } from "react-pdf";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CancelIcon from "@mui/icons-material/Cancel";
import "react-pdf/dist/esm/Page/TextLayer.css";
import Footer from "../components/Footer";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
// import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import {
  getCurrentInProgressLesson,
  enableCourseIconChecks,
  enableCourseChecks,
  formatDuration,
} from "../utils/common";
import STORAGE from "../utils/local.storage";

import CategoryIcon from "@mui/icons-material/Category";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import ReportIcon from "@mui/icons-material/Report";
import DevicesIcon from "@mui/icons-material/Devices";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import COLORS from "../utils/colors";
import SlideshowRoundedIcon from "@mui/icons-material/SlideshowRounded";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";

const LessonOverview = ({ theme, mode, handleToggleChange }) => {
  const [progress, setProgress] = React.useState(60);
  const [openRate, setOpenRate] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [sections, setSections] = useState([]);
  const [ratingValue, setRatingValue] = React.useState(0);
  const [reportReason, setReportReason] = useState("");
  const [reportResonErr, setReportReasonErr]= useState("");
  const [reportText, setReportText] = useState("");
  const [reportTextErr, setReportTextErr] = useState("");
  let globalLessonIndex = -1;
  const [searchParams] = useSearchParams();
  const id = searchParams.get("courseId");
  const navigate = useNavigate();
  const { courseid } = useParams();
  // Course Lesson Attributes
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState(null);
  const [orgRating, setOrgRating] = useState(0);

  // PDF Attributes
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pdfError, setPdfError] = useState(false);

  // Slide Attributes
  const [slides, setSlides] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // Quiz Attributes
  const [quizzes, setQuizzes] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState({});
  const [answers, setAnswers] = useState([]);

  // Is Completed Checks
  const [isPreviousCompleted, setIsPreviousCompleted] = useState(true);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  // Constants
  const videoRef = useRef(null);
  const location = useLocation();
  const { state } = location;
  const { t } = useTranslation();
  const lookupState = useSelector((state) => state.lookup);

  const settings = {
    width: 200,
    height: 200,
    value: 60,
  };

  const storage = STORAGE.getStorage();

  const bookmarkCourse = async () => {
    try {
      const res = await COURSE_API.bookmarkCourse(state?.course_id);
      courseDetail();
      if (res) {
        console.log("RES ", res);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const courseSection = async (token = null) => {
    try {
      const res = await COURSE_API.getCourseSections(state?.course_id, token);
      if (res && res?.sections) {
        setSections(res?.sections);
        // const tempArr = [];
        // res?.sections?.map((e) => {
        //   if (e?.lessons) {
        //     tempArr.push(...e?.lessons);
        //   }
        // });
        // setLessons(tempArr);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleToogleRate = () => {
    setOpenRate(!openRate);
  };

  const handleToogleReport = () => {
    setReportReason("");
    setReportText("");
    setReportReasonErr("");
    setReportTextErr("");
    setOpenReport(!openReport);
  };

  const handleBookmarkClick = () => {
    setBookmarked(!bookmarked);
    bookmarkCourse();
  };

  const details = [
    {
      heading: `${t("course_detail.review")}`,
      icon: <StarIcon />,
      description: `${course?.rating?.toFixed(1)} Rating`,
    },
    // { heading: "Series", icon: <MovieIcon />, description: "10 Episodes" },
    {
      heading: `${t("course_detail.length")}`,
      icon: <AccessTimeIcon />,
      description: formatDuration(course?.duration),
    },
    // { heading: "Language", icon: <LanguageIcon />, description: "English" },
    // { heading: "Format", icon: <FormatShapesIcon />, description: "HD" },
    {
      heading: `${t("course_detail.category")}`,
      icon: <CategoryIcon />,
      description: `${course?.category?.name}`,
    },
  ];

  const courseDetail = async () => {
    try {
      const res = await COURSE_API.getCoursesDetailLesson(state?.course_id);
      if (res) {
        setCourse(res?.course);
        setRatingValue(res?.course?.rating);
        if (res?.course?.isCompleted && !isPreviousCompleted) {
          setShowCompleteModal(true);
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.log("Error in courseDetail", error);
    }
  };

  // VIDEO CARD
  const LessonRow = ({ e, index }) => {
    const [selectedLesson, setSelectedLesson] = useState(null);
    const iconType = enableCourseIconChecks(course?.isPurchased, e, index);

    // Function to render the type icon
    const renderTypeIcon = () => {
      switch (e?.type) {
        case "VIDEO":
          return <SlideshowRoundedIcon sx={{ fontSize: "28px" }} />;
        case "PDF":
          return <PictureAsPdfOutlinedIcon sx={{ fontSize: "28px" }} />;
        case "LESSON":
          return (
            <DriveFileRenameOutlineOutlinedIcon sx={{ fontSize: "28px" }} />
          );
        case "MCQ":
          return <AccessAlarmOutlinedIcon sx={{ fontSize: "28px" }} />;
        default:
          return null;
      }
    };

    const handleClick = () => {
      setSelectedLesson(e);
      setLesson(e);
    };

    return (
      <Box
        my={"10px"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "82px",
          padding: "0px 2px",
          backgroundColor: selectedLesson === e ? "#95A2B44D" : "transparent", // Change background color when selected
          borderLeft:
            selectedLesson === e ? "6px solid #95A2B44D" : "transparent", // Change background color when selected
          cursor: "pointer", // Add a pointer cursor for better UX
          "&:hover": {
            backgroundColor: "#95A2B44D",
            borderLeft: "6px solid #95A2B44D",
            cursor: "pointer", // Add a pointer cursor for better UX
          },
        }}
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center" flex={2}>
          {/* <Box>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              1.
            </Typography>
          </Box> */}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Directly render the type icon here */}
            {renderTypeIcon()}

            <Typography
              ml={1}
              mr={1}
              sx={{
                fontWeight: "bold",
                fontSize: 16,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 3,
                textOverflow: "ellipsis",
              }}
            >
              {e?.name}
            </Typography>
          </Box>
        </Box>

        <Box>
          {iconType === "LOCK" && (
            <LockIcon
              sx={{
                color:
                  lookupState?.branding?.colors?.primary ||
                  theme.palette.background.primary,
                ml: 1,
              }}
            />
          )}
          {iconType === "COMPLETED" && (
            <CheckCircleIcon
              sx={{
                fontSize: "32px",
                color:
                  lookupState?.branding?.colors?.primary ||
                  theme.palette.background.primary,
                ml: 1,
              }}
            />
          )}
          {iconType === "RESUME" && (
            <CircleOutlinedIcon
              sx={{
                fontSize: "32px",
                color: theme.palette.text.default,
                // lookupState?.branding?.colors?.primary ||
                // theme.palette.background.primary,
                ml: 1,
              }}
            />
          )}
          {iconType === "START" && (
            <CircleOutlinedIcon
              sx={{
                fontSize: "32px",
                color: theme.palette.text.default,
                // lookupState?.branding?.colors?.primary ||
                // theme.palette.background.primary,
                ml: 1,
              }}
            />
          )}
        </Box>
      </Box>
    );
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size={props.size || 40}
          variant="determinate"
          {...props}
          sx={{ color: "green" }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            sx={{ mt: 1 }}
            variant="caption"
            component="div"
            fontWeight="bold"
            color="textSecondary"
          >
            Grade
          </Typography>
          <Typography
            sx={{ fontSize: 20, fontWeight: "bold" }}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            {`${Math.round(props.value)}%`}
          </Typography>
          <Typography
            sx={{ mt: 1 }}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            2 of 2 correct
          </Typography>
        </Box>
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          borderRadius="50%"
          border={`18px solid rgba(0, 0, 0, 0.1)`}
          boxSizing="border-box"
          width={200}
          height={200}
        />
      </Box>
    );
  }

  const courseLessons = async () => {
    try {
      const res = await COURSE_API.getCourseLessons(state?.course_id);
      if (res && res?.lessons) {
        setLessons(res?.lessons);
        // This 'state?.lesson_id' part needed to be made dynamic
        const lessonIdtoCheck = !lesson ? state?.lesson_id : lesson?._id;
        console.log("Lesson ID to Check ", lessonIdtoCheck);
        const tempLesson = res?.lessons?.find(
          (obj) => obj._id === lessonIdtoCheck
        );
        if (tempLesson) setLesson(tempLesson);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const updateCourseProgress = async (body) => {
    try {
      const res = await COURSE_API.lessonProgressUpdate(body);
      if (res) {
        courseLessons();
        courseDetail();
        courseSection();
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const getQuiz = async (id) => {
    try {
      const res = await COURSE_API.getLessonQuiz(id);
      if (res) setQuizzes(res?.questions);
    } catch (error) {
      console.log("Err ", error);
    }
  };

  const getSlides = async (id) => {
    try {
      const res = await COURSE_API.getLessonSlides(id);
      if (res) {
        setSlides(res?.slides);
        if (lesson?.lessonProgress) {
          let slideIndex = 0;
          res?.slides.forEach((el, index) => {
            if (el?._id === lesson?.lessonProgress?.latestSlide)
              slideIndex = index;
          });
          setCurrentSlideIndex(slideIndex);
        } else {
          updateCourseProgress({
            lesson: lesson?._id,
            latestSlide: res?.slides[0]._id,
          });
        }
      }
    } catch (error) {
      console.log("Err ", error);
    }
  };

  const setAnswer = (localQuiz, currentIndex) => {
    const exists = answers.some((obj) => obj?.question === localQuiz?._id);
    if (!exists) {
      const tempArr = answers;
      tempArr.push({
        question: localQuiz?._id,
        option: localQuiz?.options[currentIndex]?._id,
      });
      setAnswers(tempArr);
    }

    if (currentIndex + 1 !== quizzes.length)
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    else {
      updateCourseProgress({
        lesson: lesson?._id,
        answers,
      });
    }
  };

  const rateCourse = async () => {
    try {
      const res = await COURSE_API.rateCourse(state?.course_id, {
        rating: ratingValue,
      });
      if (res) {
        // courseDetail(state?.id);
        courseDetail(courseid);
        handleToogleRate();
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const reportCourse = async () => {
    try {
      if(!reportReason) setReportReasonErr("Report reason required");
      if(!reportText) setReportTextErr("Report description required");

      if(!reportReason || !reportText) return null;

      const res = await COURSE_API.reportCourse({
        course: state?.course_id,
        reportReason,
        reportText,
      });
      if (res) {
        console.log("RES ", res);
        // courseDetail(state?.id);
        courseDetail(courseid);
        handleToogleReport();
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const getBuyCourseUrl = async () => {
    try {
      if (
        storage &&
        storage?.authorities &&
        storage?.authorities[0] === "OPEN_USER"
      ) {
        navigate("/login", {
          state: {
            redirectBack: true,
          },
        });
        return null;
      }

      const res = await COURSE_API.buyCourse(state?.id);
      if (res && res?.sessionURL) {
        window.location.href = res?.sessionURL;
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    if (course?.organization?.rating)
      setOrgRating(course?.organization?.rating);
  }, [course]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    courseDetail();
    courseSection();
    courseLessons();
    setIsPreviousCompleted(state?.is_completed);
  }, []);

  useEffect(() => {
    if (lesson) {
      if (lesson?.type === "PDF") {
        if (lesson?.lessonProgress)
          setPageNumber(lesson?.lessonProgress?.lastViewedPage);
        else {
          setPageNumber(1);
          updateCourseProgress({
            lesson: lesson?._id,
            lastViewedPage: 1,
          });
        }
      }
      if (lesson?.type === "MCQ") getQuiz(lesson?._id);
      if (lesson?.type === "LESSON") getSlides(lesson?._id);

      navigate("/lesson-overview", {
        state: {
          course_id: state?.course_id,
          lesson_id: lesson?._id,
          is_completed: course?.isCompleted,
        },
      });
    }
  }, [lesson]);

  // PDF Handlers

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) =>
    setNumPages(nextNumPages);

  const onDocumentLoadError = (error) => setPdfError(true);

  const handlePDFNext = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  const handlePDFPrevious = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  // Quiz / Question Handlers

  const handleQuizPrevious = () =>
    setCurrentQuestionIndex(currentQuestionIndex - 1);

  const handleQuizNext = () => {
    if (currentQuestionIndex !== quizzes?.length - 1)
      setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleQuizOptionChange = (questionIndex, optionIndex) => {
    setSelectedChoices((prevChoices) => {
      return { ...prevChoices, [questionIndex]: optionIndex };
    });
  };

  // Slide / Lesson Handlers

  const handleSlidePrevious = () => setCurrentSlideIndex(currentSlideIndex - 1);

  const handleSlideNext = () => setCurrentSlideIndex(currentSlideIndex + 1);

  // Course Lesson Handlers

  const updateAndNext = async (body) => {
    try {
      await COURSE_API.lessonProgressUpdate(body);
      await COURSE_API.getCourseLessons(state?.course_id);
      courseSection();
    } catch (error) {
      console.log("updateAndNext ", error);
    }
  };

  const nextLesson = () => {
    if (lesson?.type === "PDF") {
      updateAndNext({
        lesson: lesson?._id,
        lastViewedPage: numPages,
      });
    }

    if (lesson?.type === "VIDEO") {
      updateAndNext({
        lesson: lesson?._id,
        playedTill: videoRef.current.duration * 1000,
      });
    }

    const index = lessons.findIndex((obj) => obj._id === lesson?._id);
    if (index < lessons.length - 1) setLesson(lessons[index + 1]);
  };

  const previousLesson = () => {
    const index = lessons.findIndex((obj) => obj._id === lesson?._id);
    if (index > 0) setLesson(lessons[index - 1]);
  };

  const currentLessonIndex = () => {
    if (lesson && lessons.length > 0) {
      const index = lessons.findIndex((obj) => obj._id === lesson?._id);
      return index;
    }
    return 0;
  };

  // Complete Modal Handler
  const toogleCompleteHandler = () => setShowCompleteModal(!showCompleteModal);

  const CompletedQuizSection = ({ theme }) => {
    return (
      <Box>
        <Card
          sx={{
            maxWidth: "90%",
            margin: "40px auto",
            padding: "20px",
            paddingBottom: "0px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
        >
          <CardContent sx={{ padding: "20px" }}>
            <Box
              sx={{
                textAlign: "left",
                mb: 4,
              }}
            >
              <Typography>
                {lesson?.lessonProgress?.answers?.length} Questions
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                Quiz
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  sx={{
                    bgcolor: lesson?.lessonProgress?.isPassed ? "green" : "red",
                    mr: 3,
                  }}
                >
                  {lesson?.lessonProgress?.isPassed ? (
                    <DoneIcon />
                  ) : (
                    <CancelIcon />
                  )}
                </Avatar>
                <Box sx={{ textAlign: "left" }}>
                  <Typography
                    style={{
                      color: lesson?.lessonProgress?.isPassed ? "green" : "red",
                    }}
                  >
                    {lesson?.lessonProgress?.isPassed ? "PASSED" : "FAILED"}{" "}
                  </Typography>
                  {/* <Typography>Due Dec 17,2023 </Typography> */}
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 4 }} />
            {/* <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar sx={{ bgcolor: "green", mr: 3 }}>
                  <DoneIcon />
                </Avatar>
                <Box sx={{ textAlign: "left" }}>
                  <Typography>Submited your Quiz </Typography>
                  <Typography>Due Dec 17,2023 </Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mt: 2, mb: 4 }} /> */}
            <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
              To pass {lesson?.passingPercentage} % or higher
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgressWithLabel
                value={lesson?.lessonProgress?.percentageAchieved}
                size={200}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  };

  const QuizSection = ({ quizes = [] }) => {
    return (
      <Card
        sx={{
          maxWidth: "90%",
          margin: "40px auto",
          padding: "20px",
          paddingBottom: "0px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <CardContent sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography>
              {currentQuestionIndex + 1}/{quizzes.length} Questions
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AccessAlarmIcon sx={{ mr: 1 }} />
              <Typography> 20.21</Typography>
            </Box>
          </Box>
          <Divider sx={{ mb: 4 }} />
          <Grid container>
            <Grid item md={6}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textAlign: "left",
                  mb: 3,
                  height: "100%",
                }}
              >
                Question: {quizes[currentQuestionIndex].text}
              </Typography>
            </Grid>
            <Grid item md={6} sx={{ display: "flex", justifyContent: "right" }}>
              <img
                style={{ width: "300px", height: "200px" }}
                src={quizes[currentQuestionIndex].media}
                alt=""
              />
            </Grid>
          </Grid>

          <Typography variant="h6" sx={{ textAlign: "left", mt: 2, mb: 1 }}>
            Options
          </Typography>
          <Grid container direction="column" spacing={2}>
            {quizes[currentQuestionIndex].options.map((option, index) => (
              <Grid item key={index}>
                <Button
                  fullWidth
                  variant={
                    selectedChoices[currentQuestionIndex] === index
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  onClick={() =>
                    handleQuizOptionChange(currentQuestionIndex, index)
                  }
                  sx={{
                    fontSize: "18px",
                    textTransform: "none",
                    borderRadius: "10px",
                    padding: "10px 20px",
                  }}
                >
                  {option.text}
                </Button>
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            fullWidth
            sx={{
              fontSize: "18px",
              textTransform: "none",
              borderRadius: "10px",
              mt: 2,
              padding: "10px 20px",
              backgroundColor: "blue",
            }}
            onClick={() => {
              setAnswer(quizes[currentQuestionIndex], currentQuestionIndex);
              handleQuizNext();
            }}
          >
            {t("lesson_detail.submit")}
          </Button>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingRight: 30,
              paddingLeft: 25,
              mt: 2,
            }}
          >
            {/* <Button
              disabled={currentQuestionIndex === 0}
              variant="contained"
              onClick={handleQuizPrevious}
            >
              Previous
            </Button>
            <Box sx={{ flex: 1 }} />
            <Button
              disabled={currentQuestionIndex === quizzes?.length - 1}
              variant="contained"
              onClick={handleQuizNext}
            >
              Next
            </Button> */}
          </Box>
        </CardContent>
      </Card>
    );
  };

  const SlideSection = ({ slide }) => {
    const htmlContent = slide[currentSlideIndex].html.substring(
      slide[currentSlideIndex].html.indexOf("<body>") + 6,
      slide[currentSlideIndex].html.indexOf("</body>")
    );

    return (
      <Card
        sx={{
          maxWidth: "90%",
          margin: "40px auto",
          padding: "20px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          minHeight: "400px",
          mHeight: "750px", // Corrected syntax
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
        <div>
          <iframe
            title="Raw HTML Iframe"
            srcDoc={slide[currentSlideIndex].html}
            scrolling="no"
            style={{
              width: "100%",
              height: "400px",
              border: "0px solid black",
            }}
          />
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              padding: 0,
              width: "120px",
              height: "40px",
              textTransform: "none",
            }}
            disabled={currentSlideIndex === 0}
            variant="outlined"
            onClick={handleSlidePrevious}
          >
            {t("lesson_detail.previous_slide")}
          </Button>
          <Box sx={{ flex: 1 }} />
          <Button
            sx={{
              padding: 0,
              width: "120px",
              height: "40px",
              textTransform: "none",
            }}
            disabled={currentSlideIndex === slides?.length - 1}
            variant="outlined"
            onClick={() => {
              handleSlideNext();
              updateCourseProgress({
                lesson: lesson?._id,
                latestSlide: slide[currentSlideIndex + 1]._id,
              });
            }}
          >
            {t("lesson_detail.next_slide")}
          </Button>
        </Box>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />

      <Dialog
        onClose={handleToogleReport}
        aria-labelledby="customized-dialog-title"
        open={openReport}
        fullWidth
      >
        <form>
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              backgroundColor: theme.palette.background.default,
              fontWeight: "bold",
            }}
            id="customized-dialog-title"
          >
            Report
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleToogleReport}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent
            dividers
            sx={{
              background: theme.palette.background.default,
              borderTop: "none",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontSize: 22, mb: 2 }}>
              Report this Course?
            </Typography>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Reason</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Reasons"
                onChange={(e) => {
                  setReportReason(e.target.value);
                  setReportReasonErr("");
                }}
              >
                {lookupState?.reportReasons?.map((e) => {
                  return <MenuItem value={e?._id}>{e?.reportText}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <Typography textAlign={"left"} variant="body2" sx={{color: "red", ml: 1.5}}>{reportResonErr}</Typography>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Other"
              multiline
              placeholder="Please Specify other reasons to help us"
              rows={4}
              sx={{ mt: 5, mb: 2 }}
              onChange={(e) => {
                setReportText(e.target.value);
                setReportTextErr("");
              }}
              error={reportTextErr.length > 0 ? true : false}
              helperText={reportTextErr}
            />
          </DialogContent>
          <DialogActions
            sx={{
              background: theme.palette.background.default,
            }}
          >
            <Button
              sx={{
                width: "140px",
                height: "38px",
                border: "1px solid #006AE6",
                color: "#006AE6",
              }}
              variant="outlined"
              onClick={handleToogleReport}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                reportCourse();
              }}
              sx={{
                width: "140px",
                height: "38px",
                backgroundColor: "#006AE6",
                color: "white",
                "&:hover": {
                  backgroundColor: "#006AE6",
                  color: "white",
                },
              }}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        onClose={handleToogleRate}
        aria-labelledby="customized-dialog-title"
        open={openRate}
        fullWidth
      >
        <form>
          <DialogTitle
            sx={{
              m: 0,
              p: 2,
              background: theme.palette.background.default,
              fontWeight: "bold",
            }}
            id="customized-dialog-title"
          >
            Rating
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleToogleRate}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent
            dividers
            sx={{
              background: theme.palette.background.default,
              borderTop: "none",
              textAlign: "center",
            }}
          >
            <AccountCircle sx={{ fontSize: "100px" }} />
            <Typography sx={{ fontSize: 22, mb: 1 }}>
              How was the Course?
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Rate your experience with the course
            </Typography>

            <Rating
              name="simple-controlled"
              size="large"
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
            />
          </DialogContent>
          <DialogActions
            sx={{
              background: theme.palette.background.default,
            }}
          >
            <Button
              sx={{
                width: "140px",
                height: "38px",
                border: "1px solid #006AE6",
                color: "#006AE6",
              }}
              variant="outlined"
              onClick={handleToogleRate}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "140px",
                height: "38px",
                backgroundColor: "#006AE6",
                color: "white",
                "&:hover": {
                  backgroundColor: "#006AE6",
                  color: "white",
                },
              }}
              variant="contained"
              onClick={() => {
                rateCourse();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={showCompleteModal}
        // TransitionComponent={Transition}
        keepMounted
        onClose={toogleCompleteHandler}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Congratulations !"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {/* TODO: Change course ID here */}
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              {state?.course_id === "66c73db0d5884f5b2985b931"
                ? "You can become a job broker"
                : `You have successfully completed that course ${course?.name}`}
            </Typography>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "500px",
              }}
            >
              <Grid item>
                <img
                  height={"400px"}
                  width={"250px"}
                  src={CongratulationImg}
                  alt="Congratulations"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toogleCompleteHandler}>
            <a href={course?.certificate?.image}>View Certificate</a>
          </Button>
          <Button onClick={toogleCompleteHandler}>Done</Button>
        </DialogActions>
      </Dialog>

      {/*//--------------- the whole page---------------- */}
      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid item maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <Grid
            container
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.secondary,
              paddingX: 2,
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                mt: 3,
                mb: 2,
                width: "100%",
                // paddingRight: 20,
              }}
            >
              <ArrowBackIcon
                onClick={() => navigate(`/course/${course?._id}`)}
              />
            </Box>

            <Grid container md={12} sx={{ display: "flex", flexWrap: "wrap" }}>
              <Grid
                item
                xs={12} // Full width on extra-small screens
                sm={12} // Adjusts to 6 columns on small screens
                md={12} // Adjusts to 4 columns on medium screens
                lg={8} // Adjusts to 3 columns on large screens
                xl={8} // Adjusts to 2 columns on extra-large screens
                sx={{
                  textAlign: "center",
                  width: "100%",
                  backgroundColor: theme.palette.background.secondary,
                  mb: "20px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: theme.palette.background.secondary,
                  }}
                >
                  {lesson?.type === "VIDEO" ? (
                    <Box>
                      <video
                        ref={videoRef}
                        onEnded={() => {
                          updateCourseProgress({
                            lesson: lesson?._id,
                            playedTill: videoRef.current.currentTime * 1000,
                          });
                        }}
                        width={"100%"}
                        height={"700px"}
                        controls
                        controlsList="nodownload"
                        disablePictureInPicture
                        muted
                        style={{ objectFit: "fill" }}
                      >
                        <source src={lesson?.mediaURL} type="video/mp4" />
                      </video>
                    </Box>
                  ) : null}

                  {lesson?.type === "PDF" ? (
                    <>
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          height: "700px",
                          justifyContent: "center",
                          overflowX: "auto", // Ensure the container allows horizontal scrolling
                          overflowY: "auto", // Enable horizontal scroll
                          // border: "2px solid red",
                          flexWrap: "wrap", // Allow wrapping for responsiveness
                        }}
                      >
                        {pdfError ? (
                          <p>Failed to load PDF file.</p>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <Document
                              file={lesson?.mediaURL}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={onDocumentLoadError}
                            >
                              {Array.from(new Array(numPages), (el, index) => (
                                <div
                                  style={{
                                    minWidth: "1000px", // Set to the width of the PDF page or larger
                                    maxWidth: "100%",
                                    // border: "2px solid blue",
                                    boxSizing: "border-box",
                                  }}
                                  id={`page_${index + 1}`}
                                  key={`grid_${index + 1}`}
                                >
                                  <Page
                                    scale={0.9}
                                    canvasBackground={COLORS.default_background}
                                    onRenderSuccess={() => {
                                      console.log("... 1", index + 1);
                                    }}
                                    width={1000} // Set the width to match the container or desired size
                                    pageNumber={index + 1}
                                    renderAnnotationLayer={false}
                                  />
                                </div>
                              ))}
                            </Document>
                          </div>
                        )}
                      </Grid>

                      {/* <Box
                    sx={{
                      mt: 5,
                      // position: "absolute",
                      // top: "86vh",
                      width: "100%",
                      right: 20,
                    }}
                  >
                    <p>
                      Page {pageNumber} of {numPages}
                    </p>
                    <Button
                      sx={{
                        mt: 1,
                        mr: 10,
                        width: "140px",
                        height: "38px",
                        // backgroundColor: theme.palette.background.primary,
                      }}
                      variant="contained"
                      onClick={handlePDFPrevious}
                      disabled={pageNumber === 1}
                    >
                      {t("lesson_detail.previous_page")}
                    </Button>
                    <Button
                      sx={{ width: "140px", height: "38px" }}
                      variant="contained"
                      onClick={() => {
                        // const element = document.getElementById('page_15'); 
                        // element.scrollIntoView();
                        handlePDFNext();
                        // updateCourseProgress({
                        //   lesson: lesson?._id,
                        //   lastViewedPage: pageNumber + 1,
                        // });
                      }}
                      disabled={pageNumber === numPages}
                    >
                      {t("lesson_detail.next_page")}
                    </Button>
                  </Box> */}
                    </>
                  ) : null}

                  {lesson?.type === "MCQ" && (
                    <>
                      {/* {lesson?.lessonProgress?.isCompleted ? (
                  <CompletedQuizSection />
                ) : (
                  <Box>
                    {quizzes && quizzes?.length > 0 && (
                      <QuizSection quizes={quizzes} />
                    )}
                  </Box>
                )} */}

                      <LessonQuiz
                        theme={theme}
                        quizzes={quizzes}
                        updateCourseProgress={updateCourseProgress}
                        handleQuizOptionChange={handleQuizOptionChange}
                        handleQuizNext={handleQuizNext}
                        selectedChoices={selectedChoices}
                        setSelectedChoices={setSelectedChoices}
                        lesson={lesson}
                      />
                    </>
                  )}

                  {lesson?.type === "LESSON" && (
                    <>
                      <Box>
                        {slides && slides?.length > 0 && (
                          <SlideSection slide={slides} />
                        )}
                      </Box>
                    </>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                      // paddingRight: 1,
                      // paddingLeft: 1,
                    }}
                  >
                    <Button
                      disabled={currentLessonIndex() === 0}
                      variant="contained"
                      color="primary"
                      onClick={() => previousLesson()}
                      sx={{
                        width: "170px",
                        height: "38px",
                        textTransform: "none",
                        // backgroundColor: theme.palette.background.primary,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          gap: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <ChevronLeft fontSize="small" />
                        <Typography
                          fontSize={"15px"}
                          sx={{ fontWeight: "bold" }}
                        >
                          {t("lesson_detail.previous_lesson")}
                        </Typography>
                      </Box>
                    </Button>
                    <Button
                      disabled={currentLessonIndex() === lessons.length - 1}
                      variant="contained"
                      color="primary"
                      onClick={() => nextLesson()}
                      sx={{
                        width: "170px",
                        height: "38px",
                        textTransform: "none",
                        // backgroundColor: theme.palette.background.primary,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          gap: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Typography
                          fontSize={"15px"}
                          sx={{ fontWeight: "bold" }}
                        >
                          {t("lesson_detail.next_lesson")}
                        </Typography>
                        <ChevronRight fontSize="small" />
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Grid>

              <Divider
                sx={{
                  borderWidth: "0.5px",
                  backgroundColor: "#E2F0FA",
                  mb: "20px",
                  ml: "30px",
                  mr: "20px",
                }}
              />
              {/*//----------------- Right Side ----------------*/}
              {/* //--------- Course Content Listing */}
              <Grid
                xs={12} // Full width on extra-small screens
                sm={12} // Adjusts to 6 columns on small screens
                md={12} // Adjusts to 4 columns on medium screens
                lg={3.45} // Adjusts to 3 columns on large screens
                xl={3.45} // Adjusts to 2 columns on extra-large screens
                sx={{
                  width: "100%",
                  minWidth: "250px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.text.main,
                  }}
                >
                  {t("course_detail.course_content")}
                </Typography>

                <Grid
                  mt={3}
                  sx={{
                    paddingX: 3,
                    maxHeight: "600px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "lightgrey",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "darkgrey",
                    },
                  }}
                >
                  <Box>
                    {sections.map((el) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "50px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                textOverflow: "ellipsis",
                                lineHeight: "1.2em",
                                maxHeight: "2.4em", // lineHeight * 2
                                // border: "1px solid red",
                              }}
                            >
                              {el?.name}
                            </Typography>
                          </Box>

                          {el?.lessons?.map((e) => {
                            globalLessonIndex = globalLessonIndex + 1;
                            return (
                              <>
                                <LessonRow e={e} index={globalLessonIndex} />
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </Box>
                </Grid>

                <Divider sx={{ mt: 3, mb: 5, borderWidth: "1px" }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            display={"flex"}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.background.secondary,
              paddingBottom: "70px",
            }}
          >
            <Grid
              container
              md={12}
              display={"flex"}
              flexWrap={"wrap"}
              padding={2}
            >
              {/*//----------------- Left section ----------------*/}
              {/* <Grid item md={3} minWidth={"250px"}>
              <Box>
                <Box>
                  <Box sx={{ paddingTop: 1 }}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: theme.palette.text.main,
                      }}
                    >
                      {t("course_detail.course_content")}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      maxHeight: "600px",
                      overflowY: "auto",
                      padding: "2px",
                      marginBottom: "-8px", // Adjust the margin to make the scrollbar smaller from the bottom
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "lightgrey",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "darkgrey",
                      },
                    }}
                  >
                    {sections.map((el) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0 7px",
                              color: theme.palette.text.main,
                              backgroundColor: theme.palette.background.default,
                              height: "50px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                textOverflow: "ellipsis",
                                lineHeight: "1.2em",
                                maxHeight: "2.4em", // lineHeight * 2
                              }}
                            >
                              {el?.name}
                            </Typography>
                          </Box>

                          {el?.lessons?.map((e) => {
                            globalLessonIndex = globalLessonIndex + 1;
                            return (
                              <>
                                <LessonRow e={e} index={globalLessonIndex} />
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ mt: 5, mb: 5, borderWidth: "1px" }} />
            </Grid> */}

              {/* <Divider
              sx={{ borderWidth: "0.5px", backgroundColor: "#E2F0FA" }}
            /> */}
              {/*//--------------------- Right section -----*/}
              <Grid
                item
                xs={12} // Full width on extra-small screens
                sm={12} // Adjusts to 6 columns on small screens
                md={12} // Adjusts to 4 columns on medium screens
                lg={8} // Adjusts to 3 columns on large screens
                xl={8} // Adjusts to 2 columns on extra-large screens
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "28px",
                    flexWrap: "wrap",
                    gap: 2,
                    // border: "2px solid red",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"start"}
                    // sx={{ border: "2px solid blue" }}
                  >
                    <Box maxWidth={"400px"} display={"flex"}>
                      <Typography sx={{ fontWeight: "bold", fontSize: 25 }}>
                        {course?.name}
                      </Typography>
                      {storage?.authorities[0] === "USER" ? (
                        <IconButton
                          sx={{ ml: 1, padding: 0 }}
                          onClick={handleBookmarkClick}
                          aria-label="bookmark"
                        >
                          {course?.isStarred ? (
                            <BookmarkIcon
                              sx={{
                                fontSize: "32px",
                                // color: "#006AE6",
                                color:
                                  lookupState?.branding?.colors?.primary ||
                                  theme.palette.background.primary,
                                padding: 0,
                              }}
                            />
                          ) : (
                            <BookmarkBorderRoundedIcon
                              sx={{
                                fontSize: "32px",
                                // color: "#006AE6",
                                color:
                                  lookupState?.branding?.colors?.primary ||
                                  theme.palette.background.primary,
                                padding: 0,
                              }}
                            />
                          )}
                        </IconButton>
                      ) : null}
                    </Box>
                    <Typography mt={0} sx={{ fontSize: 18 }}>
                      {course?.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                      onClick={() => {
                        navigate("/instructor-detail", {
                          state: {
                            org_id: course?.organization?._id,
                          },
                        });
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          // color: "#006AE6",
                          color:
                            lookupState?.branding?.colors?.primary ||
                            theme.palette.background.primary,
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {t("course_detail.created_by")}
                        <Typography
                          sx={{
                            ml: 1,
                            textDecoration: "underline",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {course?.organization?.name}
                        </Typography>
                      </Typography>
                      {/* <Typography sx={{ display: "flex", ml: 2 }}>
                      With{" "}
                      <Typography sx={{ ml: 1, textDecoration: "underline" }}>
                        Anders Jallai
                      </Typography>
                    </Typography>
                    <Typography sx={{ display: "flex", ml: 2 }}>
                      Publishers{" "}
                      <Typography sx={{ ml: 1, textDecoration: "underline" }}>
                        Anders Jallai
                      </Typography>
                    </Typography> */}
                    </Box>
                  </Box>

                  {/*----------------------- RATE & REPORT BUTTON */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {course?.canRate ? (
                      <Button
                        startIcon={<StarIcon />}
                        variant="outlined"
                        sx={{
                          backgroundColor: "none",
                          borderRadius: "4px",
                          mb: 2,
                          padding: 1.4,
                          width: "100px",
                          height: "40px",
                        }}
                        onClick={() => {
                          handleToogleRate();
                        }}
                      >
                        Rate
                      </Button>
                    ) : null}

                    {/* {course?.canReport ? (
                        <Button
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        onClick={() => {
                          handleToogleReport();
                        }}
                        variant="outlined"
                        sx={{
                          backgroundColor: "none",
                          borderRadius: "4px",
                          padding: 1.4,
                          width: "40px",
                          height: "40px",
                        }}
                      >
                        <ReportIcon />
                      </Button>
                      ) : null} */}
                    {/*------------------------------- RATE & REPORT BUTTON */}
                  </Box>
                </Box>

                <Box p={2}>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container columnGap={2}>
                    {details.map((detail, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          md={3}
                          sm={6}
                          // sx={{ border: "1px solid red" }}
                        >
                          <Typography variant="h6">{detail.heading}</Typography>
                          <Box display="flex" alignItems="center">
                            {detail.icon}
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                color: theme.palette.text.body,
                              }}
                              variant="body1"
                              ml={1}
                            >
                              {detail.description}
                            </Typography>
                          </Box>
                        </Grid>
                        {index < details.length && (
                          <Divider orientation="vertical" flexItem />
                        )}
                      </React.Fragment>
                    ))}
                    <Box display={"flex"} alignItems="center">
                      {course?.canReport ? (
                        <Button
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          onClick={() => {
                            handleToogleReport();
                          }}
                          variant="outlined"
                          sx={{
                            backgroundColor: "none",
                            borderRadius: "4px",
                            padding: 1.4,
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <ReportIcon />
                        </Button>
                      ) : null}
                    </Box>
                  </Grid>
                  <Divider sx={{ mt: 2 }} />
                </Box>

                {/*------------------------ COURSE INCLUDES section-------*/}
                <Box mt={2} mb={2}>
                  <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                    This Course includes
                  </Typography>
                  <Box
                    mt={1}
                    display={"flex"}
                    flexWrap={"wrap"}
                    maxWidth={"550px"}
                    gap={"5px 35px"}
                  >
                    <Box display={"flex"}>
                      <WorkspacePremiumIcon />{" "}
                      <Typography
                        ml={1}
                        sx={{ color: theme.palette.text.body }}
                      >
                        Completion Certificate
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <DevicesIcon />
                      <Typography
                        ml={1}
                        sx={{ color: theme.palette.text.body }}
                      >
                        Access on mobile, desktop
                      </Typography>
                    </Box>
                    <Box display={"flex"}>
                      <AllInclusiveIcon />
                      <Typography
                        ml={1}
                        sx={{ color: theme.palette.text.body }}
                      >
                        Lifetime Access
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/*------------------ COURSE INCLUDES section-------*/}

                <Box sx={{ textAlign: "justify", mt: 4 }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                    Description
                  </Typography>
                  <Typography mt={1} sx={{ color: theme.palette.text.body }}>
                    {course?.description}
                  </Typography>

                  {/* <Typography sx={{ mt: 3 }}>
                      {" "}
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo,
                      porro perferendis ipsum ullam molestias illo consectetur nam
                      repellendus veniam odit illum tempore iusto cumque asperiores
                      aut in tempora magni delectus. Lorem ipsum dolor sit amet
                      consectetur adipisicing elit. Quo, porro perferendis ipsum
                      ullam molestias illo consectetur nam repellendus veniam odit
                      illum tempore iusto cumque asperiores aut in tempora magni
                      delectus. Lorem ipsum dolor sit amet consectetur adipisicing
                      elit. Quo, porro perferendis ipsum ullam molestias illo
                      consectetur nam repellendus veniam odit illum tempore iusto
                      cumque asperiores aut in tempora magni delectus. Lorem ipsum
                      dolor sit amet consectetur adipisicing elit. Quo, porro
                      perferendis ipsum ullam molestias illo consectetur nam
                      repellendus veniam odit illum tempore iusto cumque asperiores
                      aut in tempora magni delectus.
                    </Typography> */}

                  {/* <Typography sx={{ mt: 3 }}>
                      {" "}
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo,
                      porro perferendis ipsum ullam molestias illo consectetur nam
                      repellendus veniam odit illum tempore iusto cumque asperiores
                      aut in tempora magni delectus. Lorem ipsum dolor sit amet
                      consectetur adipisicing elit. Quo, porro perferendis ipsum
                      ullasto cumque asperiores aut in tempora magni delectus.
                    </Typography> */}

                  <Box>
                    {" "}
                    <Typography
                      sx={{ fontWeight: "bold", fontSize: 18, mt: 4 }}
                    >
                      {" "}
                      {t("course_detail.updated_on")}
                    </Typography>
                    <Typography sx={{ color: theme.palette.text.body }}>
                      {new Date(course?.updatedAt).toDateString()}
                    </Typography>
                    {/* <Typography>AudioBook: December 4,2023</Typography> */}
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Typography>{t("course_detail.tags")}</Typography>

                    <Box sx={{ display: "flex", width: "100%", mt: 1 }}>
                      {course?.tags?.map((el) => {
                        return (
                          <Chip
                            key={el?._id}
                            label={el?.name}
                            variant="outlined"
                            sx={{ fontWeight: "bold" }}
                          />
                        );
                      })}
                      {/* <Chip
                          label="Chip Filled"
                          variant="outlined"
                          sx={{ fontWeight: "bold" }}
                        />
                        <Chip
                          label="Chip Filled"
                          variant="outlined"
                          sx={{ fontWeight: "bold", ml: 2 }}
                        /> */}
                    </Box>
                  </Box>

                  {/*---------------------- INSTRUCTOR CARD -----------*/}
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: theme.palette.background.default,
                      borderRadius: "20px",
                      marginTop: 5,
                      padding: 3,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: { xs: 16, md: 18 },
                        color:
                          lookupState?.branding?.colors?.primary ||
                          theme.palette.background.primary,
                      }}
                    >
                      Instructor
                    </Typography>
                    <Box mt={2} mb={1} minWidth={"250px"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box sx={{ paddingRight: { xs: 2, md: 5 } }}>
                          <Avatar
                            sx={{
                              width: { xs: 65, md: 100 },
                              height: { xs: 65, md: 100 },
                            }}
                            alt="Remy Sharp"
                            src={course?.organization?.profileImage}
                          />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography
                            mb={1}
                            sx={{
                              fontSize: { xs: 15, mc: 18 },
                              color:
                                lookupState?.branding?.colors?.primary ||
                                theme.palette.background.primary,
                              fontWeight: "bold",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 2,
                              textOverflow: "ellipsis",
                              lineHeight: "1.2em",
                              maxHeight: "2.4em", // lineHeight * 2
                            }}
                          >
                            {course?.organization?.name}
                          </Typography>
                          <Typography
                            mb={0.5}
                            sx={{
                              fontSize: { xs: 13, md: 15 },
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 1,
                              textOverflow: "ellipsis",
                              lineHeight: "1.2em",
                              maxHeight: "2.4em",
                            }}
                          >
                            {course?.organization?.headline}
                          </Typography>
                          <Rating value={orgRating} size="small" readOnly />
                          <Button
                            onClick={() => {
                              navigate("/instructor-detail", {
                                state: {
                                  org_id: course?.organization?._id,
                                },
                              });
                            }}
                            mt={0.5}
                            sx={{
                              width: "100%",
                              maxWidth: "110px",
                              height: "30px",
                              fontSize: { xs: 14, sm: 16 },
                              padding: 0,
                              textTransform: "none",
                              fontWeight: "bold",
                              color:
                                lookupState?.branding?.colors?.primary ||
                                theme.palette.background.primary,
                            }}
                          >
                            Check Profile
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Footer
          theme={theme}
          mode={mode}
          handleToggleChange={handleToggleChange}
        />
      </Box>
    </React.Fragment>
  );
};

export default LessonOverview;
