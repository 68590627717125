// AccountMenu.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Menu, MenuItem, Divider } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import STORAGE from "../utils/local.storage";
import { AUTH_API, LOOKUP_API } from "../apis";
import { useDispatch } from "react-redux";
import { set } from "../actions/authActions";
import { setLookup } from "../actions/lookupActions";
import { useSelector } from 'react-redux';

const AccountMenu = ({
  anchorEl,
  setAnchorEl,
  isLogin,
  setIsLogin,
  handleToggleChange,
  mode,
  theme,
  t,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lookupState = useSelector((state) => state.lookup);

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    STORAGE.logout();

    const auth_user = await AUTH_API.loginOpenUser();
    dispatch(
      set({ authorities: auth_user?.authorities, token: auth_user?.token })
    );
    const res = await LOOKUP_API.getLookup(auth_user?.token);
    if (res) dispatch(setLookup(res));

    setIsLogin(false);
    handleAccountMenuClose();
    navigate("/");
  };

  const handleThemeClick = () => {
    handleToggleChange(); // Toggle the theme mode
    handleAccountMenuClose(); // Close the menu
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleAccountMenuClose}
    >
      <Box
        sx={{
          padding: "5px 0",
          width: "230px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <MenuItem
          sx={{
            color: theme.palette.text.body,
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={handleThemeClick} // Use the handler for theme toggle
        >
          <ColorLensIcon sx={{ mr: 2, color: lookupState?.branding?.colors?.primary || theme.palette.background.primary }} /> {t("header.theme")}
        </MenuItem>
        <MenuItem
          sx={{
            color: theme.palette.text.body,
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={() => {
            navigate("/setting");
            handleAccountMenuClose();
          }}
        >
          <SettingsIcon sx={{ mr: 2, color: lookupState?.branding?.colors?.primary || theme.palette.background.primary }} />
          {t("header.settings")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{
            color: theme.palette.text.body,
            fontWeight: "bold",
            fontSize: "16px",
          }}
          onClick={handleLogout}
        >
          <LogoutIcon sx={{ mr: 2, color: lookupState?.branding?.colors?.primary || theme.palette.background.primary }} /> {t("header.logout")}
        </MenuItem>
      </Box>
    </Menu>
  );
};

export default AccountMenu;
