import { combineReducers } from "redux";
import counterReducer from "./counterReducer";
import authReducer from "./authReducer";
import lookupReducer from "./lookupReducer";

const rootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  lookup: lookupReducer,
});

export default rootReducer;
