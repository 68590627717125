import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Course from "./pages/Course";
import ExploreCourse from "./pages/ExploreCourse";
import LessonOverview from "./pages/LessonOverview";
import CourseOverview from "./pages/CourseOverview";
import VerificationCourse from "./pages/VerificationCourse";
import Signup from "./pages/Signup";
import Search from "./pages/Search";
import Category from "./pages/Category";
import Setting from "./pages/Settings";
import PaymentMethods from "./pages/AddPaymentMethod";
import CourseOverviewUpdated from "./pages/CourseOverviewUpdated";
import InstructorDetail from "./pages/InstructorDetail";
import "./App.css";
import { useEffect, useState } from "react";
import { AUTH_API, LOOKUP_API } from "./apis";
import STORAGE from "./utils/local.storage";
// import ThemeProvider from "./context/ThemeProvider";
import { SnackbarProvider } from "./context/SnackbarContext";
import Snackbar from "./components/Snackbar";

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { set } from "./actions/authActions";
import { setLookup } from "./actions/lookupActions";
import { useSelector, useDispatch } from "react-redux";
import { baseTheme, getDarkTheme, getLightTheme } from "./theme"; // Import theme, darkTheme, and lightTheme
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import HomePage from "./pages/HomePage";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
// import AboutUs2 from "./pages/AboutUs2";
import FAQs from "./pages/FAQs";
import EmailVerified from "./pages/EmailVerified";
import CourseNotFound from "./components/CourseNotFound";

function App() {
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [currentTheme, setCurrentTheme] = useState("regular");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState(prefersDarkMode); // false for light, true for dark or prefersDarkMode to check if the user prefers the dark mode
  const dispatch = useDispatch();
  const conState = useSelector((state) => state.auth);
  const conLookup = useSelector((state) => state.lookup);

  const handleToggleChange = () => {
    STORAGE.setTheme(!mode);
    setMode((prevMode) => !prevMode);
  };

  // Memoized theme creation based on mode
  // const memorizedTheme = React.useMemo(
  //   () =>
  //     mode
  //       ? getDarkTheme()
  //       : getLightTheme(conState?.branding?.colors?.primary),
  //   [mode]
  // );

  console.log("... branding ", currentTheme);

  const memorizedTheme = mode
    ? getDarkTheme(conLookup?.branding?.colors?.primary)
    : getLightTheme(conLookup?.branding?.colors?.primary);

  const loginUser = async () => {
    try {
      const auth_user = await AUTH_API.loginOpenUser();
      setIsOpenUser(true);
      dispatch(
        set({ authorities: auth_user?.authorities, token: auth_user?.token })
      );
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const lookup = async () => {
    try {
      const themeColor = STORAGE.getTheme();
      setMode(themeColor === "true" ? true : false);
      const res = await LOOKUP_API.getLookup();
      if (res) {
        dispatch(setLookup(res));
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    if (conLookup && conLookup?.branding && currentTheme === "regular") {
      setCurrentTheme("custom");
    } else if (!conLookup?.branding && currentTheme === "custom") {
      setCurrentTheme("regular");
    }
  }, [conLookup]);

  useEffect(() => {
    lookup();
    const isThereUser = STORAGE.getStorage();
    if (!isThereUser) loginUser();
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={memorizedTheme}>
        <CssBaseline />
        <SnackbarProvider>
          <Snackbar />
          {/* <Header
            theme={memorizedTheme}
            mode={mode}
            handleToggleChange={handleToggleChange}
          /> */}
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/login"
              element={
                conState && conState?.authorities?.[0] === "USER" ? (
                  <Navigate to="/" />
                ) : (
                  <Login
                    theme={memorizedTheme}
                    mode={mode}
                    handleToggleChange={handleToggleChange}
                  />
                )
              }
            ></Route>

            <Route
              path="/verification-course"
              element={
                <VerificationCourse
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            {conState && conState?.authorities?.[0] === "USER" ? (
              <Route
                path="/my-courses"
                element={
                  <Course
                    theme={memorizedTheme}
                    mode={mode}
                    handleToggleChange={handleToggleChange}
                  />
                }
              ></Route>
            ) : null}
            <Route
              path="/explore"
              element={
                <ExploreCourse
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                  theme={memorizedTheme}
                />
              }
            ></Route>
            <Route
              path="/lesson-overview"
              element={
                conState && conState?.authorities?.[0] === "OPEN_USER" ? (
                  <Navigate to="/" />
                ) : (
                  <LessonOverview
                    theme={memorizedTheme}
                    mode={mode}
                    handleToggleChange={handleToggleChange}
                  />
                )
              }
            ></Route>
            <Route
              path="/signup"
              element={
                conState && conState?.authorities?.[0] === "USER" ? (
                  <Navigate to="/" />
                ) : (
                  <Signup
                    theme={memorizedTheme}
                    mode={mode}
                    handleToggleChange={handleToggleChange}
                  />
                )
              }
            ></Route>
            {/* <Route
              path="/course-overview"
              element={
                <CourseOverview
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route> */}
            <Route
              path="/search"
              element={
                <Search
                  themeSearch={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/category"
              element={
                <Category
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/setting"
              element={
                <Setting
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>

            <Route
              path="/instructor-detail"
              element={
                <InstructorDetail
                  theme={memorizedTheme}
                  // themeSearch={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>

            <Route
              path="/course/:courseid"
              element={
                <CourseOverviewUpdated
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            {/*//--- Course 404 Route */}
            <Route
              path="/course/404"
              element={
                <CourseNotFound
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            />

            <Route
              path="/add-payment-method"
              element={
                <PaymentMethods
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicy
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/terms-conditions"
              element={
                <TermsConditions
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/contact-us"
              element={
                <ContactUs
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            {/* <Route
              path="/about-us-2"
              element={
                <AboutUs2
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route> */}
            <Route
              path="/faqs"
              element={
                <FAQs
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/about-us"
              element={
                <AboutUs
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            ></Route>
            <Route
              path="/email-verified"
              element={
                <EmailVerified
                  theme={memorizedTheme}
                  mode={mode}
                  handleToggleChange={handleToggleChange}
                />
              }
            />
            <Route path="*" element={<Navigate to={"/"} />}></Route>
          </Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
