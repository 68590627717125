import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  IconButton,
  Button,
  Typography,
  TextField,
  InputAdornment,
  ButtonGroup,
  Grid,
  Card,
  CardMedia,
  CardContent,
  InputLabel,
  Slider,
  MenuItem,
  OutlinedInput,
  FormControl,
  Chip,
  Select,
  Stack,
  Rating,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Header from "../components/Header";
import LOOKUP_API from "../apis/lookup.api";
import Footer from "../components/Footer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const buttons = [
  <Button
    sx={{
      color: "black",
      borderColor: "lightgrey!important",
      fontSize: "13px",
      paddingLeft: "30px",
      paddingRight: "40px",
    }}
    key="one"
  >
    Overall
  </Button>,
  <Button
    sx={{
      color: "black",
      borderColor: "lightgrey!important",
      fontSize: "13px",
      paddingLeft: "40px",
      paddingRight: "40px",
    }}
    key="two"
  >
    My Courses
  </Button>,
  <Button
    sx={{
      color: "black",
      borderColor: "lightgrey!important",
      fontSize: "13px",
      paddingLeft: "40px",
      paddingRight: "40px",
    }}
    key="three"
  >
    Assigned
  </Button>,
  <Button
    sx={{
      color: "black",
      borderColor: "lightgrey!important",
      fontSize: "13px",
      paddingLeft: "40px",
      paddingRight: "40px",
    }}
    key="one"
  >
    Recommended
  </Button>,
  <Button
    sx={{
      color: "black",
      borderColor: "lightgrey!important",
      fontSize: "13px",
      paddingLeft: "40px",
      paddingRight: "40px",
    }}
    key="two"
  >
    Popular Courses
  </Button>,
  <Button
    sx={{
      color: "black",
      borderColor: "lightgrey!important",
      fontSize: "13px",
      paddingLeft: "40px",
      paddingRight: "40px",
    }}
    key="three"
  >
    Trending
  </Button>,
  <Button
    sx={{
      color: "black",
      borderColor: "lightgrey!important",
      fontSize: "13px",
      paddingLeft: "40px",
      paddingRight: "40px",
    }}
    key="three"
  >
    Course Bundles
  </Button>,
];
const Category = ({ theme, mode, handleToggleChange }) => {
  const navigate = useNavigate();
  const [category, setCategory] = React.useState([]);
  const [subcategory, setSubcategory] = React.useState(false);

  const getCategory = async () => {
    try {
      const res = await LOOKUP_API.getLookup();
      if (res) {
        console.log("Categories ", res);
        setCategory(res?.categories);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  React.useEffect(() => {
    getCategory();
  }, []);

  const CourseCard = ({ name, subCategory }) => {
    return (
      <Card
        onClick={() => {
          setSubcategory(true);
          console.log(subCategory);
        }}
        sx={{
          maxWidth: 300,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "70%",
          padding: 3,
          cursor: "pointer",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            {name}
          </Typography>
          <Divider sx={{ width: "300px", mb: 1 }} />
          {subcategory && (
            <Box>
              {/* <Typography sx={{ fontWeight: "bold" }}>
                Sub Categories
              </Typography> */}
              <Typography gutterBottom variant="h4" component="div">
                {subCategory.map((sub) => (
                  <>
                    <Typography
                      sx={{ mb: 2 }}
                      onClick={() => {
                        navigate("/search");
                      }}
                    >
                      {sub.name}
                    </Typography>
                  </>
                ))}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Box
        marginTop={10}
        sx={{
          backgroundColor: "#F5F6FA",
          padding: "50px",
          paddingBottom: 0,
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "nowrap",
          }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "30px", whiteSpace: "nowrap" }}
          >
            Search Category
          </Typography>

          <TextField
            sx={{ backgroundColor: "white" }}
            size="medium"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: "white",
            width: "100%",
            mt: 4,
            height: "60px",
          }}
        >
          <ButtonGroup
            size="large"
            variant="text"
            sx={{ height: "60px", ml: 2 }}
          >
            {buttons}
          </ButtonGroup>
        </Box>

        <Grid container rowSpacing={8} columnSpacing={2} mt={0.1}>
          {category.map((category) => (
            <Grid item xs={12} md={3} minWidth={"300px"} key={category.id}>
              <CourseCard
                name={category.name}
                subCategory={category.subCategories}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Category;
