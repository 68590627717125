import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { COURSE_API } from "../apis";
import CourseCard from "../components/CourseCard";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

const LearningViewing = ({ theme, t }) => {
  const [courses, setCourses] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const conState = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Check if the screen width is less than or equal to 600px (mobile devices)
  const isMobile = useMediaQuery("(max-width:600px)");

  const getCourses = async (token = null) => {
    try {
      const res = await COURSE_API.getCoursesPaginated({}, null, token);
      if (res) {
        setCourses(res?.courses);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    getCourses(conState?.token);
  }, [conState]);

  const visibleCourses = 3; // Number of courses to show at a time
  const totalCourses = courses.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - visibleCourses, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + visibleCourses, totalCourses - visibleCourses)
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      sx={{ paddingTop: 7, paddingBottom: 7 }}
    >
      <Box
        maxWidth="1200px"
        width="100%"
        sx={{ position: "relative", paddingX: 2 }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 3 }}>
          {t("home.learners_are_viewing")}
        </Typography>

        {!isMobile && (
          <IconButton
            onClick={handlePrev}
            sx={{
              display: "flex",
              width: "50px",
              height: "50px",
              color: theme.palette.text.default,
              position: "absolute",
              top: "12rem",
              zIndex: "2",
              left: "-0.4rem",
              opacity: currentIndex === 0 ? 0.5 : 1,
              pointerEvents: currentIndex === 0 ? "none" : "auto",
            }}
          >
            <WestIcon sx={{ fontSize: "25px" }} />
          </IconButton>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            paddingX: 2,
            overflowX: "hidden", // Hide horizontal scrollbar
          }}
        >
          <Grid
            sx={{
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              paddingX: 2,
              scrollbarWidth: "none", // Hide scrollbar in Firefox
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar in WebKit browsers
              },
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                width: "100%",
                gap: 1.7,
                transition: "transform 0.5s ease",
                transform: `translateX(-${
                  (currentIndex / totalCourses) * 165
                }%)`,
              }}
            >
              {courses.map((el, index) => (
                <Grid key={index} width={"100%"}>
                  <Grid minWidth={"255px"}>
                    <CourseCard
                      theme={theme}
                      course={el}
                      onNav={() =>
                        navigate(`/course/${el?._id}`, {
                          state: {
                            id: el?._id,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>

        {!isMobile && (
          <IconButton
            onClick={handleNext}
            sx={{
              display: "flex",
              width: "50px",
              height: "50px",
              color: theme.palette.text.default,
              position: "absolute",
              top: "12rem",
              zIndex: "2",
              right: "-0.4rem",
            }}
          >
            <EastIcon sx={{ fontSize: "25px" }} />
          </IconButton>
        )}
      </Box>
    </Grid>
  );
};

export default LearningViewing;
