import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Avatar,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import CourseCard from "../components/CourseCard";
// import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { LOOKUP_API } from "../apis";
import Footer from "../components/Footer";
import LanguageIcon from "@mui/icons-material/Language";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import STORAGE from "../utils/local.storage";
import { COURSE_API } from "../apis";

const InstructorDetail = ({ theme, mode, handleToggleChange }) => {
  const [instructor, setInstructor] = useState({});
  const [courses, setCourses] = useState([]);
  const [bookmarked, setBookmarked] = useState(false);

  const storage = STORAGE.getStorage();
  const { state } = useLocation();
  const navigate = useNavigate();

  const bookmarkCourse = async () => {
    try {
      const res = await COURSE_API.bookmarkCourse(state?.id);
      if (res) {
        console.log("RES ", res);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleBookmarkClick = () => {
    setBookmarked(!bookmarked);
    bookmarkCourse();
  };

  const getProfile = async (id) => {
    try {
      const res = await LOOKUP_API.getOrgProfile(id);
      if (res) {
        setCourses(res?.courses);
        setInstructor(res?.organization);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    getProfile(state?.org_id);
  }, []);

  return (
    <>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />

      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <Typography
            mb={5}
            fontWeight={"bold"}
            sx={{
              fontSize: { xs: "25px", sm: "30pxs" },
              color: theme.palette.text.H1,
            }}
          >
            Instructor Info
          </Typography>
          <Grid>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
                // border: "2px solid blue",
              }}
            >
              {/*-------Avatar and Icons Section----------*/}
              <Box
                flexGrow={1.5}
                display="flex"
                flexDirection="column"
                alignItems={"center"}
                // sx={{ border: "2px solid orange" }}
              >
                <Avatar
                  sx={{ width: 166, height: 166 }}
                  alt="Remy Sharp"
                  src={instructor?.profileImage}
                />
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"space-evenly"}
                  sx={{ mt: 3, width: "100%" }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    sx={{ width: "100%" }}
                  >
                    <Box
                      display="flex"
                      sx={{
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        fontWeight={"bold"}
                        fontSize={"20px"}
                        sx={{ color: theme.palette.text.default }}
                      >
                        {instructor?.studentsCount}
                      </Typography>
                      <Typography
                        fontWeight={"bold"}
                        sx={{ color: theme.palette.text.default }}
                        fontSize={"15px"}
                      >
                        Students
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      sx={{
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* <CommentIcon
                    sx={{ color: theme.palette.text.body, fontSize: "18px" }}
                  /> */}
                      <Typography
                        fontWeight={"bold"}
                        fontSize={"18px"}
                        sx={{ color: theme.palette.text.default }}
                      >
                        {instructor?.rating?.toFixed(1)} (
                        {instructor?.ratingsCount})
                      </Typography>
                      <Typography
                        fontWeight={"bold"}
                        sx={{ color: theme.palette.text.default }}
                        fontSize={"15px"}
                      >
                        Ratings
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    sx={{ mt: 1, width: "100%" }}
                  >
                    {instructor?.website ? (
                      <Button
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textTransform: "none",
                          fontWeight: "bold",
                          color: theme.palette.text.default,
                        }}
                        onClick={() => {
                          window.open(instructor?.website, "_blank");
                        }}
                      >
                        <LanguageIcon
                          sx={{
                            fontSize: "30px",
                            color: theme.palette.text.default,
                          }}
                        ></LanguageIcon>
                        Website
                      </Button>
                    ) : null}

                    {instructor?.linkedin ? (
                      <Button
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textTransform: "none",
                          fontWeight: "bold",
                          color: theme.palette.text.default,
                        }}
                        onClick={() => {
                          window.open(instructor?.linkedin, "_blank");
                        }}
                      >
                        <LinkedInIcon
                          sx={{
                            fontSize: "30px",
                            color: theme.palette.text.default,
                          }}
                        ></LinkedInIcon>
                        Linkedin
                      </Button>
                    ) : null}

                    {/*-------- When it has LinkedIn in the data is not needed */}
                    <Button
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textTransform: "none",
                        fontWeight: "bold",
                        color: theme.palette.text.default,
                      }}
                      onClick={() => {
                        window.open(instructor?.linkedin, "_blank");
                      }}
                    >
                      <LinkedInIcon
                        sx={{
                          fontSize: "30px",
                          color: theme.palette.text.default,
                        }}
                      ></LinkedInIcon>
                      Linkedin
                    </Button>
                    {/*-------- When it has LinkedIn in the data is not needed */}
                  </Box>
                </Box>
              </Box>
              {/*-------Avatar and Icons Section----------*/}

              <Box
                mt={1}
                flexGrow={1}
                display={"flex"}
                flexDirection="column"
                sx={{ width: "60vw" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: { xs: 20, sm: 25 } }}
                  >
                    {instructor?.name}
                  </Typography>
                  {/* {storage?.authorities[0] === "USER" ? (
                  <IconButton
                    sx={{ ml: 0.5 }}
                    onClick={handleBookmarkClick}
                    aria-label="bookmark"
                  >
                    {bookmarked ? (
                      <StarIcon sx={{ fontSize: "32px", color: "#006AE6" }} />
                    ) : (
                      <StarBorderIcon
                        sx={{ fontSize: "32px", color: "#006AE6" }}
                      />
                    )}
                  </IconButton>
                ) : null} */}
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: 16, sm: 18 },
                    color: theme.palette.text.body,
                  }}
                >
                  {instructor?.headline}
                </Typography>

                <Box sx={{ mt: 5 }}>
                  <Typography
                    mb={1}
                    fontWeight={"bold"}
                    sx={{ fontSize: { xs: 18, sm: 20 } }}
                  >
                    About Us
                  </Typography>
                  <Typography fontSize={"16px"}>{instructor?.bio}</Typography>
                </Box>
              </Box>
            </Grid>

            <Box mt={10} sx={{ width: "96vw" }}>
              <Typography mb={3} fontWeight={"bold"} fontSize={"20px"}>
                Our Courses ({courses.length})
              </Typography>
              <Grid
                maxWidth={"1200px"}
                container
                rowSpacing={4}
                columnSpacing={2}
                mt={1.5}
              >
                {courses.map((el) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CourseCard
                        theme={theme}
                        course={el}
                        onNav={() =>
                          navigate(`/course/${el?._id}`, {
                            state: {
                              id: el?._id,
                            },
                          })
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
    </>
  );
};

export default InstructorDetail;
