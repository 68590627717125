import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { COURSE_API } from "../apis";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../context/SnackbarContext";
import Skeleton from "@mui/material/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import CourseCard from "../components/CourseCard";
import Footer from "../components/Footer";
import SearchHeader from "../components/SearchHeader";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

const ExploreCourse = ({ mode, handleToggleChange, theme }) => {
  const perPage = 8;
  const [paginatedCourses, setPaginatedCourses] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [topCourses, setTopCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const { showSnackbar } = useSnackbar();
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const conState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const getCourses = async (token = null) => {
    try {
      const res = await COURSE_API.getCoursesPaginated(
        {
          perPage,
          page,
        },
        showSnackbar,
        token
      );
      if (res) {
        setPaginatedCourses(res?.courses);
        setPages(Math.ceil(res?.pagination?.totalItems / perPage));
      }
      setSkeletonLoading(false);
    } catch (error) {
      console.log("Error getCourses", error);
    }
  };

  const getTopCourses = async (token = null) => {
    try {
      const res = await COURSE_API.getTopCourses(token);
      if (res) {
        setTopCourses(res?.topCourses);
      }
    } catch (error) {
      console.log("Error getCourses", topCourses);
    }
  };

  useEffect(() => {
    setSkeletonLoading(true);
    getTopCourses();
  }, []);

  useEffect(() => {
    getCourses();
  }, [page]);

  useEffect(() => {
    if (paginatedCourses.length === 0) {
      getCourses(conState?.token);
      getTopCourses(conState?.token);
    }
  }, [conState]);

  const visibleCourses = 4;
  const totalCourses = topCourses.length;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - visibleCourses, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + visibleCourses, totalCourses - visibleCourses)
    );
  };

  const RowCourse = ({ el }) => {
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));

    return (
      <Card sx={{}}>
        <Box
          sx={{ display: isMdScreen ? "block" : "flex" }}
          onClick={() =>
            navigate("/course", {
              state: {
                id: el?._id,
              },
            })
          }
        >
          <CardMedia
            component="img"
            height="150"
            width="140"
            image={el?.featureImage}
            alt="green iguana"
            sx={{ maxWidth: 255 }}
          />
          <CardContent sx={{ maxWidth: 255 }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {el?.name}
            </Typography>
            <Typography
              gutterBottom
              component="div"
              sx={{
                color: "blue",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              by {el?.organization?.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {el?.description}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    );
  };

  function Media() {
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "250px",
          minWidth: "250px",
          height: "365px",
          borderRadius: 3,
          border: "1px solid #dadada",
          padding: "15px 5px 15px 5px",
        }}
      >
        {skeletonLoading ? (
          <Skeleton
            sx={{
              width: "220px",
              height: "220px",
            }}
            animation="wave"
            variant="rectangular"
          />
        ) : (
          <Box></Box>
        )}
        <CardContent
          sx={{
            width: "100%",
            height: "105px",
            padding: "0px 10px 0px 10px",
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          {skeletonLoading ? (
            <React.Fragment>
              <Skeleton
                animation="wave"
                height={10}
                style={{ marginBottom: 6 }}
              />
              <Skeleton animation="wave" height={10} width="100%" />
            </React.Fragment>
          ) : (
            <Box></Box>
          )}
        </CardContent>
      </Card>
    );
  }

  const RowCourse2 = ({ el }) => {
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));

    return (
      <Card sx={{}}>
        <Box
          sx={{
            display: isMdScreen ? "block" : "flex",
          }}
          onClick={() =>
            navigate("/course", {
              state: {
                id: el?._id,
              },
            })
          }
        >
          <CardMedia
            component="img"
            height="150"
            width="140"
            image={el?.courseImage}
            alt="green iguana"
            sx={{ maxWidth: 255 }}
          />
          <CardContent sx={{ maxWidth: 255 }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {el?.name}
            </Typography>
            <Typography
              gutterBottom
              component="div"
              sx={{
                color: "blue",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              by {el?.organization?.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {el?.description}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Header
        showNavigation={true}
        mode={mode}
        handleToggleChange={handleToggleChange}
        theme={theme}
      />
      <Grid
        mt={7}
        container
        justifyContent="center"
        sx={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <Grid maxWidth="1200px" width="100%" sx={{ paddingX: 2 }}>
          <SearchHeader title={t("explore.title")} theme={theme} />
          <Grid
            item
            xs={12}
            md={12}
            mt={8}
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
              {t("explore.section1")}
            </Typography>
            <Box
              mt={1}
              sx={{
                padding: "0px 40px",
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              {!isMobile && (
                <IconButton
                  onClick={handlePrev}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                    color: theme.palette.text.default,
                    position: "absolute",
                    zIndex: "2",
                    top: "11rem",
                    left: "-0.5rem",
                    opacity: currentIndex === 0 ? 0.5 : 1,
                    pointerEvents: currentIndex === 0 ? "none" : "auto",
                  }}
                >
                  <WestIcon sx={{ fontSize: "25px" }} />
                </IconButton>
              )}

              <Grid
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  overflowY: "hidden",
                  scrollbarWidth: "none", // Hide scrollbar in Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar in WebKit browsers
                  },
                }}
              >
                {skeletonLoading ? (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    mt={2}
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      width: "100%",
                      gap: 2,
                      transition: "transform 0.5s ease",
                      transform: `translateX(-${
                        (currentIndex / totalCourses) * 100
                      }%)`,
                    }}
                  >
                    {[...Array(5)].map((_, index) => (
                      <Grid item key={index} md={12} xs={12}>
                        <Media loading />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    mt={2}
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      width: "100%",
                      gap: 2,
                      transition: "transform 0.5s ease",
                      transform: `translateX(-${
                        (currentIndex / totalCourses) * 100
                      }%)`,
                    }}
                  >
                    {topCourses.map((el) => (
                      <Grid item key={el?._id} md={12}>
                        <CourseCard
                          theme={theme}
                          course={el}
                          onNav={() =>
                            navigate(`/course/${el?._id}`, {
                              state: { id: el?._id },
                            })
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>

              {!isMobile && (
                <IconButton
                  onClick={handleNext}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50px",
                    height: "50px",
                    borderRadius: "100%",
                    color: theme.palette.text.default,
                    position: "absolute",
                    zIndex: "2",
                    right: "-0.5rem",
                    top: "11rem",
                  }}
                >
                  <EastIcon sx={{ fontSize: "25px" }} />
                </IconButton>
              )}
            </Box>
          </Grid>

          <Grid container rowSpacing={2} columnSpacing={2} mt={5}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box mb={2}>
                  <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    {t("explore.section2")}
                  </Typography>
                </Box>
                {skeletonLoading && (
                  <Grid
                    maxWidth={"1200px"}
                    container
                    rowSpacing={4}
                    columnSpacing={2}
                    mt={1.5}
                  >
                    {[...Array(6)].map((_, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Media loading />
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Grid maxWidth={"1200px"} container rowSpacing={4} mt={1.5}>
                  {paginatedCourses.map((el) => (
                    <Grid
                      item
                      key={el?._id}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CourseCard
                        theme={theme}
                        course={el}
                        onNav={() =>
                          navigate(`/course/${el?._id}`, {
                            state: {
                              id: el?._id,
                            },
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
                <Pagination
                  size="large"
                  onChange={(e, value) => {
                    setPage(value);
                  }}
                  sx={{ mt: 4, display: "flex", justifyContent: "end" }}
                  count={pages}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={5}></Box>
        </Grid>
      </Grid>
      <Box>
        <Footer
          theme={theme}
          mode={mode}
          handleToggleChange={handleToggleChange}
        />
      </Box>
    </React.Fragment>
  );
};

export default ExploreCourse;
